<template>
  <b-card class="card-outline">
    <template #header>
      <b-row>
        <b-col md="6" lg="4" class="mb-3">
          <b-form-group label="Estatus" label-cols-md="6" label-cols-lg="4">
            <b-select @change="refreshTable" v-model="statusOption" :options="statusOptions"/>
          </b-form-group>
        </b-col>
        <b-col md="6" lg="4" class="mb-3" v-if="canSeeAll">
          <b-form-group label="Asignado" label-cols-md="6" label-cols-lg="4">
            <b-select @change="refreshTable" v-model="userOption" :options="userOptions"/>
          </b-form-group>
        </b-col>
        <b-col md="6" lg="4" class="mb-3">
          <b-form-group label="Buscar por Código" label-cols-md="6" label-cols-lg="4">
            <b-input @change="refreshTable" v-model="searchCode" lazy type="search"/>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <b-table id="table-orders"
             borderless
             hover
             :items="getOrders"
             :fields="fields"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :per-page="perPage"
             :current-page="currentPage"
             responsive="xl"
             ref="tableOrders"
             :busy="tableBusy">

      <template #cell(userDisplayName)="d">
        <p class="text-wrap" v-text="d.item.data.userDisplayName"></p>
      </template>
      <template #cell(orderAt)="d">
        <format-date-time :datetime="d.item.data.orderAt"/>
      </template>
      <template #cell(deliveryStatus)="d">
        <b-badge variant="secondary" v-if="d.item.data.deliveryStatus === 0">Pendiente</b-badge>
        <b-badge variant="primary" v-else-if="d.item.data.deliveryStatus === 1">Empaquetando</b-badge>
        <b-badge variant="success" v-else-if="d.item.data.deliveryStatus === 2">Enviado</b-badge>
      </template>
      <template #cell(priority)="d">
        <b-badge variant="primary" v-if="d.item.data.deliveryStatus === 2">Enviado</b-badge>
        <b-badge variant="success"
                 v-else-if="getDate(d.item.data.orderAt).getTime() > dates.twoDays">Baja
        </b-badge>
        <b-badge variant="warning"
                 v-else-if="getDate(d.item.data.orderAt).getTime() > dates.fourDays">Media
        </b-badge>
        <b-badge variant="danger" v-else>Alta</b-badge>

      </template>
      <template v-slot:cell(options)="d">
        <router-link :to="{name: 'OrderProcess', params: { orderId: d.item.id }}"
                     class="btn btn-link btn-sm ripple ripple-dark"
                     v-b-tooltip.hover
                     title="Proceso">
          <i class="material-icons-outlined">inventory_2</i>
        </router-link>
        <b-button variant="link"
                  @click="openProductListModal(d.item.id, d.item.data)"
                  class="btn-sm ripple ripple-dark"
                  title="Lista de productos"
                  v-b-tooltip.hover>
          <i class="material-icons-outlined">checklist</i>
        </b-button>
        <router-link v-if="d.item.data.emitterAddress"
                     :to="{name: 'OrderShippingGuide', params: {orderId: d.item.id, supplierId: supplierId}}"
                     class="btn btn-link btn-sm ripple ripple-dark" v-b-tooltip.hover
                     target="_blank"
                     title="Guía">
          <i class="material-icons-outlined">receipt</i>
        </router-link>
      </template>
    </b-table>

    <b-button @click="currentPage--" variant="light"
              :disabled="currentPage === 1">
      <i class="material-icons-outlined">arrow_back</i> Anterior
    </b-button>
    <b-button @click="currentPage++" variant="light" :disabled="isCurrentPageLastPage">
      Siguiente <i class="material-icons-outlined">arrow_forward</i>
    </b-button>

    <b-modal title="Lista de Productos"
             v-model="modalProductList.show"
             hide-footer centered
             size="lg">
      <package-product-list :products="modalProductList.products"/>
    </b-modal>
  </b-card>
</template>

<script>
import FormatDateTime from "@/components/share/formatDateTime";
import firebase from "firebase";
import {mapGetters} from "vuex";
import PackageProductList from "@/components/admin/orders/PackageProductList";


export default {
  components: {PackageProductList, FormatDateTime},
  data() {
    let today = new Date()
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'orderAt',
      sortDesc: true,
      tableBusy: false,
      fields: [
        {key: "id", sortable: false, label: 'Código de Identificación'},
        {key: "userDisplayName", sortable: false, label: 'Nombre de Usuario'},
        {key: "orderAt", sortable: true, label: 'Fecha de Orden'},
        {key: "deliveryStatus", sortable: true, label: 'Estado del Pedido'},
        {key: "priority", sortable: false, label: 'Prioridad'},
        {key: 'options', label: 'Opciones'}
      ],
      searchCode: '',
      startAfterDocsList: [],
      statusOptions: [
        {text: ' - ', value: 0},
        {text: 'Pendiente', value: 1},
        {text: 'Empaquetando', value: 2},
        {text: 'Enviado', value: 3}
      ],
      statusOption: 0,
      userOption: null,
      userOptions: [],
      orders: [],
      dates: {
        now: today,
        twoDays: (new Date()).setDate(today.getDate() - 2),
        fourDays: (new Date()).setDate(today.getDate() - 4)
      },
      modalProductList: {
        show: false,
        data: {},
        id: null,
        products: [],
      },
      modalPackageDelivery: {
        show: false,
        data: {},
        id: null,
      }
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    refreshTable() {
      this.$refs.tableOrders.refresh();
    },
    async getUsers() {
      let db = firebase.firestore()
      let users = await db.collection('admin_users')
          .where('roleUID', 'in', ['4', '5'])
          .where('suppliersUID', '==', this.supplierId)
          .where('status', '==', 1)
          .get()

      let list = [{value: null, text: ' - '}]
      users.forEach(u => {
        let data = u.data()
        list.push({
          value: u.id,
          text: data.displayName
        })
      })
      this.userOptions = list
    },
    getOrders() {
      let query = firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')

      //SearchByCode
      if (this.searchCode.length > 0) {
        this.currentPage = 1
        this.startAfterDocsList = []

        return query.doc(this.searchCode).get().then(order => {
          if (!order.exists) {
            this.orders = []
            return []
          }
          this.orders = [{
            id: order.id,
            data: order.data(),
            doc: order
          }]
          return [{
            id: order.id,
            data: order.data(),
            doc: order
          }]
        }).catch(error => {
          console.log(error)
          this.orders = []
          return []
        })
      }

      //Status
      if (this.statusOption) {
        let statusVal = this.statusOption - 1
        query = query.where('deliveryStatus', '==', statusVal)
      }

      //UsersAssigned
      if (!this.canSeeAll) {
        let uid = this.user.adminData.uid
        query = query.where('assignedMemberUID', '==', uid)
        console.log('on cant see all')
      } else if(this.userOption) {
        let userVal = this.userOption
        query = query.where('assignedMemberUID', '==', userVal)
      }

      //limits
      query = query.limit(this.perPage).orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.orders[this.orders.length - 1].doc
        query = query.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      let ordersArray = []
      return query.get().then(orders => {
        orders.forEach(o => {
          ordersArray.push({
            id: o.id,
            data: o.data(),
            doc: o
          })
        })
        this.orders = ordersArray
        return ordersArray
      }).catch(err => {
        console.log(err)
        this.orders = []
        return []
      })
    },
    async openProductListModal(id, data) {
      this.modalProductList.show = true
      this.modalProductList.id = id
      this.modalProductList.data = data

      let productsSnap = await firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')
          .doc(id)
          .collection('products')
          .get()

      this.modalProductList.products = []

      productsSnap.forEach(p => {
        this.modalProductList.products.push({
          id: p.id,
          data: p.data(),
          doc: p
        })
      })
    },
    async openPackageDeliveryModal(id, data) {
      this.modalPackageDelivery.show = true
      this.modalPackageDelivery.id = id
      this.modalPackageDelivery.data = data
    },
    getDate(datetime) {
      if (this.datetime instanceof Date) return datetime;
      if (typeof datetime === 'string') return new Date(datetime)
      if (typeof datetime === 'number') return new Date(datetime)
      if (typeof (datetime) == 'object') return datetime.toDate()
      return null
    },
  },
  computed: {
    rows() {
      return this.orders.length
    },
    isCurrentPageLastPage() {
      return this.orders.length < this.perPage;
    },
    ...mapGetters(['user']),
    supplierId() {
      return this.user.adminData.suppliersUID
    },
    roleUID() {
      return this.user.adminData.roleUID
    },
    canSeeAll() {
      return this.roleUID === '4'
    }
  }
}
</script>