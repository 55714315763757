<template>
  <div>
    <div class="card card-outline">
      <div class="card-header mt-2">
        <div class="row">
          <div class="col-md-6">
            <BFormGroup label-for="user-uuid" label="UUID del usuario">
              <BInput v-model="searchUserUuid" id="user-uuid" type="text"
                      placeholder="Ej. WbnDn3eXAsXhBfLg8xt7gy6MLbC3"></BInput>
              <span v-if="errorMessage" class="text-danger text-muted">{{ errorMessage }}</span>
            </BFormGroup>
          </div>
          <div class="col-md-6 align-self-center text-right">
            <BButton @click="fetchUserBonos"
                     :disabled="isSubmitDisabled || isLoading"
                     type="submit" variant="primary"
                     class="btn-round btn-full-width ripple px-5">
              <i class="material-icons-outlined mr-2">search</i>
              {{ isLoading ? 'Buscando...' : 'Buscar' }}
            </BButton>
          </div>
        </div>
      </div>
      <div v-if="userBonos" class="card-body">
        <div v-if="userBonos.length < 1">
          El usuario no ha recibido ningun pago de bonos.
        </div>
        <BTable borderless hover :items="userBonos"></BTable>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  data() {
    return {
      searchUserUuid: '',
      result: {},
      errorMessage: null,
      userBonos: null,
      isLoading: false
    }
  },
  computed: {
    isSubmitDisabled() {
      return this.searchUserUuid === ''
    }
  },
  methods: {
    fetchUserBonos() {
      this.errorMessage = null
      this.userBonos = null
      this.isLoading = true

      const getUserGroupedPayouts = firebase.functions().httpsCallable('getUserGroupedPayouts')

      getUserGroupedPayouts({
        userUuid: this.searchUserUuid,
        limit: 5
      })
          .then(response => this.result = response.data.data)
          .then(data => this.userBonos = data.map(info => {
            return {
              UUID: info.group_payout_uuid,
              total: info.total_amount,
              estatus_de_pago: info.is_paid ? "Pago enviado" : "Pago pendiente",
              fecha: new Date(info.created_at).toLocaleString('es-MX', {timeZone: 'America/Monterrey'}),
            }
          }))
          .catch(err => this.errorMessage = err)
          .finally(() => this.isLoading = false)
    }
  }
}
</script>
