<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container class="mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <b-row class="mb-3">
        <b-col md="12">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Datos"
                   title-item-class="text-uppercase ripple ripple-dark"
                   active>
              <form-category-info v-model="category.data" @save="saveCategory"/>
            </b-tab>
            <b-tab title="Subcategorías"
                   :disabled="!categoryExists"
                   title-item-class="text-uppercase ripple ripple-dark">
              <table-subcategories v-if="categoryExists"
                                   :category-id="categoryId"/>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import firebase from "firebase/app";
import FormCategoryInfo from "@/components/admin/products/FormCategoryInfo";
import TableSubcategories from "@/components/admin/category/TableSubcategories";

export default {
  name: "CategoryForm",
  components: {TableSubcategories, FormCategoryInfo, Navbar, Sidebar},
  data: () => {
    const db = firebase.firestore();
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Productos',
        },
        {
          text: 'Categorías',
          to: {name: 'Categories'}
        },
        {
          text: 'Formulario',
        },
      ],
      categoryId: null,
      db: db,
      category: {
        doc: null,
        ref: db.collection('categories'),
        data: {},
        filters: {}
      }
    }
  },
  mounted() {
    this.categoryId = this.$route.params.id ? this.$route.params.id : null;
    if (this.categoryId) {
      this.initCategory();
    }
  },
  methods: {
    async initCategory() {
      this.category.doc = this.category.ref.doc(this.categoryId);
      let doc = await this.category.doc.get();
      if (doc.exists) {
        this.category.data = doc.data();
        this.category.filters = this.category.doc.collection('filters');
      } else {
        this.categoryId = null;
      }
    },
    saveCategory(category) {
      if (this.categoryId) {
        this.category.ref.doc(this.categoryId).set(category, {merge: true});
      } else {
        const newCategory = this.category.ref.doc();
        newCategory.set(category, {merge: true});
        this.categoryId = newCategory.id;
        this.category.doc = newCategory;
      }

      this.$bvToast.toast('Categoría guardada en la base. Regresar a la lista de categorías.', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
        href: 'admin/products/categories/'
      });
    }
  },
  computed: {
    categoryExists() {
      return this.categoryId != null;
    }
  }
}
</script>

<style scoped>

</style>