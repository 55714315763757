<template>
  <div>
    <div class="card card-outline">
      <div class="card-body">

        <div v-for="(filter, index) in filters" v-bind:key="index + 'filter-btn'">
          <b-button v-b-toggle="filter.id"
                    class="btn-link bg-grey text-body text-left btn-block ripple ripple-dark mx-0 mb-2">
            {{ filter.name }}
          </b-button>
          <b-collapse :id="filter.id">
            <div class="d-flex justify-content-between my-2" v-for="(filterItem, index2) in filter.filterItems"
                 v-bind:key="index2 + '-filterItem-checkbox'">
              <b-form-checkbox v-model="selectedCheckboxes['cat' + index + '_' + index2]"
                               @change="val => onFilterSelected(val, filterItem)"
                               class="text-capitalize">{{ filterItem.name }}
              </b-form-checkbox>
            </div>
          </b-collapse>
        </div>

        <div v-if="filters.length === 0">
          <p>No hay filtros</p>
        </div>

      </div>

      <div class="card-footer">
        <button @click="clear()"
                class="btn btn-default-outline btn-block ripple ripple-dark">Limpiar filtros
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: 'CategoriesFilters',
  props: [
    'value'
  ],
  mounted() {
    this.fetchFilters()
  },
  data() {
    const db = firebase.firestore()

    return {
      filters: [],
      filtersItems: [],
      selectedFilters: [],
      selectedCheckboxes: {},
      ref: db.collection('categories')
          .doc(this.$route.params.categoryId)
          .collection('subcategories')
          .doc(this.$route.params.subcategoryId)
    }
  },
  methods: {
    clear() {
      this.selectedFilters = []
      this.selectedCheckboxes = {}
      this.$emit('selectedFiltersChanged', [])
    },
    async fetchFilters() {
      const filters = await this.ref.collection('filters').get()

      for (const doc of filters.docs) {
        this.filters.push({
          id: doc.id,
          name: doc.data().name,
          filterItems: await this.fetchFilterItems(doc.id)
        })
      }
    },
    async fetchFilterItems(docId) {
      const filterItems = await this.ref.collection('filters').doc(docId).collection('filter_items').get()
      const itemsArr = []
      filterItems.forEach(doc => itemsArr.push({
        id: doc.id,
        name: doc.data().name,
      }))
      return itemsArr
    },
    onFilterSelected(val, filterItem) {
      if (val) {
        this.selectedFilters.push(filterItem.id)
      } else {
        this.selectedFilters = this.selectedFilters.filter(selectedFilter => selectedFilter !== filterItem.id)
      }

      this.$emit('selectedFiltersChanged', this.selectedFilters)
    }
  }
}
</script>