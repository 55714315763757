<template>
  <b-modal v-if="isDocCreated"
           :title="'Invitación enviada a '+name"
           ok-title="Copiar y cerrar"
           cancel-title="cerrar"
           cancel-variant="link btn-round ripple ripple-dark"
           ok-variant="primary btn-round ripple ripple-dark"
           :visible="true"
           @hide="onHideLink">
    <p class="font-weight-bolder text-center">
      La Invitación a sido enviada pero puedes copiar el link y enviarlo directamente:
    </p>
    <br>
    <p class="badge badge-secondary w-100">
      <code ref="invitationLink"
            class="font-weight-bolder"
            style="font-size: 1rem"
            v-text="currentHost+'/register/'+doc.id"/>
    </p>
  </b-modal>
  <!-- MODAL ADD INVITES -->
  <b-modal :visible="value"
           @change="toggleVisibleModal"
           @ok="generateEmailAndCode"
           title="Agregar invitados"
           title-class="font-weight-bold"
           ok-variant="primary btn-round ripple ripple-dark"
           :ok-disabled="!(validateEmail && validateName)"
           ok-title="Enviar"
           cancel-variant="link btn-round ripple ripple-dark"
           cancel-title="Cancelar"
           centered
           v-else>
    <form action="">
      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Nombre"
                        :validated="validateName">
            <b-form-input class="form-control"
                          placeholder="Ximena"
                          v-model="name">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Apellido"
                        :validated="validateLastName">
            <b-form-input class="form-control"
                          placeholder="López"
                          v-model="lastName">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Correo Electrónico"
                        :validated="validateEmail">
            <b-form-input v-model="email"
                          class="form-control"
                          placeholder="Ingresa tu correo">
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12 mt-3 ">
          <b-form-checkbox
              v-model="notificationConfirm"
              class="text-dark">
            <span class="text-dark">Enviar notificación de recibido</span>
          </b-form-checkbox>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css';
import firebase from "firebase/app";
import {Settings} from 'luxon'

Settings.defaultLocale = 'es'

export default {
  name: 'ModalAddInvites',
  components: {

  },
  props: {
    value: Boolean,
    user: Object
  },
  data() {
    return {
      name: '',
      lastName: '',
      email: '',
      notificationConfirm: false,
      db: firebase.firestore().collection('nucleos_requests'),
      doc: null
    }
  },
  mounted() {

  },
  methods: {
    async generateEmailAndCode() {
      //TODO Generate Email
      let doc = this.db.doc();
      await doc.set({
        emitterUser: {
          displayName: this.user.displayName,
          email: this.user.email,
          lastName: this.user.lastName,
        },
        emitterUserUID: this.user.uid,
        senderDisplayName: this.name,
        senderEmail: this.email,
        senderLastName: this.lastName,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        isActive: true,
        notificationConfirm: this.notificationConfirm
      });

      this.doc = doc;

      this.$bvToast.toast('Invitación enviada a '+this.name, {
        title: 'Enviado',
        autoHideDelay: 6500,
        variant: 'primary',
      });
    },
    toggleVisibleModal(val) {
      if (val) {
        this.name = '';
        this.email = '';
        this.lastName = '';
        this.notificationConfirm = false;
        this.doc = null;
      }
      this.$emit('input', val);
    },
    copyLink() {
      let el = this.$refs.invitationLink;
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
    onHideLink() {
      this.name = '';
      this.email = '';
      this.doc = null;
      this.lastName = '';
      this.notificationConfirm = false;
    }
  },
  computed: {
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    validateName() {
      return this.name.replaceAll(' ', '').length >= 3;
    },
    validateLastName() {
      return this.lastName.replaceAll(' ', '').length >= 3;
    },
    isDocCreated() {
      return this.doc != null;
    },
    currentHost() {
      return window.location.host;
    }
  }
}
</script>