<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <h6>Dale Xplits</h6>
      </div>
      <div class="col-md-4 mb-3">
        <div class="card shadow-sm">
          <img src="https://images.pexels.com/photos/7432/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
               class="card-img-top" alt="Foto de la card">
          <div class="card-body text-center">
            <h6 class="subtitle-1">Material Redes Sociales</h6>
            <a class="btn btn-secondary btn-round ripple px-5"
               v-if="xplitsMaterial != null"
               :href="xplitsMaterial.data().url"
               target="_blank"
               download="materialXplits">Descargar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: 'DaleXplits',
  data() {
    return {
      xplitsMaterial: null
    }
  },
  mounted() {
    this.getMaterialSocialXplits()
  },
  methods: {
    async getMaterialSocialXplits() {
      let img = await firebase.firestore()
          .collection('image_banners')
          .doc('materialSocialXplits')
          .get()

      if (img.exists) {
        this.xplitsMaterial = img;
      }
    }
  }
}
</script>