<template>
  <div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12 mb-5">
          <div class="text-center">
            <img src="@/assets/img/logos/logo.png" class="img-fluid mb-3" width="200px" alt="Logo de Xplits">
            <h5 class="font-weight-bold text-primary">Detalles del pedido</h5>
            <h6 class="subtitle-1">Recibo #{{ routeUuid }}</h6>
          </div>
        </div>
        <div class="col-md-12 mb-5">
          <div class="card card-outline">
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <thead>
                <tr>
                  <th>No. identificador</th>
                  <th>Tipo</th>
                  <th>Cantidad total</th>
                  <th>Fecha de creación</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(payout, index) in groupedPayouts" v-bind:key="'index-'+index">
                  <th>{{ payout.uuid }}</th>
                  <td>{{ payout.type }}</td>
                  <td>{{ payout.total_amount }}</td>
                  <td>{{ payout.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <h6 class="subtitle-1">¡Gracias por comprar con nosotros!</h6>
          <h6 class="subtitle-1">© 2021 xplits.com</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  data() {
    return {
      routeUuid: this.$route.params.uuid,
      groupedPayouts: []
    }
  },
  mounted() {
    this.fetchGroupedPayout()
        .then(() => window.print())
  },
  methods: {
    fetchGroupedPayout() {
      const getSpecificGroupedPayout = firebase.functions().httpsCallable('getSpecificGroupedPayout')

      return getSpecificGroupedPayout({
        groupedPayoutUUID: this.routeUuid
      })
          .then(res => this.groupedPayouts = res.data)
    }
  }
}
</script>