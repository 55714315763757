import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta';
import * as firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/auth'
import 'popper.js'
import 'jquery'
import 'bootstrap';
import {BootstrapVue} from 'bootstrap-vue'
import {Datetime} from "vue-datetime";


Vue.config.productionTip = false

Vue.use(VueMeta);
Vue.use(BootstrapVue)
Vue.use(Datetime)

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'production') {
    firebase.analytics()
}

Vue.config.productionTip = false

firebase.auth().onAuthStateChanged(user =>
    store.dispatch("fetchUser", user)
    .then(async () => new Vue({ router, store, render: h => h(App)}).$mount('#app'))
    .then(() => store.dispatch("fetchCartProductsCount")))
