<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>

      <!-- TABLE -->
      <b-row class="mb-3">
        <b-col md="12">
          <b-card class="card-outline">

            <b-form-row>
              <InputFile v-model="userPicture"
                         id="input-user-picture"
                         label="Avatar"
                         :accept="accept"
                         class="col-md-12 mb-3"/>

              <b-form-group class="col-md-6"
                            :validated="validatedEmail"
                            label="Email">
                <b-form-input v-model="user.email"
                              type="email"
                              class="form-control-border-bottom"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedUsername"
                            label="Nombre de usuario">
                <b-form-input type="text"
                              class="form-control-border-bottom"
                              v-model="user.username"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedName"
                            label="Nombre">
                <b-form-input type="text"
                              v-model="user.displayName"
                              class="form-control-border-bottom"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedLastName"
                            label="Apellido">
                <b-form-input type="text"
                              v-model="user.lastName"
                              class="form-control-border-bottom"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedPassword"
                            label="Contraseña"
                            v-if="userUid == null">
                <b-form-input type="password"
                              v-model="user.password"
                              class="form-control-border-bottom"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedConfirmPassword"
                            label="Confirmar Contraseña"
                            v-if="userUid == null">
                <b-form-input type="password"
                              v-model="confirmPassword"
                              class="form-control-border-bottom"
                              required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            :validated="validatedBirthday"
                            label="Fecha de Nacimiento">
                <datetime input-class="text-dark form-control form-control-border-bottom"
                          @input="birthdayDatetime = new Date($event)"
                          :value="birthdayDatetime ? birthdayDatetime.toISOString() : null"
                          :phrases="datetimeConfig.phrases"
                          placeholder="Fecha de nacimiento"
                          class="theme-datepicker"
                          :format="datetimeConfig.format"
                          required/>
              </b-form-group>

              <b-form-group class="col-md-6"
                            label="Rol">
                <b-form-select v-model="user.roleUID"
                               :options="roleOptions"
                               value-field="id"
                               text-field="text"
                               class="form-control-flat"/>
              </b-form-group>
              <b-form-group class="col-md-6"
                            label="Estatus">
                <b-form-select v-model="user.status"
                               :options="status"
                               class="form-control-flat"/>
              </b-form-group>
            </b-form-row>

            <b-row class="col-12" v-if="showSuppliersList">
              <hr class="col-12">
              <b-col sm="12"><h5>Proveedor</h5></b-col>

              <b-form-group class="col-md-6"
                            :validated="validatedName"
                            label="Empresa Proveedora">
                <b-form-select v-model="user.suppliersUID"
                               :options="suppliers"
                               value-field="id"
                               text-field="text"
                               class="form-control-flat"/>
              </b-form-group>
            </b-row>

            <template #footer>
              <div class="text-right">
                <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
                  <i class="material-icons-outlined">arrow_back</i>Regresar
                </b-button>
                <b-button variant="primary"
                          :disabled="isSaveButtonDisabled"
                          @click="saveUser"
                          class="btn-round ripple mt-3 px-5">Guardar</b-button>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import InputFile from "@/components/InputFile";
import firebase from "firebase/app";
import {Datetime} from 'vue-datetime';

export default {
  metaInfo: {
    title: 'Registro de Usuario',
  },
  components: {InputFile, Sidebar, Navbar, Datetime},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Usuarios',
          to: {name: 'Users'}
        },
        {
          text: 'Registro',
        }
      ],
      status: [
        {value: 0, text: 'No habilitado'},
        {value: 1, text: 'Habilitado'},
      ],
      userPicture: null,
      accept: 'image/*',
      user: {
        roleUID: 0,
        status: 1,
        email: '',
        username: '',
        displayName: '',
        lastName: '',
        birthday: firebase.firestore.Timestamp.now()
      },
      userUid: null,
      confirmPassword: '',
      roleOptions: [],
      suppliers: [],
      datetimeConfig: {
        phrases: {
          ok: 'Continuar',
          cancel: 'Salir'
        },
        format: 'dd/MM/yyyy'
      }
    }
  },
  mounted() {
    let id = this.$route.params.userUID;
    if (id) {
      this.userUid = id;
      this.getUser();
    }
    this.getRoleOptions();
  },
  methods: {
    async getUser() {
      let userDoc = await firebase.firestore().collection('admin_users').doc(this.userUid).get();
      this.user = userDoc.data();
    },
    async getRoleOptions() {
      let roleOptions = await firebase.firestore().collection('role_options').get();
      this.roleOptions = [];
      roleOptions.forEach(r => this.roleOptions.push({
        data: r.data(),
        doc: r,
        id: r.id,
        text: r.data().name ?? ''
      }));
    },
    async getSuppliers() {
      let suppliers = await firebase.firestore().collection('suppliers').orderBy('name').get();
      this.suppliers = [];
      suppliers.forEach(s => this.suppliers.push({
        data: s.data(),
        doc: s,
        id: s.id,
        text: s.data().name ?? ''
      }));
    },
    saveUser() {
      //RoleDisplayName
      let role = this.roleOptions.find(r => r.id === this.user.roleUID);
      if (role) this.user.roleDisplayName = role.data.name;

      if (this.userUid) {
        let doc = firebase.firestore().collection('admin_users').doc(this.userUid);
        doc.update(this.user).then(this.savedUserEvent).catch(this.savedUserErrorEvent)
      } else {
        let doc = firebase.firestore().collection('admin_users').doc();
        doc.set(this.user).then(this.savedUserEvent).catch(this.savedUserErrorEvent);
      }
    },
    savedUserEvent() {
      this.$bvToast.toast('Usuario registrado en la base de datos.', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
        href: 'admin/users'
      });

      setTimeout(() => {
        this.$router.back();
      }, 2000)
    },
    savedUserErrorEvent() {
      this.$bvToast.toast('Hubo un error al momento de registrar al usuario.', {
        title: 'Error',
        autoHideDelay: 6500,
        variant: 'danger',
      });
    }
  },
  computed: {
    validatedEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.user.email).toLowerCase());
    },
    validatedUsername() {
      const re = /^([a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$)/;
      return re.test(String(this.user.username));
    },
    validatedName() {
      return String(this.user.displayName).length > 2;
    },
    validatedLastName() {
      return String(this.user.lastName).length > 2;
    },
    validatedPassword() {
      const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      return re.test(String(this.user.password));
    },
    validatedConfirmPassword() {
      return this.user.password === this.confirmPassword;
    },
    validatedBirthday() {
      return this.user.birthday != null;
    },
    isSaveButtonDisabled() {
      return !(this.validatedEmail
          && this.validatedUsername
          && this.validatedName
          && this.validatedLastName
          //&& this.validatedBirthday
          && ((this.validatedPassword && this.validatedConfirmPassword) || this.userUid != null))
    },
    showSuppliersList() {
      let role = this.user.roleUID == 4 || this.user.roleUID == 5;
      if (role && this.suppliers.length === 0) {
        this.getSuppliers();
      }
      return role;
    },
    birthdayDatetime: {
      get() {
        let d = this.user.birthday;
        if (d == null) return null;
        return d.toDate();
      },
      set(val) {
        if (val == null) {
          this.user.birthday = null;
          return;
        }
        this.user.birthday = firebase.firestore.Timestamp.fromDate(val);
      }
    }
  }
}
</script>

<style scoped>
  /deep/ .theme-datepicker input {
    color: var(--dark) !important;
  }
</style>