<template>
  <div>
    <Navbar></Navbar>
    <SearchBar v-model="filter"
               @search-submit="handleSearch"
               current-view/>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- PRODUCTS -->
      <div class="row mb-4">
        <!-- LEFT COLUMN FILTERS -->
        <div class="col-md-3 mb-3" v-if="subcategories.length > 0">
          <div class="card card-outline">
            <div class="card-header">
              <h6 class="subtitle-1 font-weight-bold border-radius-8 bg-grey text-body p-2 mb-0">Subcategorías</h6>
            </div>
            <div class="card-body">
              <div v-for="sc in subcategories" :key="sc.id">
                <router-link :to="{name: 'Subcategory', params: { categoryId: categoryId, subcategoryId: sc.id}}"
                             v-b-toggle="sc.id"
                             class="btn btn-default-outline btn-block ripple ripple-dark mb-2">
                  {{ sc.title }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- RIGHT COLUMN PRODUCT -->
        <div class="col-md-9 mx-auto mb-3">
          <div class="mb-3">
            <b-row>
              <product-item v-for="product in products"
                            :key="product.id"
                            :value="product"
                            cols="6"
                            md="4"/>
            </b-row>

            <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
              <div slot="no-more">No hay más resultados.</div>
              <div slot="no-results">No hay resultados.</div>
            </infinite-loading>

          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import firebase from "firebase";
import InfiniteLoading from 'vue-infinite-loading';
import ProductItem from "@/components/product/ProductItem";

export default {
  name: 'Category',
  components: {
    ProductItem,
    Navbar, Footer, SearchBar, InfiniteLoading
  },
  metaInfo: {
    title: 'Categoria',
  },
  mounted() {
    this.fetchCategory()
  },
  data() {
    return {
      productsPerPage: 12,
      category: {},
      subcategories: [],
      products: [],
      selectedFilterItems: [],
      items: [
        {text: 'Inicio', to: {name: 'Home'}},
        {text: 'Tienda', to: {name: 'Store'}},
        {text: 'Loading'},
      ],
      lastDocInfiniteScroll: null,
      filter: null,
      categoryId: this.$route.params.categoryId,
    }
  },
  methods: {
    handleSearch(text) {
      console.log('search', text)
      this.filter = text
      this.selectedFilterItems = []
      this.products = []
      this.$refs.infiniteLoading.stateChanger.reset() // reset infinite loader, so it will fetch products again
    },
    async fetchCategory() {
      const db = firebase.firestore()
      const category = await db.collection('categories').doc(this.$route.params.categoryId).get()
      this.category = {id: category.id, ...category.data()}
      this.items[2].text = category.data().name // nav item

      const subcategories = await db.collection('categories')
          .doc(this.$route.params.categoryId).collection('subcategories').get()

      this.subcategories = []

      subcategories.forEach(s => {
        this.subcategories.push({
          id: s.id,
          title: s.data().title
        })
      })
    },
    async fetchProducts(overrideProducts = true) {
      console.log(overrideProducts)
      const db = firebase.firestore()
      let products = db.collection('products')
          .where('category', '==', this.$route.params.categoryId)

      if (this.filter) {
        // search only 10 first words because firebase only allows 10 word comparisons
        const searchable10WordArray = this.filter.split(' ').slice(0, 10).map(word => word.toLowerCase())
        products = products.where('filterKeywords', 'array-contains-any', searchable10WordArray)
      }

      products = products.orderBy('created_at', 'asc')


      if (this.lastDocInfiniteScroll) {
        products = products.startAfter(this.lastDocInfiniteScroll)
      }

      products = await products.limit(this.productsPerPage).get()

      if (overrideProducts) {
        this.products = []
      }

      let queryResultsCount = 0

      products.forEach(doc => {
        queryResultsCount += 1

        this.products.push({
          id: doc.id,
          ...doc.data()
        })
      })

      return {
        count: queryResultsCount,
        lastDocumentSnapshot: products.docs[products.docs.length - 1]
      }
    },
    async infiniteHandler(state) {
      const productsFetchResult = await this.fetchProducts(false)

      this.lastDocInfiniteScroll = productsFetchResult.lastDocumentSnapshot

      if (productsFetchResult.count > 0) {
        console.debug(`Infinite scroll loaded ${productsFetchResult.count} products.`)
        state.loaded()
      } else {
        console.debug("Infinite scroll was completed.")
        state.complete()
      }
    }
  }
}
</script>