<template>
  <div>
    <div class="card card-outline">
      <div class="card-header my-2">
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-3">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter" type="search" id="filterInput" class="form-control-flat"
                            placeholder="Buscar por categoría, descripción o cantidad"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-6 col-lg-8 text-md-right">
            <router-link :to="{name: 'AddCategory'}" class="btn btn-primary ripple">
              <i class="material-icons-outlined">add</i>
              Agregar
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <b-table id="table-categories"
                 ref="table"
                 borderless
                 hover
                 :items="categories"
                 :fields="fields"
                 :filter="filter"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :per-page="perPage"
                 :current-page="currentPage"
                 responsive="xl"
        >
          <template v-slot:cell(level)="d">
            <b-button-group>
              <b-button @click="upItem(d.item)">
                <i class="material-icons-outlined" style="font-size:1rem">expand_less</i>
              </b-button>
              <div class="px-3 text-center">
                <p v-text="d.value" class="mt-2 mb-0" style="font-size: 1rem"></p>
              </div>
              <b-button @click="downItem(d.item)">
                <i class="material-icons-outlined" style="font-size:1rem">expand_more</i>
              </b-button>
            </b-button-group>
          </template>

          <template v-slot:cell(name)="d"><p v-text="d.item.name" class="text-wrap"/></template>
          <template v-slot:cell(productCount)="d">
            <p v-text="d.item.productCount == null ? 'Sin productos asignados' : d.item.productCount"
               class="text-wrap"/>
          </template>
          <template v-slot:cell(options)="d">

            <router-link :to="{ name: 'EditCategory', params: { id: d.item.id } }"
                         variant="link"
                         class="btn btn-link ripple ripple-dark"
                         v-b-tooltip.hover
                         title="Editar">
              <i class="material-icons">edit</i>
            </router-link>

            <b-button variant="link"
                      class="ripple ripple-dark"
                      @click="openDeleteModal(d.item)"
                      v-b-tooltip.hover
                      title="eliminar">
              <i class="material-icons">delete</i>
            </b-button>

          </template>
        </b-table>

      </div>
    </div>

    <b-modal :title="'Eliminar Categoría: '+deleteModal.category.name"
             v-model="deleteModal.show"
             @ok="deleteCategory"
             ok-variant="danger ripple ripple-dark"
             ok-title="Eliminar"
             cancel-variant="link ripple ripple-dark"
             cancel-title="Cancelar">
      <p>¿Desea eliminar la categoría <b v-text="deleteModal.category.name"></b> permanentemente?</p>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  components: {

  },
  data() {
    return {
      filter: "",
      perPage: 10,
      currentPage: 1,
      sortBy: 'level',
      sortDesc: false,
      startAfterDocsList: [],
      fields: [
        {key: "level", label: 'Orden', sortable: false},
        {key: "name", label: 'Nombre', sortable: false},
        {key: "productCount", label: 'Cantidad de Productos', sortable: false},
        {key: "options", label: 'Acciones', sortable: false}
      ],
      categories: [],
      deleteModal: {
        category: {name: ''},
        id: null,
        show: false,
      },
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    async getCategories() {
      const db = firebase.firestore();
      let categories = db.collection('categories');

      let snap = await categories.get()
      let catArray = []
      snap.forEach(c => {
        let d = c.data();
        d.id = c.id;
        d.doc = c
        catArray.push(d);
      });

      if (catArray.length > 0 && catArray[0].level == null) {

        let level = 1
        for (let i = 0; i < catArray.length; i++) {
          catArray[i].level = level
          await db.collection('categories').doc(catArray[i].id).update({
            level: level
          })
          level++
        }
      }

      this.categories = catArray

      return catArray
    },
    async deleteCategory() {
      if (this.deleteModal.id) {
        const db = firebase.firestore();
        let query = db.collection('categories').doc(this.deleteModal.id)

        await query.delete()

        // this.$refs.table.refresh()
        await this.getCategories()
      }
    },
    openDeleteModal(category) {
      this.deleteModal.show = true
      this.deleteModal.id = category.id
      this.deleteModal.category = category
    },
    async upItem(item) {
      let level = item.level
      let db = firebase.firestore().collection('categories')

      if (level === 1) return

      let forwardItem = this.categories.find(c => c.level === (level - 1))

      if (forwardItem) {
        let forwardIndex = this.categories.findIndex(c => c.id === forwardItem.id)
        let itemIndex = this.categories.findIndex(c => c.id === item.id)

        this.categories[forwardIndex].level++
        this.categories[itemIndex].level--

        await db.doc(forwardItem.id).update({
          level: this.categories[forwardIndex].level
        })

        await db.doc(item.id).update({
          level: this.categories[itemIndex].level
        })
      }
    },
    async downItem(item) {
      let level = item.level
      let db = firebase.firestore().collection('categories')

      if (level >= this.maxLevel) return

      let backwardItem = this.categories.find(c => c.level === (level + 1))

      if (backwardItem) {
        let backwardIndex = this.categories.findIndex(c => c.id === backwardItem.id)
        let itemIndex = this.categories.findIndex(c => c.id === item.id)

        this.categories[backwardIndex].level--
        this.categories[itemIndex].level++

        await db.doc(backwardItem.id).update({
          level: this.categories[backwardIndex].level
        })

        await db.doc(item.id).update({
          level: this.categories[itemIndex].level
        })
      }
    }
  },
  computed: {
    rows() {
      return this.categories.length
    },
    isCurrentPageLastPage() {
      return this.categories.length < this.perPage
    },
    maxLevel() {
      return this.categories.length
    }
  }
}
</script>