<template>
<div>
  <b-card class="card-outline">
    <template #header>
      <b-row>
        <b-col md="6" lg="4" class="mb-3">
          <div class="d-flex align-items-center">
            <i class="material-icons-outlined mr-2">search</i>
            <b-form-input v-model="filter"
                          type="search"
                          id="filterInput"
                          class="form-control-flat"
                          placeholder="Buscar por nombre de la compañía"/>
          </div>
        </b-col>
        <b-col md="6" lg="8" class="text-md-right">
          <b-button variant="primary"
                    class="ripple"
                    @click="openFormModal(null)">
            <i class="material-icons-outlined">add</i>
            Agregar
          </b-button>
        </b-col>
      </b-row>
    </template>

    <b-table :fields="fields"
             :filter="filter"
             :per-page="perPage"
             :filter-ignored-fields="filterIgnoredFields"
             :current-page.sync="currentPage"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :items="getBrands">
      <template #cell(company_name)="d"><p v-text="d.value"></p></template>
      <template #cell(products_count)="d">
        <p v-text="d.item.products_count ? d.item.products_count : 'Sin productos asignados'"></p>
      </template>
      <template #cell(created_at)="d">
        <format-date-time :datetime="d.value"/>
      </template>
      <template #cell(options)="d">
        <b-button variant="link" class="ripple ripple-dark"
                  @click="openFormModal(d.item.doc)"
                  title="Editar">
          <i class="material-icons-outlined">edit</i>
        </b-button>
      </template>
    </b-table>

    <b-button @click="currentPage--" variant="light"
              :disabled="currentPage === 1">
      <i class="material-icons-outlined">arrow_back</i> Anterior
    </b-button>
    <b-button @click="currentPage++" variant="light" :disabled="isCurrentPageLastPage">
      Siguiente <i class="material-icons-outlined">arrow_forward</i>
    </b-button>

  </b-card>

  <b-modal v-model="formModal.visible"
           ok-title="Guardar"
           cancel-title="Cancelar"
           cancel-variant="link"
           :ok-disabled="!(isCompanyNameValid)"
           @ok="saveBrand"
           title="Marca">
    <b-form-group label="Nombre de la Marca" :validated="isCompanyNameValid">
      <b-form-input v-model="formModal.brand.company_name"/>
    </b-form-group>
    <b-form-group label="Nombre del contacto">
      <b-form-input v-model="formModal.brand.contact_name"/>
    </b-form-group>
    <b-form-group label="Número de contacto">
      <b-form-input v-model="formModal.brand.contact_number"/>
    </b-form-group>
  </b-modal>
</div>
</template>

<script>
import firebase from "firebase";
import FormatDateTime from "@/components/share/formatDateTime";

export default {
  name: "TableBrands",
  components: {FormatDateTime},
  data() {
    return {
      filter: '',
      perPage: 10,
      currentPage: 1,
      sortBy: 'company_name',
      sortDesc: false,
      filterIgnoredFields: ['id', 'doc'],
      lastDocumentFetched: null,
      startAfterDocsList: [],
      fields: [
        {key: "company_name", label: 'Nombre de la Marca', sortable: true},
        {key: "products_count", label: 'Cantidad de Productos', sortable: true},
        {key: "created_at", label: 'Cantidad de Productos', sortable: true},
        {key: "options", label: 'Acciones', sortable: false}
      ],
      brands: [],
      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      },
      formModal: {
        visible: false,
        brand: {},
        brandId: null
      }
    }
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    async getBrands() {
      let query = firebase.firestore().collection('brands')
          .limit(this.perPage)
          .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.filter !== '' && this.filter !== null) {
        query = query.where('filterKeywords', 'array-contains-any', this.filter.toLowerCase().split(' '))
      }

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.brands[this.brands.length - 1].doc
        query = query.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      let brandsArray = []
      return query.get().then(brands => {
        brands.forEach(s => {
          brandsArray.push({
            id: s.id,
            ...s.data(),
            doc: s
          })
        })

        this.brands = brandsArray;
        return brandsArray;
      }).catch(error => {
        console.log(error)
        this.brands = []
        return []
      })


      // const db = firebase.firestore();
      // const brands = await db.collection('brands').get();
      // this.brands = [];
      // brands.forEach(b => {
      //   let d = b.data();
      //   d.id = b.id;
      //   d.doc = b;
      //   d.created_at = d.created_at != null ? d.created_at.toDate().toLocaleString('es-MX', this.dateOptions) : null;
      //   this.brands.push(d);
      // })
    },
    openFormModal(doc = null) {
      if (doc) {
        this.formModal.brandId = doc.id;
        this.formModal.brand = doc.data();
        this.formModal.visible = true;
      } else {
        this.formModal.brandId = null;
        this.formModal.brand = {};
        this.formModal.visible = true;
      }
    },
    saveBrand() {
      const db = firebase.firestore().collection('brands');
      if (this.formModal.brandId) {
        db.doc(this.formModal.brandId).update(this.formModal.brand);
      } else {
        this.formModal.brand.created_at = firebase.firestore.Timestamp.fromDate(new Date());
        this.formModal.brand.products_count = 0;
        db.doc().set(this.formModal.brand);
      }

      this.$bvToast.toast('Marca agregada a la base', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
      });

      this.getBrands();
    }
  },
  computed: {
    isCompanyNameValid: function () {
      let cn = this.formModal.brand.company_name;
      return cn == null ? false : cn.replaceAll(' ', '').length >= 2;
    },
    totalRows: function () {
      return this.brands.length;
    },
    isCurrentPageLastPage() {
      return this.brands.length < this.perPage
    },
    rows() {
      return this.brands.length
    },
  }
}
</script>

<style scoped>

</style>