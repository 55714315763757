import Dashboard from "@/views/admin/Dashboard";
import Catalog from "@/views/admin/catalog/Catalog";
import CatalogProduct from "@/views/admin/catalog/CatalogProduct";
import Categories from "@/views/admin/catalog/Categories";
import CategoryForm from "@/views/admin/catalog/CategoryForm";
import Orders from "@/views/admin/orders/Orders";
import OrderSummary from "@/views/admin/orders/OrderSummary";
import Pays from "@/views/admin/Pays"
import Invoices from "@/views/admin/Invoices"
import Users from "@/views/admin/users/Users"
import UserRegister from "@/views/admin/users/UserRegister"
import Suppliers from "@/views/admin/suppliers/SuppliersView"
import SupplierRegister from "@/views/admin/suppliers/SupplierRegister"
import Settings from "@/views/admin/Settings"
import Bonos from "@/views/admin/bonos/Bonos";
import BrandsView from "@/views/admin/brands/BrandsView";
import TaxesView from "@/views/admin/taxes/TaxesView";
import Banners from "@/views/admin/store/Banners";
import ProductGroupView from "@/views/admin/catalog/ProductGroupView";
import FormProductsGroup from "@/views/admin/catalog/FormProductsGroup";
import ObjectiveView from "@/views/admin/objetives/ObjectiveView";
import ObjectiveForm from "@/views/admin/objetives/ObjectiveForm";

import authorizedMiddleware from "../middleware/admin/auth"
import PackageDeliveryProcess from "@/views/admin/orders/PackageDeliveryProcess";
import ShippingGuide from "@/views/admin/orders/ShippingGuide";
import BonosPayment from "../views/admin/bonos/BonosPayment";
import SubcategoryFilterForm from "@/views/admin/catalog/SubcategoryFilterForm";

const routes = [
    {
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next,})
        }
    },
    {
        path: '/admin',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/store/banners',
        name: 'Banners',
        component: Banners,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2, 3]})
        }
    },
    //==================================================================================================================
    //==============================                PRODUCTS                ============================================
    //==================================================================================================================
    {
        path: '/admin/products',
        name: 'Catalog',
        component: Catalog,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products/new',
        name: 'CatalogProduct',
        component: CatalogProduct,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products/edit/:id',
        name: 'CatalogProductEdit',
        component: CatalogProduct,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products-groups',
        name: 'CatalogGroups',
        component: ProductGroupView,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products-groups/form',
        name: 'CatalogGroupsCreate',
        component: FormProductsGroup,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products-groups/form/:id',
        name: 'CatalogGroupsUpdate',
        component: FormProductsGroup,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },

    //==================================================================================================================
    //==============================                CATEGORY                ============================================
    //==================================================================================================================
    {
        path: '/admin/products/categories',
        name: 'Categories',
        component: Categories,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products/categories/category',
        name: 'AddCategory',
        component: CategoryForm,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products/categories/category/:id',
        name: 'EditCategory',
        component: CategoryForm,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/products/categories/category/:categoryId/filters/:subcategoryId',
        name: 'EditFiltersCategory',
        component: SubcategoryFilterForm,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },

    //==================================================================================================================
    //===============================                BRANDS                =============================================
    //==================================================================================================================
    {
        path: '/admin/brands',
        name: 'Brands',
        component: BrandsView,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    //==================================================================================================================
    //===============================                     TAXES                =========================================
    //==================================================================================================================
    {
        path: '/admin/taxes',
        name: 'TaxesView',
        component: TaxesView,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },

    //==================================================================================================================
    //===============================                OBJECTIVES                =========================================
    //==================================================================================================================
    {
        path: '/admin/objectives',
        name: 'ObjectivesView',
        component: ObjectiveView,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/objectives/form',
        name: 'ObjectivesForm',
        component: ObjectiveForm,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/objectives/form/:id',
        name: 'ObjectivesFormEdit',
        component: ObjectiveForm,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },

    //==================================================================================================================
    //================================                PAYMENT                ===========================================
    //==================================================================================================================
    {
        path: '/admin/products/bonos',
        name: 'Bonos',
        component: Bonos,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1]})
        }
    },
    {
        path: '/admin/payment/pays',
        name: 'Pays',
        component: Pays,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1]})
        }
    },
    {
        path: '/admin/payment/invoices',
        name: 'Invoices',
        component: Invoices,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    {
        path: '/admin/bonos',
        name: 'BonosPayment',
        component: BonosPayment,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    //==================================================================================================================
    //==============================                ACCOUNT                =============================================
    //==================================================================================================================
    {
        path: '/admin/users/',
        name: 'Users',
        component: Users,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    {
        path: '/admin/users/register',
        name: 'UserRegister',
        component: UserRegister,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    {
        path: '/admin/users/edit/:userUID',
        name: 'UserEdit',
        component: UserRegister,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    {
        path: '/admin/account/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next})
        }
    },
    //==================================================================================================================
    //==============================                SUPPLIERS                ===========================================
    //==================================================================================================================
    {
        path: '/admin/suppliers/',
        name: 'Suppliers',
        component: Suppliers,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/suppliers/register',
        name: 'SuppliersRegister',
        component: SupplierRegister,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },
    {
        path: '/admin/suppliers/register/:id',
        name: 'SuppliersRegisterEdit',
        component: SupplierRegister,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [1, 2]})
        }
    },


    {
        path: '/admin/orders',
        name: 'Orders',
        component: Orders,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [4, 5]})
        }
    },
    {
        path: '/admin/orders/:orderId',
        name: 'OrderProcess',
        component: PackageDeliveryProcess,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [4, 5]})
        }
    },
    {
        path: '/admin/orders/guide/:supplierId/:orderId',
        name: 'OrderShippingGuide',
        component: ShippingGuide,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [4, 5]})
        }
    },
    {
        path: '/admin/orders/order-summary',
        name: 'OrderSummary',
        component: OrderSummary,
        beforeEnter: (to, from, next) => {
            authorizedMiddleware({to: to, from: from, next: next, roleIds: [4, 5]})
        }
    },
]

// for (let i = 0; i < routes.length; i += 1) {
//     routes[i].meta.middleware.push(authorizedMiddleware)
// }

export default routes