<template>
  <b-navbar class="navbar navbar-expand-lg navbar-light bg-light sticky-top" toggleable="lg">
    <div class="container">
      <b-navbar-brand>
        <router-link to="/" class="navbar-brand">
          <img src="../assets/img/logos/xplits.png" class="img-fluid" width="80px" alt="Xplits Logo">
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="navbarNav"></b-navbar-toggle>

      <b-collapse id="navbarNav" is-nav>
        <b-navbar-nav class="navbar-nav ml-auto">
          <b-nav-item to="/">
            Inicio
          </b-nav-item>

          <b-nav-item @click="openMenuCategories" class="d-none d-lg-block">
            Tienda <i class="material-icons-outlined mdc-18">arrow_drop_down</i>
            <MenuStoreCategories :categories="categoriesByLevel" :subCategories="subCategories"
                                 :is-menu-categories-open="isMenuCategoriesOpen"></MenuStoreCategories>
          </b-nav-item>

          <b-nav-item-dropdown text="Tienda" class="d-block d-lg-none">
            <b-dropdown-item :to="'/store/categories/' + category.id" v-for="(category, index) in categories"
                             v-bind:key="index + 'categories-nav'">
              <i class="material-icons-outlined text-secondary mr-1">category</i>
              {{ category.text }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <template v-if="user.loggedIn">
            <b-nav-item-dropdown text="Mi Xplits" class="">
              <b-dropdown-item to="/xplits/panel">
                <i class="material-icons-outlined text-secondary mr-1">dashboard</i>
                Mi panel
              </b-dropdown-item>
              <b-dropdown-item to="/xplits/panel?tabIndex=1">
                <i class="material-icons-outlined text-secondary mr-1">group_add</i>
                Mis invitados
              </b-dropdown-item>
              <b-dropdown-item to="/xplits/panel?tabIndex=2">
                <i class="material-icons-outlined text-secondary mr-1">shop</i>
                Mis pedidos
              </b-dropdown-item>
              <b-dropdown-item to="/xplits/panel?tabIndex=3">
                <i class="material-icons-outlined text-secondary mr-1">receipt</i>
                Mis bonos
              </b-dropdown-item>
              <b-dropdown-item to="/xplits/panel?tabIndex=4">
                <i class="material-icons-outlined text-secondary mr-1">today</i>
                Dale Xplits
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>

          <b-nav-item to="/about-us">
            Cómo funciona
          </b-nav-item>

          <b-nav-item to="/contact">
            Contacto
          </b-nav-item>

          <template v-if="user.loggedIn">
            <b-nav-item-dropdown>
              <template v-slot:button-content>
                {{ user.data ? user.data.displayName : "Cargando..." }}
              </template>
              <router-link
                  v-if="user.adminData != null"
                  :to="{name: 'Dashboard'}"
                  v-slot="{ href, route, navigate, isActive }"
                  custom>
                <b-dropdown-item :href="href"
                                 @click="navigate"
                                 :active="isActive">
                  <i class="material-icons-outlined text-secondary mr-1">dashboard</i>
                  Panel Administración
                </b-dropdown-item>
              </router-link>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click.prevent="signOut" class="text-uppercase">
                <i class="material-icons-outlined text-secondary mr-1">exit_to_app</i>
                Cerrar sesión
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>

        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <template v-if="user.loggedIn">
            <b-nav-item to="/profile">
              <i class="material-icons-outlined text-muted">account_circle</i>
            </b-nav-item>
            <b-nav-item v-if="!hideShoppingCart">
              <div @click="toggleClass()">
                <i class="material-icons-outlined text-muted">local_mall</i>
                <div class="shopping-cart-items">
                  {{ cartProductsCount }}
                </div>
              </div>
            </b-nav-item>
          </template>

          <template v-else>
            <b-nav-item to="/login">
              Iniciar sesión
            </b-nav-item>
            <b-nav-item to="/register">
              Registrarse
            </b-nav-item>
          </template>
        </b-navbar-nav>

      </b-collapse>
      <ShoppingCart v-bind:class="[isCartOpen && !hideShoppingCart ? 'd-block' : 'd-none']"></ShoppingCart>
    </div>
  </b-navbar>
</template>

<script>
import ShoppingCart from "@/components/Home/ShoppingCart";
import {mapGetters} from 'vuex'
import firebase from "firebase";
import MenuStoreCategories from "@/components/MenuStoreCategories";

export default {
  props: {
    hideShoppingCart: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getCategories()

  },
  components: {MenuStoreCategories, ShoppingCart},
  data() {
    return {
      categories: [],
      subCategories: {},
      isMenuCategoriesOpen: false
    }
  },
  methods: {
    toggleClass() {
      this.$store.dispatch('toggleClassCart')
    },
    openMenuCategories() {
      this.isMenuCategoriesOpen = !this.isMenuCategoriesOpen
    },
    signOut() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "Login"
            });
          });
    },
    async getCategories() {
      const db = firebase.firestore()
      const categories = await db.collection('categories').get()
      categories.forEach(doc => {

        this.categories.push({
          text: doc.data().name,
          id: doc.id,
          level: doc.data().level,
          data: doc.data()
        })
        if (!this.subCategories[doc.id]) {
          this.subCategories[doc.id] = []
        }

        db.collection('categories').doc(doc.id).collection('subcategories').get()
            .then(subCategories => {
              subCategories.forEach(s => {
                this.subCategories[doc.id].push({
                  id: s.id,
                  title: s.data().title
                })
              })
            })
      })
    },
  },
  computed: {
    ...mapGetters(['isCartOpen', 'user', 'cartProductsCount']),
    categoriesByLevel() {
      let x = this.categories
      return x.sort(function(a, b) {
        return (a.level ?? 0) - (b.level ?? 0)
      })
    }
  },
}
</script>