<template>
  <div>
    <v-navbar/>
    <v-sidebar/>

    <b-container :fluid="true" class="mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"/>
        </b-col>
      </b-row>

      <!-- TABLE -->
      <b-row class="mb-3">
        <b-col md="12">
          <table-suppliers/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import TableSuppliers from "@/components/admin/Suppliers/TableSuppliers";

export default {
  metaInfo: { title: 'Proveedores', },
  name: "SuppliersView",
  components: {
    TableSuppliers,
    'v-sidebar': Sidebar,
    'v-navbar': Navbar
  },
  data() {
    return {
      items: [
        { text: 'Inicio', to: {name: 'Dashboard'} },
        { text: 'Proveedores' }
      ],
    }
  }
}
</script>

<style scoped>

</style>