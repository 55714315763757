<template>
  <div class="protein">
    <Navbar/>

    <section class="section-50"
             :style="{backgroundColor: goal.color,
              backgroundImage: 'url('+goal.url+')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
      }">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="font-weight-bold text-body-inverse">{{ goal.title }}</h3>
          </div>
          <div class="col-md-12">
            <b-breadcrumb :items="items"
                          :style="{backgroundColor: 'rgba(250,250,250,0.0)'}"
                          style="margin-top: 0"/>
          </div>
        </div>
      </div>
    </section>

    <SearchBar v-model="searchText"
               @search-submit="handleSearch"
               current-view/>
    <div class="container mt-3">

      <!-- PRODUCTS -->
      <b-row class="mb-4">

        <!-- RIGHT COLUMN PRODUCT -->
        <b-col md="12" class="mb-3">
          <div class="mb-3">
            <b-row>
              <product-item v-for="product in products"
                            :key="product.id"
                            :value="product"
                            cols="6"
                            md="3"/>
            </b-row>

            <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
              <div slot="no-more">No hay más resultados.</div>
              <div slot="no-results">No hay resultados.</div>
            </infinite-loading>

          </div>

        </b-col>
      </b-row>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import firebase from "firebase";
import InfiniteLoading from 'vue-infinite-loading';
import ProductItem from "@/components/product/ProductItem";

export default {
  name: "Goal",
  components: {
    ProductItem,
    Navbar, Footer, SearchBar, InfiniteLoading
  },
  metaInfo: {
    title: 'Objetivos',
  },
  data() {
    return {
      productsPerPage: 10,
      goal: {},
      products: [],
      selectedFilterItems: [],
      items: [
        {text: 'Inicio', to: {name: 'Home'}},
        {text: 'Tienda', to: {name: 'Store'}},
        {text: 'Loading'},
      ],
      lastDocInfiniteScroll: null,
      searchText: '',
    }
  },
  mounted() {
    this.fetchGoal()
  },
  methods: {
    handleSearch(text) {
      this.searchText = text
      this.products = []
      this.$refs.infiniteLoading.stateChanger.reset() // reset infinite loader, so it will fetch products again
    },
    handleFiltersChange() {

    },
    async infiniteHandler(state) {
      const productsFetchResult = await this.fetchProducts(false)

      this.lastDocInfiniteScroll = productsFetchResult.lastDocumentSnapshot

      if (productsFetchResult.count > 0) {
        state.loaded()
      } else {
        state.complete()
      }
    },
    async fetchProducts(overrideProducts = true) {
      const db = firebase.firestore()
      let products = db.collection('goal_categories').doc(this.$route.params.goalId).collection('products')

      products = products.orderBy('created_at', 'asc')

      if (this.searchText) {
        // search only 10 first words because firebase only allows 10 word comparisons
        const searchable10WordArray = this.searchText.split(' ').slice(0, 10).map(word => word.toLowerCase())
        products = products.where('filterKeywords', 'array-contains-any', searchable10WordArray)
      }

      if (this.lastDocInfiniteScroll) {
        products = products.startAfter(this.lastDocInfiniteScroll)
      }

      products = await products.limit(this.productsPerPage).get()

      if (overrideProducts) {
        this.products = []
      }

      let queryResultsCount = 0

      for await (let doc of products.docs) {
        queryResultsCount += 1
        let fullProduct = await db.collection('products').doc(doc.id).get()

        this.products.push({
          id: doc.id,
          ...fullProduct.data()
        })
      }

      return {
        count: queryResultsCount,
        lastDocumentSnapshot: products.docs[products.docs.length - 1]
      }
    },
    async fetchGoal() {
      const db = firebase.firestore();
      const goal = await db.collection('goal_categories').doc(this.$route.params.goalId).get()
      if (goal.exists) {
        this.goal = {id: goal.id, ...goal.data()}
        this.items[2].text = goal.data().title
      } else {
        await this.$router.push({name: 'Home'})
      }
    }
  },
  computed: {}
}
</script>

<style scoped>
/deep/ .jumbotron h1 {
  color: var(--theme-on-primary-inverse);
}

.breadcrumb-item.active {
  color: var(--theme-on-primary-inverse);
}
</style>