<template>
<div>
  <Navbar/>
  <Sidebar/>

  <b-container class="mt-3" :fluid="true">
    <b-row>
      <b-col md="6">
        <b-breadcrumb :items="breadcrumb"/>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="12">
        <table-brands/>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import TableBrands from "@/components/admin/brands/TableBrands";
export default {
  name: "BrandsView",
  components: {TableBrands, Sidebar, Navbar},
  data: () => {
    return {
      breadcrumb: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Marcas',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>