<template>
  <div>
    <div v-for="(p, index) in people" v-bind:key="index + '-person'"
         :class="['list-ranking mt-3',
         index === 1
            ? 'list-ranking-primary'
            : index === 2 ? 'list-ranking-default'
                  : 'list-ranking-secondary']">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-3 text-center text-md-left my-2">
          <span class="list-ranking-number">{{ index }}</span>
        </div>
        <div class="col-md-6 col-lg-9 text-center text-md-left my-2">
          <h6 class="subtitle-2 text-white">{{ getName(p.firebase_uuid) }}</h6>
<!--          <h6 class="subtitle-2 text-white">N:12 | R: 1,1012</h6>-->
        </div>
      </div>
    </div>

<!--    <div class="list-ranking list-ranking-default mt-3">-->
<!--      <div class="row align-items-center">-->
<!--        <div class="col-md-6 col-lg-3 text-center text-md-left my-2">-->
<!--          <span class="list-ranking-number">02</span>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-lg-9 text-center text-md-left my-2">-->
<!--          <h6 class="subtitle-2 text-white">E. Garza</h6>-->
<!--          <h6 class="subtitle-2 text-white">N:8 | R: 1,005</h6>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="list-ranking list-ranking-secondary mt-3">-->
<!--      <div class="row align-items-center">-->
<!--        <div class="col-md-6 col-lg-3 text-center text-md-left my-2">-->
<!--          <span class="list-ranking-number">03</span>-->
<!--        </div>-->
<!--        <div class="col-md-6 col-lg-9 text-center text-md-left my-2">-->
<!--          <h6 class="subtitle-2 text-white">G. Grijalva</h6>-->
<!--          <h6 class="subtitle-2 text-white">N:11 | R: 1,1050</h6>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  name: 'PerformanceRanking',
  props: ['people'],
  methods: {
    async getName(uuid) {
      const user = await firebase.firestore().collection('users').doc(uuid).get()
      return user.data().displayName
    }
  }
  // data() {
  //   return {
  //     people: []
  //   }
  // }
}
</script>