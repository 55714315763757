<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container class="mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <b-row class="mb-3">
        <b-col md="12">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Datos"
                   title-item-class="text-uppercase ripple ripple-dark"
                   active>
              <form-products-group-data v-model="productsGroup.data"
                                        @save="saveProductsGroup"/>
            </b-tab>
            <b-tab title="Productos"
                   :disabled="productsGroupUID == null"
                   title-item-class="text-uppercase ripple ripple-dark">

              <form-products-group-list v-if="productsGroupUID != null"
                                        v-model="productsGroup.data"
                                        :id="productsGroupUID" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import FormProductsGroupData from "@/components/admin/products/FormProductsGroupData";
import firebase from "firebase";
import FormProductsGroupList from "@/components/admin/products/FormProductsGroupList";

export default {
  name: "FormProductsGroup",
  components: {FormProductsGroupList, FormProductsGroupData, Navbar, Sidebar},
  data: () => {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Grupos',
          to: {name: 'CatalogGroups'}
        },
        {
          text: 'Formulario',
        },
      ],
      productsGroupUID: null,
      productsGroup: {
        data: {
          description: '',
          name: '',
          filters: [],
          counts: 0
        },
        doc: null,
        ref: firebase.firestore().collection('products_groups')
      }
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.productsGroupUID = id;
      this.getProductsGroup();
    }
  },
  methods: {
    saveProductsGroup() {
      let db = firebase.firestore().collection('products_groups');
      if (this.productsGroupUID) {
        db.doc(this.productsGroupUID).update(this.productsGroup.data);
      } else {
        let doc = db.doc()
        doc.set(this.productsGroup.data, { merge: true })
        this.productsGroupUID = doc.id;
      }

      this.$bvToast.toast('Grupo de Producto guardado', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
      });
    },
    async getProductsGroup() {
      let doc = await this.productsGroup.ref.doc(this.productsGroupUID).get()
      this.productsGroup.doc = doc;
      this.productsGroup.data = doc.data();
    }
  }
}
</script>

<style scoped>

</style>