<template>
  <div class="px-3">
    <v-carousel :visible-slides="elementsVisible"
                :slideMultiple="true"
                :slide-ratio="1 / elementsVisible"
                fixedHeight="420px"
                :gap="3"
                :dragging-distance="50"
                class="vue-carousel-height shadow-0dp">
      <v-slide v-for="product in products"
               :key="product.id"
               class="text-center ripple ripple-secondary">
        <template v-slot:content>
          <b-row>
            <product-item :value="{id: product.id, ...product.data}" cols="12" md="12" :full-height="true"/>
          </b-row>
        </template>
      </v-slide>
    </v-carousel>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import firebase from "firebase/app";
import ProductItem from "@/components/product/ProductItem";

export default {
  name: 'TopProductsCarousel',
  components: {
    ProductItem,
    'v-carousel': VueperSlides,
    'v-slide': VueperSlide
  },
  data() {
    return {
      products: [],
      elementsVisible: 3
    }
  },
  mounted() {
    this.getProducts()
    this.onWindowResized()
  },
  created() {
    window.addEventListener('resize', this.onWindowResized);
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResized);
  },
  methods: {
    async getProducts() {
      let products = await firebase.firestore()
          .collection('products')
          .limit(21)
          .orderBy('created_at', 'desc')
          .get()

      this.products = []
      products.forEach(p => {
        this.products.push({
          id: p.id,
          data: p.data(),
          doc: p
        })
      })
    },
    onWindowResized() {
      let width = window.innerWidth;
      this.elementsVisible =
          width < 768 ? 2 : 3;
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .vue-carousel-height, /deep/ .vueperslides {
  height: 350px;

  .vueperslides__inner {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

@media only screen and (min-width: 576px) {
  /deep/ .vue-carousel-height, /deep/ .vueperslides {
    height: 375px;
  }
}

@media only screen and (min-width: 768px) {
  /deep/ .vue-carousel-height, /deep/ .vueperslides {
    height: 400px;
  }
}

@media only screen and (min-width: 992px) {
  /deep/ .vue-carousel-height, /deep/ .vueperslides {
    height: 425px;
  }
}

@media only screen and (min-width: 1200px) {
  /deep/ .vue-carousel-height, /deep/ .vueperslides {
    height: 450px;
  }
}
</style>