<template>
  <b-card class="card-outline">
    <b-row>
      <b-col md="12">
        <b-form-group label="Nombre del Proveedor" :validated="validName">
          <b-form-input v-model="value.name"
                        placeholder="Nutrición Universal S.A. de C.V."/>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="RFC" :validated="validRFC">
          <b-form-input v-model="value.rfc"/>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Nombre del contacto" :validated="validContactName">
          <b-form-input v-model="value.contactName"
                        placeholder="Mauricio Gaytán"/>
        </b-form-group>
      </b-col>
      <b-col md="12" lg="6">
        <b-form-group label="Teléfonos de contacto" >
          <b-form-tags v-model="value.contactPhones"
                       style="padding: 0; border: 0"
                       :tag-validator="phoneValidator"
                       no-outer-focus>
            <template v-slot="{tags, inputAttrs, inputHandlers, addTag, removeTag }">

              <b-form-input v-bind="inputAttrs"
                            v-on="inputHandlers"
                            placeholder="Agrega nuevo telefono">
                <b-input-group-append>
                  <b-button variant="primary"
                            @click="addTag()">Agregar</b-button>
                </b-input-group-append>
              </b-form-input>

              <b-list-group class="my-1">
                <b-list-group-item v-for="tag in tags" :key="tag">
                  <div class="d-flex justify-content-between">
                    <p v-text="tag" class="m-0"/>
                    <b-button-close @click="removeTag(tag)"/>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>


      <b-col md="12" lg="6">
        <b-form-group label="Correos de contacto">
          <b-form-tags v-model="value.contactMails"
                       style="padding: 0; border: 0"
                       :tag-validator="emailValidator"
                       no-outer-focus>
            <template v-slot="{tags, inputAttrs, inputHandlers, addTag, removeTag }">
              <b-form-input v-bind="inputAttrs"
                            v-on="inputHandlers"
                            placeholder="Agrega nuevo correo">
                <b-input-group-append>
                  <b-button variant="primary"
                            @click="addTag()">Agregar</b-button>
                </b-input-group-append>
              </b-form-input>

              <b-list-group class="my-1">
                <b-list-group-item v-for="tag in tags" :key="tag">
                  <div class="d-flex justify-content-between align-items-center">
                    <p v-text="tag"
                       class="m-0"/>
                    <b-button-close @click="removeTag(tag)"/>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>


    <template #footer>
      <div class="text-right">
        <b-button variant="light" class="btn-round ripple mr-3" @click="$router.back()">
          <i class="material-icons-outlined">arrow_back</i>Regresar
        </b-button>
        <b-button variant="primary" :disabled="!(validName && validContactName && validRFC)"
                  @click="saveSupplier"
                  class="btn-round ripple px-5">
          Guardar
        </b-button>
      </div>
    </template>

  </b-card>
</template>

<script>

export default {
  name: "FormSuppliersInfo",
  props: {
    value: Object
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    saveSupplier() {
      this.$emit('save', this.value)
    },
    phoneValidator(phone) {
      const r = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
      return r.test(phone);
    },
    emailValidator(email) {
      const r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return r.test(email);
    }
  },
  computed: {
    validRFC() {
      const r = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      return r.test(this.value.rfc)
    },
    validName() {
      return this.value.name.length > 2
    },
    validContactName() {
      return this.value.contactName.length > 2
    },
  }
}
</script>

<style scoped>

</style>