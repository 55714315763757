<template>
  <b-modal v-model="visible"
           ok-title="¡Gracias!"
           ok-only
           ok-variant="link ripple ripple-dark rounded"
           centered
           @hidden="closeModal">
    <div class="text-center">
      <img src="@/assets/img/empty-states/welcome.svg" class="img-fluid mb-5" width="200px" alt="Imagen de bienvenida">

      <h5 class="font-weight-bold">Bienvenido a Xplits {{ user.data ? user.data.displayName : "Cargando..." }}</h5>
      <h6 class="subtitle-1 text-muted">
        Gracias por registrarte con nosotros!
      </h6>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import firebase from "firebase/app";

export default {
  name: "ModalWelcome",
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      visible: false
    }
  },
  mounted () {
    if (this.user.data && !this.user.data.welcomeModalShown) {
      this.visible = true
    }
  },
  methods: {
    closeModal() {
      let uid = this.user.data.uid
      let db = firebase.firestore().collection('users')

      if(uid) {
        db.doc(uid).update({
          welcomeModalShown: true
        })
      }
    }
  }
}
</script>