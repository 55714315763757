<template>
  <div>
    <!-- NAME AND USER LEVEL -->
    <div class="card card-outline mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 my-1">
            <div class="d-md-flex align-items-center">
              <div class="mb-3">
                <b-avatar size="4rem" class="material-icons mdc-38">person</b-avatar>
              </div>
              <div class="mx-3">
                <h6 v-text="user.displayName"></h6>
                <h6 class="subtitle-1">
                  Tienes {{ peopleInvitedByUser }} persona(s) que completaron su nucleo.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="row">
          <!-- PERFORMANCE -->
          <div class="col-md-12 mb-3">
            <div class="card card-outline">
              <div class="card-header text-center my-2">
                <h6>Meta de gastos</h6>
              </div>
              <div class="card-body">
                <h6 class="subtitle-1 text-center text-uppercase">Gastos</h6>
                <div class="row justify-content-center text-center">
                  <div class="col-md-12">
                    <format-price
                        :value="userMicroserviceInfo.monthly_total_purchased"
                        class="text-secondary subtitle-1 font-weight-bold"/>
                    <BProgress :value="userMicroserviceInfo.monthly_total_purchased"
                               :max="this.userMicroserviceInfo.purchase_goal || 2000"></BProgress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <!-- PERFORMANCE -->
          <div class="col-md-12 mb-3">
            <div class="card card-outline">
              <div class="card-header text-center my-2">
                <h6>Mi desempeño</h6>
              </div>
              <div class="card-body">
                <h6 class="subtitle-1 text-center text-uppercase">Ranking</h6>
                <div class="row justify-content-center text-center">
                  <div class="col-md-12">
                    <h6 class="subtitle-1 font-weight-bold mb-3">
                      {{ rankValue > 0 ? rankValue : "Sin rango"}}
                    </h6>
                    <BProgress :value="rankValue" :max="rankMax"></BProgress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAddInvites v-model="modalAddInvites.visible" :user="user"></ModalAddInvites>

  </div>
</template>

<script>
import FormatPrice from "@/components/share/formatPrice";
import ModalAddInvites from "@/components/xplits/invites/ModalAddInvites";
import 'firebase/functions'

export default {
  name: 'MyPanel',
  props: [ 'user', 'userMicroserviceInfo' ],
  data() {
    return {
      peopleInvitedByUser: this.userMicroserviceInfo?.people_in_network ?? 0,
      rankValue: this.calculateRankBasedOnPeople(this.userMicroserviceInfo?.people_in_network),
      rankMax: 6,
      modalAddInvites: {
        visible: false
      },
    }
  },
  components: {
    ModalAddInvites,
    FormatPrice,
  },
  methods: {
    calculateRankBasedOnPeople(people) {
      if (!people) {
        return 0
      }

      if (people > 0 && people <= 5) {
        return 1
      }

      if (people > 5 && people <=25) {
        return 2
      }

      if (people > 25 && people <=125) {
        return 3
      }

      if (people > 125 && people <=625) {
        return 4
      }

      if (people > 625 && people <= 3125) {
        return 5
      }

      if (people > 3125 && people <=15625) {
        return 6
      }

      return 0
    }
  }
}
</script>