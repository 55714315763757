<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12">
          <table-products-group/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import TableProductsGroup from "@/components/admin/products/TableProductsGroup";

export default {
  name: "ProductGroupView",
  metaInfo: {
    title: 'Grupo de variantes de Producto',
  },
  components: {TableProductsGroup, Sidebar, Navbar},
  data() {
    return {
      items: [
        { text: 'Inicio', to: {name: 'Dashboard'} },
        { text: 'Grupos de Productos' }
      ],
    }
  }
}
</script>

<style scoped>

</style>