<template>
  <b-card class="card-outline">
    <template #header>
      Selecciona la dirección del remitente.
    </template>
    <b-row>
      <address-item v-for="address in addresses"
                    :key="address.id"
                    :value="address" lg="6" md="6">
        <b-btn variant="secondary"
               @click="saveAddress(address)"
               block>Seleccionar</b-btn>
      </address-item>
    </b-row>
  </b-card>
</template>

<script>
import firebase from "firebase";
import AddressItem from "@/components/checkout/components/AddressItem";

export default {
  name: "AddressStep",
  components: {AddressItem},
  props: {
    order: Object,
    supplierId: String
  },
  data() {
    return {
      addresses: []
    }
  },
  mounted() {
    this.getAddresses()
  },
  methods: {
    async getAddresses() {
      let db = firebase.firestore()

      let addressSnap = await db.collection('suppliers').doc(this.supplierId).collection('addresses').get()
      let list = []
      addressSnap.forEach(a => {
        list.push({
          id: a.id,
          ...a.data()
        })
      })

      this.addresses = list

      if(!this.order.emitterAddress && list.length === 1) {
        this.saveAddress(list[0])
      }
    },
    saveAddress(address) {
      this.$emit('save', address)
    }
  }
}
</script>

<style scoped>

</style>