<template>
  <div class="contact">
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- PHONES AND EMAIL -->
      <div class="row mb-3">
        <div class="col-md-6">
          <h5 class="text-primary font-weight-bold">Queremos escucharte</h5>
          <h6 class="text-primary">Envíanos un mensaje</h6>
          <hr>
          <h6 class="subtitle-1 font-weight-bold">Teléfonos</h6>
          <h6 class="subtitle-2"><a href="tel:8183637845">(81) 8363 7845</a></h6>
          <h6 class="subtitle-2"><a href="tel:8124267225">812 426 7225</a></h6>
          <h6 class="subtitle-2 my-3"><a href="mailto:soporte@xplits.com">soporte@xplits.com</a></h6>
          <h6 class="subtitle-2">
            Montes Cárpatos 306B
            Residencial San Agustín <br>
            San Pedro Garza García, N.L. México
            CP 62260
          </h6>
        </div>
        <!-- FORM -->
        <div class="col-md-6">
          <b-form>
            <b-form-group>
              <b-form-input id="name" type="text" class="form-control-border-bottom"
                            placeholder="Nombre"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input id="email" type="email" class="form-control-border-bottom"
                            placeholder="Email"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input id="subject" type="text" class="form-control-border-bottom"
                            placeholder="Asunto"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-textarea id="message" class="form-control-border-bottom" placeholder="Mensaje">
              </b-form-textarea>
            </b-form-group>
            <div class="text-right">
              <button class="btn btn-secondary btn-round ripple ripple-dark py-2 px-5">Enviar</button>
            </div>
          </b-form>
        </div>
      </div>
      <!-- MAPS -->
      <div class="row mb-5">
        <div class="col-md-12">
          <div class="embed-responsive embed-responsive-21by9">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.463325056203!2d-100.3417409919433!3d25.65593156644417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662be0e839aaef3%3A0x53a29d9521c5165!2sMontes%20Carpatos%20306B%2C%20Residencial%20San%20Agust%C3%ADn%201er%20Sector%2C%2066260%20San%20Pedro%20Garza%20Garc%C3%ADa%2C%20N.L.!5e0!3m2!1sen!2smx!4v1612462762024!5m2!1sen!2smx"
                frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Contacto',
  },
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Home'}
        },
        {
          text: 'Contacto',
        },
      ]
    }
  },
  components: {
    Navbar,
    Footer,
    SearchBar,
  },
}
</script>