<template>
  <div>
    <div v-if="payoutsInfo.status === 'awaiting_cut'" class="card card-outline mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 text-center">
            <img src="@/assets/img/empty-states/waiting.svg" class="img-fluid mb-3" width="250px" alt="Icono de espera">
            <h6>Esperando fecha de corte...</h6>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card card-outline mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <strong>Fechas del ciclo:</strong>
            {{ this.calculations.cycle_dates ? formatDate(this.calculations.cycle_dates.lastCut.date) : 'Cargando...' }} -
            {{ this.calculations.cycle_dates ? formatDate(this.calculations.cycle_dates.nextCut.date) : 'Cargando...' }}
          </div>
        </div>
        <BTable borderless hover :items="items"></BTable>
        <BTable borderless hover :items="items2"></BTable>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  data() {
    return {
      items: [
        {concepto: 'Usuarios con meta completada', valor: 'Cargando...' },
        {concepto: 'Núcleos de usuario completados', valor: 'Cargando...' },
        {concepto: 'Acumulado para bonos', valor: 'Cargando...' },
        {concepto: 'Fecha y hora de cierre', valor: 'Cargando...' },
      ],
      items2: [],
      payoutsInfo: {status: 'awaiting_cut'},
      calculations: {}
    }
  },
  mounted() {
    this.fetchBonosInfo()
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleDateString('es-MX', {timeZone: 'America/Monterrey'})
    },
    async fetchBonosInfo() {
      const db = firebase.firestore()
      const payoutsInfoDocRef = db.collection('payouts_status').doc('now')

      this.payoutsInfo = await payoutsInfoDocRef.get().then(docSnap => docSnap.data())
        .catch(err => console.error("Error getting payoutsInfo in TableBonosTotal.vue", err))

      if (this.payoutsInfo.status === 'pending_payouts') {
        const payoutsCalculationsRef = payoutsInfoDocRef.collection('calculations')
        this.calculations = await payoutsCalculationsRef.orderBy('createdAt').limit(1).get()
            .then(querySnap => querySnap.docs[0].data())

        const date = new Date(this.calculations.createdAt)

        this.items[0].valor = this.calculations.total_completed_users_count
        this.items[1].valor = this.calculations.total_cores_count
        this.items[2].valor = `$ ${this.calculations.total_payout_amount} MXN`
        this.items[3].valor = date.toUTCString()

        // TODO: ADD EL DESGLOSE EXACTO DE LOS BONOS AQUI

      }
    }
  }
}
</script>