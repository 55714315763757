<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
        <b-col cols="12" xl="8" offset-xl="2">
          <b-alert v-if="productsGroup != null" :show="productsGroup.exists">
            Producto Ligado al grupo de producto
            <b v-text="productsGroup.data().name"></b> con id
            <router-link :to="{name: 'CatalogGroupsUpdate', params: {id: productsGroupUID}}">
              <code v-text="productsGroupUID" class="text-secondary" style="text-decoration: underline"></code>
            </router-link>
          </b-alert>
        </b-col>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Información básica"
                   title-item-class="text-uppercase ripple ripple-dark"
                   active>
              <FormProductInfo v-model="product.data"
                               :products-group-uid="productsGroupUID"
                               :products-group="productsGroup"
                               :settings="settings"
                               @save="saveProduct" ref="formProductInfoPage"/>
            </b-tab>
            <b-tab title="Descripción" :disabled="!isTabsEnabled"
                   title-item-class="text-uppercase ripple ripple-dark">
              <FormProductDescription v-if="isTabsEnabled"
                                      v-model="productDescription"
                                      @save="saveProductDescription"/>
            </b-tab>
            <b-tab title="Instrucciones de Uso" :disabled="!isTabsEnabled"
                   title-item-class="text-uppercase ripple ripple-dark">
              <FormProductDescription v-if="isTabsEnabled"
                                      v-model="productInstructions"
                                      @save="saveProductInstructions"/>
            </b-tab>
            <b-tab title="Descuentos" :disabled="!isTabsEnabled"
                   title-item-class="text-uppercase ripple ripple-dark">
              <FormProductDiscount v-if="isTabsEnabled" v-model="productId"/>
            </b-tab>
            <b-tab title="Imágenes" :disabled="!isTabsEnabled"
                   title-item-class="text-uppercase ripple ripple-dark">
              <FormProductImages v-if="isTabsEnabled" :product-id="productId"/>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import FormProductInfo from "@/components/admin/products/FormProductInfo";
import FormProductImages from "@/components/admin/products/FormProductImages";
import FormProductDescription from "@/components/admin/products/FormProductDescription";
import FormProductDiscount from "@/components/admin/products/FormProductDiscount";
import firebase from "firebase";

export default {
  metaInfo: {
    title: 'Producto',
  },
  components: {FormProductDiscount, FormProductDescription, FormProductImages, FormProductInfo, Navbar, Sidebar},
  data() {
    const db = firebase.firestore()
    return {
      db: db,
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Productos',
          to: {name: 'Catalog'}
        },
        {
          text: 'Catálogos',
          to: {name: 'Catalog'}
        },
        {
          text: 'Producto',
        },
      ],
      options: [
        {value: null, text: 'Selecciona una opción'},
        {value: 'a', text: 'Cualquiera'},
      ],
      productId: null,
      productsGroupUID: null,
      productsGroupFilters: {},
      productsGroup: null,
      product: {
        ref: db.collection('products'),
        doc: null,
        data: {
          is_visible: true,
          filters: [],
          subcategory: '',
          category: ''
        },
        discounts: null
      },
      settings: {
        unitDefault: '',
        unitsOptionsList: []
      },
      productDescription: '',
      productInstructions: ''
    }
  },
  beforeMount() {
    this.productId = this.$route.params.id ? this.$route.params.id : null;
    if (this.productId) {
      this.initProduct();
    }

    this.productsGroupUID = this.$route.query.productsGroupUID;
    if (this.productsGroupUID) {
      try {
        this.productsGroupFilters = JSON.parse(this.$route.query.productsGroupFilter);
        this.getProductsGroup();
      } catch (e) {
        this.productsGroupFilters = {}
      }
    }
  },
  mounted() {
    this.getProductSettings()

  },
  methods:{
    async getProductSettings() {
      let settings = await firebase.firestore().collection('settings').doc('products').get()
      this.settings = settings.data()
      if (this.product.data.stock_unit === '' || this.product.data.stock_unit == null) {
        this.product.data.stock_unit = this.settings.unitDefault
      }
    },
    async initProduct() {
      this.getProduct();
      let doc = await this.product.doc.get();
      this.product.data = doc.data();
      this.getDescription()
      this.getInstructions()
      if (this.product.data.productsGroupUID) {
        this.productsGroupUID = this.product.data.productsGroupUID;
        await this.getProductsGroup();
        this.$refs.formProductInfoPage.getProductsGroup();
      }
      this.product.discounts = this.product.doc.collection('discounts');
    },
    async getProductsGroup() {
      let group = await firebase.firestore().collection('products_groups').doc(this.productsGroupUID).get();
      if (group.exists) {
        this.productsGroup = group;
      } else {
        this.productsGroupUID = null;
      }
    },
    getProduct() {
      this.product.doc = this.product.ref.doc(this.productId);
    },
    getDescription() {
      if (this.productId) {
        let ref = firebase.storage().ref()
        let descriptionRef = ref.child('products').child('descriptions').child(this.productId).child('description.html')

        descriptionRef.getDownloadURL().then(url => {

          let xhr = new XMLHttpRequest();
          xhr.onload = () => {
            this.productDescription = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

        }).catch(() => {
          this.productDescription = ''
        })
      } else {
        this.productDescription = ''
      }
    },
    getInstructions() {
      if (this.productId) {
        let ref = firebase.storage().ref()
        let descriptionRef = ref.child('products').child('descriptions').child(this.productId).child('instructions.html')

        descriptionRef.getDownloadURL().then(url => {

          let xhr = new XMLHttpRequest();
          xhr.onload = () => {
            this.productInstructions = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

        }).catch(() => {
          this.productInstructions = ''
        })
      } else {
        this.productInstructions = ''
      }
    },
    saveProductDescription(html) {
      let ref = firebase.storage().ref()
      let descriptionRef = ref.child('products').child('descriptions').child(this.productId).child('description.html')

      descriptionRef.putString(html).then(() => {
        this.$bvToast.toast('Descripción del producto guardado', {
          title: 'Guardado',
          autoHideDelay: 6500,
          variant: 'primary',
          href: '/admin/products/catalog'
        });
      })
    },
    saveProductInstructions(html) {
      let ref = firebase.storage().ref()
      let descriptionRef = ref.child('products').child('descriptions').child(this.productId).child('instructions.html')

      descriptionRef.putString(html).then(() => {
        this.$bvToast.toast('Instrucciones del producto guardado', {
          title: 'Guardado',
          autoHideDelay: 6500,
          variant: 'primary',
          href: '/admin/products/catalog'
        });
      })
    },
    saveProduct(product) {
      if (this.productsGroup && this.productsGroup.exists) {

        let filtersLengthQuery = Object.keys(this.productsGroupFilters)
        // let json = JSON.stringify(x.length > 0 ? this.productsGroupFilters : product.productsGroupFilters)
        //
        // console.log(product, 'product group', this.productsGroup.id, json,
        //     JSON.stringify(this.productsGroupFilters), JSON.stringify(product.productsGroupFilters))


        product.productsGroupUID = this.productsGroup.id ?? null;
        product.productsGroupFilters = filtersLengthQuery.length > 0 ? this.productsGroupFilters : product.productsGroupFilters;
      }

      //Save Product Settings
      if (this.product.data.stock_unit) {
        console.log('stock_unit el')
        let settingsDoc = firebase.firestore().collection('settings').doc('products')
        let unitsOptList = this.settings.unitsOptionsList ?? []
        if (!unitsOptList.includes(this.product.data.stock_unit)) unitsOptList.push(this.product.data.stock_unit)
        this.settings.unitsOptionsList = unitsOptList
        this.settings.unitDefault = this.product.data.stock_unit
        settingsDoc.update({
          unitDefault: this.product.data.stock_unit,
          unitsOptionsList: unitsOptList
        })
      }

      if (this.productId) {
        this.product.ref.doc(this.productId).set(product, {merge: true});
      } else {
        const newProduct = this.product.ref.doc()
        newProduct.set(product, {merge: true})
        this.productId = newProduct.id
        this.product.doc = newProduct;
      }

      this.$bvToast.toast('Producto guardado en la base. Regresar a la lista de productos.', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
        href: '/admin/products/catalog'
      });
    }
  },
  computed: {
    isTabsEnabled: function () {
      return this.productId != null;
    }
  }
}
</script>