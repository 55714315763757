<template>
  <b-col :sm="sm" :md="md" :lg="lg">
    <component :is="noCard ? 'div' : 'b-card'" class="shadow-sm h-100">
      <h6 class="font-weight-bold subtitle-1" v-text="value.fullName"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="value.firstAddress"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="value.secondAddress"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="value.city+', '"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="value.state"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="value.country"/>
      <p class="text-wrap mb-1 subtitle-2" v-text="'Teléfono: '+value.phoneNumber"/>

      <template #footer v-if="hasFooterSlot">
        <slot>

        </slot>
      </template>
    </component>
  </b-col>
</template>

<script>
export default {
  name: "AddressItem",
  props: {
    value: Object,
    lg: {
      type: String,
      default: '4'
    },
    md: {
      type: String,
      default: '6'
    },
    sm: {
      type: String,
      default: '12'
    },
    noCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasFooterSlot() {
      return this.$slots['default']
    }
  }
}
</script>

<style scoped>

</style>