<template>
  <b-card class="card-outline">
    <template #header>
      <h6 class="font-weight-bold mt-2">Productos</h6>
      <div class="d-flex justify-content-between">
        <h6 class="subtitle-1 text-primary">Lista de Productos en el carrito</h6>
      </div>
    </template>

    <b-row>
      <b-col md="12" class="mb-5">
        <shopping-products-list :products.sync="products"
                                :loading="loading"
                                product-link-on-name/>
      </b-col>
      <b-col class="text-center">
        <h6 class="subtitle-1">Total:
          <span class="font-weight-bold" v-text="formatPrice(totalPrice)+' MXN'"></span>
        </h6>
      </b-col>
    </b-row>

    <template #footer>
      <b-button @click="continueWithProducts"
                class="btn-round float-right ripple ripple-dark px-5">
        Continuar
      </b-button>
    </template>

  </b-card>
</template>

<script>
import ShoppingProductsList from "@/components/share/ShoppingProductsList";
import firebase from "firebase/app";
import {mapGetters} from "vuex";

export default {
  name: "ProductsListStep",
  components: {ShoppingProductsList},
  data() {
    return {
      products: [],
      loading: false,
    }
  },
  mounted() {
    this.onCartOpen()
  },
  methods: {
    async onCartOpen() {
      this.products = []
      await this.fetchCartProducts();
      for (let i = 0; i < this.products.length; i++) {
        await this.getDiscounts(this.products[i]);
      }
    },
    async fetchCartProducts() {
      const productsSnap = await firebase.firestore()
          .collection('shopping_carts')
          .doc(this.user.data.uid)
          .collection('products')
          .get()

      productsSnap.forEach(product => {
        this.products.push({
          data: product.data(),
          id: product.id,
          doc: product,
          discounts: []
        })
      })
    },
    async getDiscounts(product) {
      let id = product.id;
      const today = firebase.firestore.Timestamp.now();
      const discounts = await firebase.firestore()
          .collection('products')
          .doc(id)
          .collection('discounts')
          .where('code', '==', null)
          .get()
      const discountCode = await firebase.firestore().collection('products')
          .doc(id)
          .collection('discounts')
          .where('code', '==', product.data.discountCode)
          .get()

      let discountsEl = []
      discounts.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          discountsEl.push({
            id: d.id,
            data: data,
            doc: d
          })
        }
      })
      discountCode.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          discountsEl.push({
            id: d.id,
            data: data,
            doc: d
          })
        }
      })

      product.discounts = discountsEl;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
    totalPriceWithDiscount(product, discount, withCount = true) {
      let percentage = discount.data.percentage;
      let price = product.data.price;
      let count = withCount ? product.data.count : 1;

      return (price * count) * ((100 - percentage) / 100)
    },
    getOptimalDiscount(product) {
      let ds = product.discounts.filter(d => d.data.quantity <= product.data.count);
      ds = ds ? ds.sort((a, b) => b.data.percentage - a.data.percentage)[0] : null;
      return ds;
    },
    continueWithProducts() {
      this.products.forEach(p => {
        let optimalDiscount = this.getOptimalDiscount(p)
        p.data.optimalDiscount = optimalDiscount ? optimalDiscount.data : null

        let price = p.data.price;
        let count = p.data.count;
        p.data.finalTotalPrice = optimalDiscount ? this.totalPriceWithDiscount(p, optimalDiscount) : (price * count)
        p.data.finalUnitPrice = optimalDiscount ? this.totalPriceWithDiscount(p, optimalDiscount, false) : price
        p.data.id = p.id
      })

      let prodData = this.products.map(p => p.data)
      console.log(prodData)
      this.$emit('selected', prodData)
    }
  },
  computed: {
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
    optimalDiscounts() {
      let discounts = {}
      this.products.forEach(product => discounts[product.id] = this.getOptimalDiscount(product));
      return discounts;
    },
    totalPrice() {
      let c = 0;
      this.products.forEach(p => {
        let d = this.optimalDiscounts[p.id];
        c += d
            ? (p.data.price * p.data.count) * ((100 - d.data.percentage) / 100)
            : p.data.price * p.data.count
      })
      return c;
    },
  }
}
</script>

<style scoped>

</style>