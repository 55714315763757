<template>
  <b-card class="card-outline">
    <template #header>
      <p class="subtitle text-wrap">Deberás imprimir la guía del producto y
        enviarla con el transportista e ingresar el código de rastreo</p>

      <router-link :to="{name: 'OrderShippingGuide', params: {orderId: orderId, supplierId: supplierId }}"
                   class="btn btn-secondary mb-3" target="_blank">Imprimir Dirección</router-link>
    </template>

    <b-form-group label="Compañía de mensajería"
                  description="Compañía de mensajería a la que se manda el producto">
      <b-form-input v-model="deliveryCompanyProp"/>
    </b-form-group>

    <b-form-group label="Guía de Rastreo"
                  description="Código para rastrear el producto">
      <b-form-input v-model="deliveryCodeProp"/>
    </b-form-group>

    <b-form-group label="Número de Guía"
                  description="Número de guía del paquete enviado">
      <b-form-input v-model="deliveryGuideNumberProp"/>
    </b-form-group>

<!--    <b-form-group label="Cargar Foto de la Guía"-->
<!--                  description="Foto de la Guía de envío del producto">-->
<!--      <input-file/>-->
<!--    </b-form-group>-->

    <template #footer>
      <div class="float-right">
        <b-button @click="$emit('next', deliveryCodeProp, deliveryCompanyProp, deliveryGuideNumberProp)"
                  :disabled="!checkProps"
                  variant="secondary">
          Enviar
        </b-button>
      </div>
    </template>
  </b-card>

</template>

<script>
export default {
  name: "shippingStep",
  components: {},
  props: {
    order: Object,
    orderId: String,
    supplierId: String,
    deliveryCode: String,
    deliveryCompany: String,
    deliveryGuideNumber: String
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    deliveryCodeProp: {
      get() { return this.deliveryCode ?? '' },
      set(x) { this.$emit('update:deliveryCode', x) }
    },
    deliveryCompanyProp: {
      get() { return this.deliveryCompany ?? '' },
      set(x) { this.$emit('update:deliveryCompany', x) }
    },
    deliveryGuideNumberProp: {
      get() { return this.deliveryGuideNumber ?? '' },
      set(x) { this.$emit('update:deliveryGuideNumber', x) }
    },
    checkProps() {
      return this.deliveryCodeProp.length > 5
          && this.deliveryCompanyProp.length > 2
          && this.deliveryGuideNumberProp.length > 2
    }
  },
  watch: {

  }
}
</script>

<style scoped>

</style>