<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <b-card class="card-outline">

          <div class="card mb-3">
            <div class="card-body">
              <input-file label="Imagen principal"
                          id="imagen-principal"
                          :accept="accept"
                          v-model="heroImage"/>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-body">
              <input-file label="Imagen tabla de contenido (1920 x 1920)"
                          id="imagen-contenido"
                          :accept="accept"
                         v-model="contentTableImage"/>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-body">
              <input-file label="Imagen banner pequeño (1920x720)"
                          id="imagen-banner"
                          :accept="accept"
                          v-model="smallBannerImage"/>
            </div>
          </div>

          <template #footer>
            <div class="float-right">
              <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
                <i class="material-icons-outlined">arrow_back</i>Regresar
              </b-button>
              <b-button variant="primary"
                        class="btn-round ripple mt-3 px-5"
                        @click="saveImages">Guardar</b-button>
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/InputFile'
import firebase from "firebase";

export default {
  props: {
    productId: String,
  },
  data() {
    const db = firebase.firestore();
    const store = firebase.storage().ref();

    return {
      heroImage: null,
      contentTableImage: null,
      smallBannerImage: null,
      accept: 'image/*',
      db: db,
      imagesRef: store.child('products/images'),
      product: {
        ref: db.collection('products'),
        doc: null,
        data: {}
      }
    }
  },
  components: {
    InputFile
  },
  mounted() {
    this.product.doc = this.product.ref.doc(this.productId);
    this.product.doc.get().then(product => {
      this.product.data = product.data();
      this.product.doc = product;
    });
  },
  methods: {
    saveImages() {
      if (this.heroImage) {
        this.saveFile(this.heroImage, 'heroImage', 'Imagen Principal');
      }
      if (this.contentTableImage) {
        this.saveFile(this.contentTableImage, 'contentTableImage', 'Imagen de Tabla de Contenido');
      }
      if (this.smallBannerImage) {
        this.saveFile(this.smallBannerImage, 'smallBannerImage', 'Imagen de banner pequeño');
      }
    },
    saveFile(file, fileName, fileTitle) {
      let splitName = file.name.split('.');
      let ext = splitName[splitName.length - 1]
      let route = this.imagesRef.child(this.productId).child(fileName+'.'+ext);

      route.put(file).then(async uploadTaskSnapshot => {
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()

        if (fileName === 'heroImage') {
          await this.product.ref.doc(this.productId).set({ photoURL: downloadURL }, { merge: true })
        } else if (fileName === 'contentTableImage') {
          await this.product.ref.doc(this.productId).set({ contentTableURL: downloadURL }, { merge: true })
        } else if (fileName === 'smallBannerImage') {
          await this.product.ref.doc(this.productId).set({ smallBannerURL: downloadURL }, { merge: true })
        }

        await this.product.ref.doc(this.productId).collection('images').doc(fileName).set({
          downloadURL: downloadURL,
          ext: ext,
          originalFullName: file.name
        })

        this.$bvToast.toast(fileTitle+' cargada con éxito', {
          title: 'Guardado',
          autoHideDelay: 6500,
          variant: 'primary',
        });
      }).catch(e => {
        console.error(e.message)

        this.$bvToast.toast('Ocurrió un error al momento de cargar el archivo '+fileTitle, {
          title: 'Carga Fallida',
          autoHideDelay: 6500,
          variant: 'danger',
        });
      });

    }
  }
}
</script>