<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- CARD -->
      <div class="row justify-content-center mb-3">
        <div class="col-md-6">
          <!-- DESGLOSE DE BONOS-->
          <div class="row mb-3">
            <div class="col-md-12">
              <h6 class="font-weight-bold">Desglose de bonos</h6>
              <TableBonosTotal></TableBonosTotal>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-2">
              <h6 class="font-weight-bold">Desglose por usuario</h6>
              <h6 class="subtitle-1 text-muted">
                Escribe el UUID del usuario para traer el último calculo de bonos que se realizó sobre este usuario.
              </h6>
            </div>
            <div class="col-md-12">
              <InputUserBonos></InputUserBonos>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <div class="row mb-3">
            <div class="col-md-12">
              <PayoutActionForm></PayoutActionForm>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-bold">Acumulado de bono emprendedor</h6>
              <EntrepreneurSummary></EntrepreneurSummary>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";
import TableBonosTotal from "@/components/admin/bonos/TableBonosTotal";
import InputUserBonos from "@/components/admin/bonos/InputUserBonos";
import EntrepreneurSummary from "@/components/admin/bonos/EntrepreneurSummary";
import PayoutActionForm from "@/components/admin/bonos/PayoutActionForm";

export default {
  name: 'BonosPayment',
  metaInfo: {
    title: 'Pago de bonos a corte',
  },
  components: {
    PayoutActionForm, EntrepreneurSummary,
    InputUserBonos,
    TableBonosTotal, Navbar, Sidebar
  },

  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Pago de bonos a corte',
        },
      ],
    }
  },
}
</script>