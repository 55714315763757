<template>
  <div>
    <div class="card card-outline">
      <div class="card-header my-2">
        <div class="row">
          <div class="col-md-8"></div>
          <div class="col-md-6 col-lg-4">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter" type="search" id="filterInput" class="form-control-flat"
                            placeholder="Buscar por sujeto, fecha, proridad u otro campo"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <b-table id="table-payments"
                 borderless
                 hover
                 :items="payments"
                 :fields="fields"
                 :filter="filter"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :per-page="perPage"
                 :current-page="currentPage"
                 responsive="xl"
        >
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-payments"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: "",
      perPage: 10,
      currentPage: 1,
      sortBy: 'ticket_id',
      sortDesc: false,
      fields: [
        {key: "ticket_id", sortable: true},
        {key: "sujeto", sortable: true},
        {key: "creado", sortable: true},
        {key: "última_actualización", sortable: true},
        {key: "prioridad", sortable: true},
        {key: "estatus", sortable: true}
      ],
      payments: [
        {
          ticket_id: "0001",
          sujeto: 'Lorem Ipsum',
          creado: '03/12/2020',
          última_actualización: "04/12/2020",
          prioridad: "Media",
          estatus: "Pagado",

        }
      ],
    }
  },
  computed: {
    rows() {
      return this.fields.length
    }
  }
}
</script>