<template>
  <div>
    <Navbar/>
    <Sidebar/>

    <b-container class="mt-3" :fluid="true">
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="breadcrumb"/>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col md="12">
          <table-objectives />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import TableObjectives from "@/components/admin/objective/TableObjectives";

export default {
  name: "ObjectiveView",
  components: {TableObjectives, Sidebar, Navbar},
  data: () => {
    return {
      breadcrumb: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Objetivos',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>