<template>
  <b-row class="justify-content-center">
    <b-col>
      <b-card class="card-outline">
        <b-form-radio-group v-model="editor" :options="opts"/>

        <b-form-group label-for="input-description" label="Descripción">
          <vue-editor v-show="editor === 'editor'"
                      v-model="description"/>

          <b-textarea v-show="editor === 'code'"
                      v-model="description"
                      rows="12"/>
        </b-form-group>

        <template #footer>
          <div class="text-right">
            <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
              <i class="material-icons-outlined">arrow_back</i>Regresar
            </b-button>
            <b-button variant="primary"
                      @click="$emit('save', value)"
                      class="btn-round float-right ripple mt-3 px-5">Guardar</b-button>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "FormProductDescription",
  props: {
    value: String
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      editor: 'editor',
      opts: [
        { text: 'Editor', value: 'editor' },
        { text: 'Código', value: 'code' }
      ]
    }
  },
  methods: {

  },
  computed: {
    description: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    }
  }
}
</script>

<style scoped>
.ql-align-center {
  text-align: center !important;
}
</style>