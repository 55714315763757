<template>
  <b-overlay :show="isBusy">
    <template #overlay>

      <div class="text-center m-3">
        <h5 class="mb-3 text-primary">Estamos configurando tu cuenta Xplits...</h5>
        <b-spinner style="width: 4rem; height: 4rem; margin-bottom: 1rem"
                   label="Estamos configurando tu cuenta Xplits..."
                   variant="primary"/>
      </div>

    </template>
    <section class="section-login">
      <div class="container container-login">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <!-- LOGO -->
            <img src="../../assets/img/logos/logo-white.png" class="img-fluid mb-5" alt="Logo de Xplits"
                 width="200px">
            <h6 class="text-white font-weight-bold mb-4">Tu cuenta Xplits</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- CARD -->
            <!--            <div v-if="error" class="alert alert-danger">{{ error }}</div>-->
          </div>
          <div class="col-md-12">
            <div class="card mb-5">
              <div class="card-header mt-2">
                <h6 class="font-weight-bold">Información de facturación</h6>
              </div>
              <div class="card-body">
                <h6 class="text-roboto font-weight-normal mb-3">
                  Usamos Openpay para asegurarnos que recibas los pagos
                  en tiempo y forma, asi como mantener tu información personal y de banco seguras. Llena la
                  información
                  a continuación y selecciona <span class="font-weight-bold">"Continuar y crear cuenta"</span>
                  para terminar el proceso.
                </h6>

                <div class="row mt-3">
                  <hr>

                  <div class="form-group col-md-12">
                    <label for="name">Nombre de persona física</label>
                    <input v-model="form.name" id="name" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="rfc">R.F.C.</label>
                    <input v-model="form.rfc" id="rfc" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="street_address">Calle y número</label>
                    <input v-model="form.streetAddress" id="street_address" type="text" class="form-control"
                           required/>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="neighborhood">Colonia</label>
                    <input v-model="form.neighborhood" id="neighborhood" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="city">Ciudad</label>
                    <input v-model="form.city" id="city" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="zip_code">Código postal</label>
                    <input v-model="form.zipCode" id="zip_code" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="state">Estado</label>
                    <input v-model="form.state" id="state" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="country">País</label>
                    <select disabled id="country" class="form-control" required>
                      <option value="MX">México</option>
                    </select>
                  </div>

                  <hr>

                  <div class="form-group col-md-6">
                    <label for="bank_account_clabe">CLABE interbancaria</label>
                    <input v-model="form.clabe" id="bank_account_clabe" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="bank_account_alias">Alias de la cuenta</label>
                    <input v-model="form.alias" id="bank_account_alias" type="text" class="form-control" required/>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="bank_account_name">Nombre completo del titular</label>
                    <input v-model="form.nameForBankAccount" id="bank_account_name"
                           type="text" class="form-control" required/>
                  </div>

                  <hr>
                  <button @click="completeOpenpayProfile" class="btn btn-primary btn-block ripple py-3">
                    Continuar y crear cuenta
                  </button>
                  <button @click="signOut" class="btn btn-link">Cerrar sesión</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import {mapGetters} from "vuex";
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

export default {
  name: 'RegisterStripe',
  metaInfo: {
    title: 'Crear cuenta Stripe Express',
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      isBusy: true,
      form: {
        name: '', rfc: '', streetAddress: '', neighborhood: '', state: '', zipCode: '', city: '', clabe: '',
        alias: '', nameForBankAccount: ''
      },
    }
  },
  mounted() {
    if (this.user.data.openpayBillingReady) {
      console.log("redireccionando a home porque stripeexpressready: ", this.user.data.openpayBillingReady)
      return this.$router.replace({name: 'Home'})
    }

    const db = firebase.firestore()
    const unsubExpressListener = db
        .collection('openpay_customers')
        .doc(this.user.data.uid)
        .onSnapshot(docSnap => {
          const data = docSnap.data()

          if (data && data.customer_id) {
            this.isBusy = false
            unsubExpressListener()
          }
        })
  },
  methods: {
    completeOpenpayProfile() {
      this.isBusy = true
      const openpayCompleteProfile = firebase.functions().httpsCallable('openpayCompleteProfile')

      openpayCompleteProfile(this.form)
          .then((res) => console.log("SUCCESS!", res.data.message))
          .then(() => this.$router.replace({name: 'Home'}))
          .finally(() => this.isBusy = false)
          .catch(err => {
            console.error("Error during request: ", err.message)
            this.isBusy = false
          })
    },
    signOut() {
      firebase.auth().signOut()
          .then(() => this.$router.replace({name: "Login"}))
          .catch(console.debug)
    }
  }
}
</script>

<style scoped>
.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  height: 100%;
}

.container-login {
  margin: auto;
}

.b-form-btn-label-control.form-control > label {
  color: #ffffff !important;
}

.b-form-btn-label-control.form-control > .btn {
  background: transparent;
  color: #fff;
  height: 30px !important;
}

.custom-control-label {
  color: #ffffff !important;
}
</style>

