<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>

    <b-container fluid>
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <b-row style="max-width: 1920px; margin-left: auto; margin-right: auto">
        <b-col lg="6" cols="12">
          <package-delivery v-model="order.data"
                            :supplier-id="supplierId"
                            :order.sync="order.data"
                            :products="products"
                            :order-id="order.id"/>
        </b-col>
        <b-col lg="6" cols="12">
          <b-card class="m-3 card-outline" style="margin-top: 88px !important;">
            <template #header>
              <b-row>
                <b-col>
                  <h5>Productos</h5>
                </b-col>
                <b-col class="text-right">
                  <b-badge variant="secondary" v-if="deliveryStatus === 0">Pendiente</b-badge>
                  <b-badge variant="primary" v-else-if="deliveryStatus === 1">Empaquetando</b-badge>
                  <b-badge variant="success" v-else-if="deliveryStatus === 2">Enviado</b-badge>
                </b-col>
              </b-row>
            </template>
            <package-product-list :products="products"/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";
import PackageDeliveryProcess from "@/components/admin/orders/PackageDeliveryProcess";
import PackageProductList from "@/components/admin/orders/PackageProductList";
import firebase from "firebase/app";
import {mapGetters} from "vuex";

export default {
  name: "PackageDeliveryProcess",
  components: {PackageProductList, Navbar, Sidebar, 'package-delivery': PackageDeliveryProcess},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Pedidos',
          to: {name: 'Orders'}
        },
        {
          text: 'Proceso',
        }
      ],
      products: [],
      order: {
        data: {},
        id: null,
        doc: null
      }
    }
  },
  mounted() {
    this.order.id = this.$route.params.orderId
    this.getOrder()
  },
  methods: {
    async getOrder() {
      let db = firebase.firestore()

      let orderSnap = await db.collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')
          .doc(this.order.id)
          .get()

      if (orderSnap.exists) {
        this.order.doc = orderSnap
        this.order.id = orderSnap.id
        this.order.data = orderSnap.data()

        let products = await orderSnap.ref.collection('products').get()
        this.products = []

        products.forEach(p => {
          this.products.push({
            id: p.id,
            data: p.data(),
            doc: p
          })
        })

      } else {
        this.$router.back()
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    supplierId() {
      return this.user.adminData.suppliersUID
    },
    deliveryStatus() {
      return this.order.data.deliveryStatus
    }
  }
}
</script>

<style scoped>

</style>