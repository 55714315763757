<template>
  <div>
    <Navbar/>
    <SearchBar v-model="filter"
               @search-submit="handleSearch"
               current-view/>

    <b-container>
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"/>
        </b-col>
      </b-row>

      <!-- PRODUCTS -->
      <b-row class="mb-4">
        <!-- LEFT COLUMN FILTERS -->
        <div class="col-md-3 mb-3">
          <CategoriesFilters ref="categoriesFilters" @selectedFiltersChanged="handleFiltersChange"></CategoriesFilters>
        </div>

        <!-- RIGHT COLUMN PRODUCT -->
        <div class="col-md-9 mb-3">
          <div class="mb-3">
            <b-row>
              <product-item v-for="product in products"
                            :key="product.id"
                            :value="product"
                            cols="6"
                            md="4"/>
            </b-row>

            <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
              <div slot="no-more">No hay más resultados.</div>
              <div slot="no-results">No hay resultados.</div>
            </infinite-loading>

          </div>

        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import SearchBar from '@/components/Home/SearchBar'
import CategoriesFilters from "@/components/store/categories/CategoriesFilters";
import ProductItem from "@/components/product/ProductItem";
import InfiniteLoading from 'vue-infinite-loading';
import firebase from "firebase";


export default {
  name: "SubcategoryView",
  components: {
    ProductItem,
    CategoriesFilters,
    Navbar,
    SearchBar,
    InfiniteLoading
  },
  metaInfo: {
    title: 'Subcategoría',
  },
  data() {
    return {
      items: [
        {text: 'Inicio', to: {name: 'Home'}},
        {text: 'Tienda', to: {name: 'Store'}},
        {text: 'Loading'},
        {text: 'Loading'},
      ],
      lastDocInfiniteScroll: null,
      productsPerPage: 12,
      category: {},
      subcategory: {},
      products: [],
      selectedFilterItems: [],
      filter: null
    }
  },
  mounted() {
    this.fetchCategory()
  },
  methods: {
    clearFilters() {
      return this.$refs.categoriesFilters.clear()
    },
    handleSearch(text) {
      this.filter = text
      this.selectedFilterItems = []
      this.products = []
      this.clearFilters()
      this.$refs.infiniteLoading.stateChanger.reset() // reset infinite loader, so it will fetch products again
    },
    handleFiltersChange(selectedFilterItems = []) {
      this.selectedFilterItems = selectedFilterItems
      this.filter = null
      this.products = []
      this.$refs.infiniteLoading.stateChanger.reset() // reset infinite loader, so it will fetch products again
    },
    async fetchCategory() {
      const db = firebase.firestore()
      const category = await db.collection('categories').doc(this.$route.params.categoryId).get()
      const subcategory = await category.ref.collection('subcategories').doc(this.$route.params.subcategoryId).get()
      this.category = {id: category.id, ...category.data()}
      this.subcategory = {id: subcategory.id, ...subcategory.data()}
      this.items[2].text = category.data().name // nav item
      this.items[2].to = {name: 'Category', params: {categoryId: category.id}}
      this.items[3].text = subcategory.data().title
    },
    async fetchProducts(overrideProducts = true) {
      const db = firebase.firestore()
      let products = db.collection('products')
          .where('category', '==', this.$route.params.categoryId)
          .where('subcategory', '==', this.$route.params.subcategoryId)


      if (this.filter) {
        // search only 10 first words because firebase only allows 10 word comparisons
        const searchable10WordArray = this.filter.split(' ').slice(0, 10).map(word => word.toLowerCase())
        products = products.where('filterKeywords', 'array-contains-any', searchable10WordArray)
      } else {
        if (this.selectedFilterItems.length > 0) {
          products = products.where('filters', 'array-contains-any', this.selectedFilterItems)
        }
      }

      products = products.orderBy('created_at', 'asc')


      if (this.lastDocInfiniteScroll) {
        products = products.startAfter(this.lastDocInfiniteScroll)
      }

      products = await products.limit(this.productsPerPage).get()

      if (overrideProducts) {
        this.products = []
      }

      let queryResultsCount = 0

      products.forEach(doc => {
        queryResultsCount += 1

        this.products.push({
          id: doc.id,
          ...doc.data()
        })
      })

      return {
        count: queryResultsCount,
        lastDocumentSnapshot: products.docs[products.docs.length - 1]
      }
    },
    async infiniteHandler(state) {
      const productsFetchResult = await this.fetchProducts(false)

      this.lastDocInfiniteScroll = productsFetchResult.lastDocumentSnapshot

      if (productsFetchResult.count > 0) {
        state.loaded()
      } else {
        state.complete()
      }
    }
  }

}
</script>

<style scoped>

</style>