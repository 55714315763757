<template>
  <div class="product">
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- PRODUCT INFO -->
      <b-row class="mb-4">
        <b-col md="4" class="mb-3">
          <product-image-hero v-model="imageHero"/>
        </b-col>
        <b-col md="5" class="mb-3">
          <h6 class="subtitle-1 text-muted" v-text="product.brand_label"/>
          <!--          <h6 class="subtitle-1 text-muted">UID: {{product.id}}</h6>-->
          <h5 class="font-weight-bold mb-3" v-text="product.title"/>
          <div class="body-1 mb-3">
            <p v-text="line" v-for="(line, index) in productDetailLines" :key="index"/>
          </div>
          <hr v-if="productsGroup != null && productsGroup.counts > 1">

          <div v-if="productsGroup != null && productsGroup.counts > 1">
            <b-form-group v-for="filter in productsGroup.filters"
                          :key="filter.name"
                          label-cols="4"
                          label-cols-lg="4"
                          :label="filter.name">
              <b-form-select v-model="productsGroupFilter[filter.name]"
                             @change="onFilterChange"
                             :options="filter.options"/>
            </b-form-group>
          </div>

          <b-form-group label-cols="4" label-cols-lg="4" label="Cantidad" label-for="input-quantity">
            <b-form-spinbutton :disabled="loading" id="input-quantity" v-model="productCount"></b-form-spinbutton>
          </b-form-group>
        </b-col>
        <b-col md="3" class="mb-3 text-md-right">
          <div>
            <div v-if="normalDiscount" class="subtitle-1 text-muted">
              <div class="badge badge-secondary">-{{ normalDiscount.data.percentage }}%</div>
              <del>{{ formatPrice(product.price) }} MXN</del>
            </div>
            <h6 class="text-primary">{{ formatPrice(priceAfterDiscount) }} MXN</h6>
            <b-button :disabled="loading"
                      @click="handleAddToCart"
                      variant="secondary"
                      class="btn-full-width ripple px-3">
              Agregar al carrito
            </b-button>

            <b-input-group class="my-2">
              <b-form-input placeholder="Código" class="form-control-border-bottom"
                            v-model="discountCode.code"
                            @submit="searchForCodeDiscount"/>
              <template #append>
                <b-button variant="secondary"
                          class="m-0 p-1"
                          @click="searchForCodeDiscount">
                  <i class="material-icons-outlined material-icons">arrow_right</i>
                </b-button>
              </template>
            </b-input-group>

            <b-badge v-if="discountCode.discount.doc != null"
                     variant="theme-secondary"
                     style="font-size: 0.7rem">CÓDIGO
              <code v-text="discountCode.discount.data.code" class="font-weight-bold"/> APLICADO. <br>
              PRODUCTO MINIMO REQUERIDO {{ discountCode.discount.data.quantity }}
            </b-badge>
            <hr>

            <div v-if="hasWholesaleDiscount" class="wholesale-table">
              <b-badge variant="info"
                       class="mb-2"
                       style="font-size: 0.7rem">DESCUENTOS DE MAYOREO
              </b-badge>

              <b-table :fields="wholesale.fields"
                       :items="wholesale.data"
                       responsive="md"
                       borderless
                       small>
                <template v-slot:cell(percentage)="d">
                  {{ d.value }}% {{ d.item.id }}
                </template>
                <template v-slot:cell(priceAfter)="d">
                  {{ formatPrice(product.price * ((100 - d.item.percentage) / 100)) }}
                </template>
                <template v-slot:cell(quantity)="d">
                  {{ d.value }} {{ product.stock_unit }}
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-container class="my-5">
        <b-img fluid :src="product.smallBannerURL" class="mx-auto d-block"/>
      </b-container>

      <!-- PRODUCT DETAILS -->
      <b-row class="mb-4">
        <b-col md="12">
          <b-tabs content-class="mt-3" align="center">
            <b-tab title="DESCRIPCIÓN" title-item-class="ripple ripple-dark" active>
              <b-row>
                <div class="col-md-12 mb-3 p-2 ql-editor overflow-x-auto" v-html="productDescription">

                </div>
              </b-row>
            </b-tab>
            <b-tab title="INSTRUCCIONES DE USO" title-item-class="ripple ripple-dark">
              <b-row>
                <div class="col-md-12 mb-3 p-2 ql-editor overflow-x-auto" v-html="productInstructions">

                </div>
              </b-row>
            </b-tab>
            <b-tab title="TABLA DE CONTENIDO" title-item-class="ripple ripple-dark">
              <div class="row">
                <b-card-img :src="contentTableImage" class="mx-auto" style="max-width: 450px"/>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import firebase from "firebase";
import {mapGetters} from "vuex";
import ProductImageHero from "@/components/product/ProductImageHero";

export default {
  name: 'Product',
  components: {
    ProductImageHero,
    Navbar,
    Footer,
    SearchBar,
  },
  metaInfo() {
    return {
      title: this.product.title + ' | Producto',
    }
  },
  data() {
    return {
      db: firebase.firestore(),
      items: [
        {text: 'Inicio', to: {name: 'Home'}},
        {text: 'Tienda', to: {name: 'Store'}},
        {text: 'categoría',},
        {text: 'Subcategoría',},
        {text: 'Loading',},
      ],
      wholesale: {
        fields: [
          {key: 'percentage', label: '%',},
          {key: 'priceAfter', label: 'P.U.',},
          {key: 'quantity', label: 'Min',},
        ],
        data: []
      },
      selected: null,
      productCount: 1,
      product: {},
      images: null,
      loading: false,
      productsGroup: {},
      productsGroupFilter: {},
      discounts: [],
      discountCode: {
        code: null,
        discount: {
          id: null,
          data: {},
          doc: null
        }
      },
      productDescription: '',
      productInstructions: ''
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      const db = firebase.firestore()
      const product = await db.collection('products').doc(this.$route.params.productId).get()

      if (!product.exists) {
        await this.$router.back()
      }

      let data = product.data()
      this.product = {id: product.id, ...data}
      this.items[4].text = data.title
      this.items[2].text = data.category_label
      this.items[2].to = {name: 'Category', params: {categoryId: data.category}}

      if (data.subcategory) {
        this.items[3].text = data.subcategory_label
        this.items[3].to = {name: 'Category', params: {categoryId: data.category}}
      } else {
        this.items.splice(3, 1)
      }

      this.getDescription(this.$route.params.productId)
      this.getInstructions(this.$route.params.productId)

      await this.getDiscounts()
      if (data.productsGroupUID) {
        this.productsGroupFilter = product.data().productsGroupFilters;

        const productsGroup = await this.db
            .collection('products_groups')
            .doc(data.productsGroupUID)
            .get()
        this.productsGroup = {id: productsGroup.id, _ref: productsGroup, ...productsGroup.data()}

      }
    },
    async handleAddToCart() {
      this.loading = true

      if (this.user.loggedIn) {
        this.$store.dispatch('addProductToCart', {
          product: this.product,
          countToAdd: this.productCount,
          discountCode: this.discountCode.code
        })
            .then(() => this.productCount = 1)
            .finally(() => this.loading = false)
      } else {
        await this.$router.push({name: 'Login'})
      }
    },
    onFilterChange() {
      this.productsGroup._ref.ref
          .collection('products')
          .where('filters', '==', this.productsGroupFilter)
          .get()
          .then(prods => {
            if (prods.docs.length > 0) {
              this.$router.push({
                name: 'Product',
                params: {
                  categoryId: prods.docs[0].data().category,
                  productId: prods.docs[0].id
                }
              })
            }
          })
    },
    getDescription(id) {
      let ref = firebase.storage().ref()
      let descriptionRef = ref.child('products').child('descriptions').child(id).child('description.html')

      descriptionRef.getDownloadURL().then(url => {

        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
          this.productDescription = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

      }).catch(() => {
        this.productDescription = ''
      })
    },
    getInstructions(id) {
      let ref = firebase.storage().ref()
      let descriptionRef = ref.child('products').child('descriptions').child(id).child('instructions.html')

      descriptionRef.getDownloadURL().then(url => {

        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
          this.productInstructions = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

      }).catch(() => {
        this.productInstructions = ''
      })
    },
    async getDiscounts() {
      const today = firebase.firestore.Timestamp.now();
      const discounts = await firebase.firestore()
          .collection('products')
          .doc(this.product.id)
          .collection('discounts')
          .where('code', '==', null)
          .get()

      this.discounts = [];
      this.wholesale.data = [];
      discounts.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          this.discounts.push({
            id: d.id,
            data: data,
            doc: d
          })
          if (data.quantity > 1) {
            this.wholesale.data.push(data);
          }
        }
      })
    },
    async searchForCodeDiscount() {
      const today = firebase.firestore.Timestamp.now();
      const discounts = await firebase.firestore()
          .collection('products')
          .doc(this.product.id)
          .collection('discounts')
          .where('code', '==', this.discountCode.code)
          .get()

      discounts.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          this.discountCode.discount = {
            id: d.id,
            data: data,
            doc: d
          }
        }
      })
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
  },
  computed: {
    ...mapGetters(['user']),
    imageHero() {
      return this.product.photoURL ?? ''
    },
    contentTableImage() {
      return this.product.contentTableURL ?? ''
    },
    productDetailLines() {
      if (!this.product.details) return [''];
      return this.product.details.split('\n')
    },
    normalDiscount() {
      let ds = this.discounts.filter(d => d.data.quantity <= this.productCount);
      ds = ds ? ds.sort((a, b) => b.data.percentage - a.data.percentage)[0] : null;
      return this.discountCode.discount.doc
          ? (ds
              ? (ds.data.percentage > this.discountCode.discount.data.percentage
                  ? ds
                  : this.discountCode.discount)
              : this.discountCode.discount)
          : ds;
    },
    hasWholesaleDiscount() {
      return this.discounts.findIndex(d => d.data.quantity > 1) > -1
    },
    priceAfterDiscount() {
      let d = this.normalDiscount;
      return d ? this.product.price * ((100 - d.data.percentage) / 100) : this.product.price;
    },
    ...mapGetters(['isCartOpen', 'user', 'cartProductsCount'])
  },
}
</script>

<style scoped lang="scss">
.wholesale-table {
  /deep/ .table-sm {
    font-size: 0.7rem;
  }
}

/deep/ .badge-theme-secondary {
  background-color: var(--theme-secondary-light);
  color: var(--theme-primary);
}

</style>