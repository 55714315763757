<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <h4 class="font-weight-bold">Dashboard</h4>
        </div>
      </div>

      <div class="row mb-3">

        <!-- RESUMEN DE CARGOS -->
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="card card-no-border h-100">
            <div class="card-body ">
              <h6 class="text-muted mb-3">Total de ingresos</h6>
              <h2 class="font-weight-bold">
                <format-price tag="span" :value="stats.total"/>
                <span class="subtitle-1 text-muted">MXN</span>
                <i class="material-icons-outlined text-success mdc-78">show_chart</i>
              </h2>
            </div>
          </div>
        </div>

        <!-- COMISIONES OPENPAY -->
        <div class="col-md-6 col-lg-8 align-self-center">
          <div class="row">
            <div class="col-md-12 col-lg-4 mb-3">
              <div class="card shadow-sm">
                <div class="card-body d-flex flex-wrap justify-content-around align-items-center">
                  <i class="material-icons-outlined text-secondary mdc-38 icon-wrap mb-3">attach_money</i>
                  <div class="w-75">
                    <h6 class="subtitle-1 text-muted">Total comisión OPENPAY</h6>
                    <format-price tag="h6" :value="stats.total_openpay_commission"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 mb-3">
              <div class="card shadow-sm">
                <div class="card-body d-flex flex-wrap justify-content-around align-items-center">
                  <i class="material-icons-outlined text-secondary mdc-38 icon-wrap mb-3">payment</i>
                  <div class="w-75">
                    <h6 class="subtitle-1 text-muted">Total IVA comisión OPENPAY</h6>
                    <format-price tag="h6" :value="stats.total_openpay_commission_iva" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 mb-3">
              <div class="card shadow-sm">
                <div class="card-body d-flex flex-wrap justify-content-around align-items-center">
                  <i class="material-icons-outlined text-secondary mdc-38 icon-wrap mb-3">shopping_cart</i>
                  <div class="w-75">
                    <h6 class="subtitle-1 text-muted">Total IVA</h6>
                    <format-price tag="h6" :value="stats.total_iva" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- COMISIONES TOTALES -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div class="card shadow-md border-radius-50">
            <div class="card-body">
              <div class="row">
                <div class="col-6 col-md-3 text-center mb-3">
                  <i class="material-icons-outlined mdc-38 icon-wrap icon-wrap-primary mb-3">local_shipping</i>
                  <div>
                    <h6 class="subtitle-1 text-muted">Total comisión de repartos</h6>
                    <h6 class="">
                      <format-price tag="h6" :value="stats.total_distribution" />

                    </h6>
                  </div>
                </div>
                <div class="col-6 col-md-3 text-center mb-3">
                  <i class="material-icons-outlined mdc-38 icon-wrap icon-wrap-primary mb-3">devices</i>
                  <div>
                    <h6 class="subtitle-1 text-muted">Total comisión del sistema</h6>
                    <format-price tag="h6" :value="stats.total_system" />

                  </div>
                </div>
                <div class="col-6 col-md-3 text-center mb-3">
                  <i class="material-icons-outlined mdc-38 icon-wrap icon-wrap-primary mb-3">loyalty</i>
                  <div>
                    <h6 class="subtitle-1 text-muted">Total comisión por envios</h6>
                    <format-price tag="h6" :value="stats.total_shipping" />

                  </div>
                </div>
                <div class="col-6 col-md-3 text-center mb-3">
                  <i class="material-icons-outlined mdc-38 icon-wrap icon-wrap-primary mb-3">airport_shuttle</i>
                  <div>
                    <h6 class="subtitle-1 text-muted">Total pago a proveedores</h6>
                    <format-price tag="h6" :value="stats.total_suppliers" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class="row mb-3">
              <div class="col-md-8 mb-3">
                <div class="card card-outline">
                  <div class="card-header my-2">
                    <h6 class="subtitle-1 font-weight-bold">Últimos pedidos</h6>
                  </div>
                  <div class="card-body">
                    <TableLastOrders></TableLastOrders>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="card card-outline">
                  <div class="card-header my-2">
                    <h6 class="subtitle-1 font-weight-bold">Mis invitados</h6>
                  </div>
                  <div class="card-body">
                    <TableInvites></TableInvites>
                  </div>
                </div>
              </div>
            </div>
            -->

    </div>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import firebase from 'firebase/app'
import 'firebase/functions'
import FormatPrice from "@/components/share/formatPrice";
// import FormatPrice from "@/components/share/formatPrice";

export default {
  metaInfo: {
    title: 'Panel',
  },
  components: {FormatPrice, Sidebar, Navbar, },
  mounted() {
    this.fetchStatistics()
  },
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Panel',
        },
      ],
      value: 33.333333333,
      max: 50,
      stats: {
        total: 0,
        total_openpay_commission: 0,
        total_openpay_commission_iva: 0,
        total_iva: 0,
        total_distribution: 0,
        total_system: 0,
        total_shipping: 0,
        total_suppliers: 0,
      }
    }
  },
  methods: {
    async fetchStatistics() {
      const getStatistics = firebase.functions().httpsCallable('getStatistics')
      const stats = await getStatistics().then(res => res.data)
      this.stats = stats
      console.log(stats)
    }
  }
}
</script>