import { render, staticRenderFns } from "./ProductImageHero.vue?vue&type=template&id=530c2e62&scoped=true&"
import script from "./ProductImageHero.vue?vue&type=script&lang=js&"
export * from "./ProductImageHero.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530c2e62",
  null
  
)

export default component.exports