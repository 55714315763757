<template>
  <b-row class="justify-content-center">
    <b-col md="12">
      <b-card class="card-outline">
        <b-row>
          <b-form-group class="col-md-12"
                        label="Nombre del Grupo">
            <b-form-input type="text"
                          class="form-control-border-bottom"
                          required
                          v-model="value.name"/>
          </b-form-group>

          <b-form-group class="col-md-12"
                        label="Descripción">
            <b-form-textarea type="text"
                             class="form-control-border-bottom"
                             required
                             v-model="value.description"/>
          </b-form-group>

          <b-form-group class="col-md-12"
                        label="Marca"
                        label-for="input-brand">
            <v-select :options="companies"
                      label="text"
                      id="input-brand"
                      class="form-control-border-bottom"
                      :reduce="brand => brand.value"
                      v-model="value.brand"/>

          </b-form-group>

          <b-form-group class="col-md-12"
                        label="Proveedor"
                        label-for="input-brand">
            <v-select :options="suppliers"
                      label="text"
                      id="input-supplier"
                      class="form-control-border-bottom"
                      :reduce="supplier => supplier.value"
                      v-model="value.supplier"/>

          </b-form-group>
        </b-row>


        <template #footer>
          <div class="text-right">
            <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
              <i class="material-icons-outlined">arrow_back</i>Regresar
            </b-button>
            <b-button variant="primary"
                      class="btn-round ripple mt-3 px-5"
                      @click="saveProductsGroup">
              Guardar
            </b-button>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "firebase";
import Vue from "vue";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)
export default {
  name: "FormProductsGroupData",
  props: {
    value: Object
  },
  data() {
    return {
      companies: [],
      suppliers: []
    }
  },
  mounted() {
    this.getCompanies();
    this.getSuppliers();
  },
  methods: {
    async saveProductsGroup() {
      if (this.value.brand) {
        let company = this.companies.filter(c => c.value === this.value.brand);
        if (company.length > 0) this.value.brand_label = company[0].text;
      }
      if (this.value.supplier) {
        let supplier = this.suppliers.filter(s => s.value === this.value.supplier);
        if (supplier.length > 0) this.value.supplier_label = supplier[0].text;
      }
      return this.$emit('save', this.value)
    },
    async getCompanies() {
      const db = firebase.firestore();
      const companies = await db.collection('brands').get();
      this.companies = [];
      companies.forEach(doc => this.companies.push({
        text: doc.data().company_name,
        value: doc.id
      }))
    },
    async getSuppliers() {
      const db = firebase.firestore();
      const suppliers = await db.collection('suppliers').get();
      this.suppliers = []
      suppliers.forEach(doc => this.suppliers.push({
        text: doc.data().name,
        value: doc.id
      }))
    },
  }
}
</script>

<style scoped>

</style>