<template>
  <div>
    <!-- TEXT -->
    <h6>Información de tu cuenta</h6>
    <span class="text-muted subtitle-2">UUID: {{ user.data.uid || "Cargando..." }}</span>
    <hr>
    <!-- FORM -->
    <div class="form-row">
      <div class="col-md-6">
        <b-form-group label="Nombre" label-for="input-name">
          <!--          <b-form-input id="input-name" class="form-control"
                                  placeholder="Ingresa tu nombre" v-bind:value="user.data.displayName" readonly>
                    </b-form-input>-->
          <label for="" class="font-weight-normal text-muted">{{ user.data.displayName }}</label>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Apellidos" label-for="input-lastname">
          <!--          <b-form-input id="input-lastname" class="form-control"
                                  placeholder="Ingresa tu apellido" v-bind:value="user.data.lastName" readonly>
                    </b-form-input>-->
          <label for="" class="font-weight-normal text-muted">{{ user.data.lastName }}</label>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Correo electrónico" label-for="input-email">
          <!--          <b-form-input id="input-email" class="form-control"
                                  placeholder="Ingresa tu correo" v-bind:value="user.data.email" readonly>
                    </b-form-input>-->
          <label for="" class="font-weight-normal text-muted">{{ user.data.email }}</label>
        </b-form-group>
      </div>
      <!--      <div class="col-md-6">
              <b-form-group label="Celular" label-for="input-phone">
                <b-form-input id="input-phone" class="form-control"
                              placeholder="Ingresa tu número celular">
                </b-form-input>
              </b-form-group>
            </div>-->
      <div class="col-md-6">
        <b-form-group label="Fecha de nacimiento" label-for="input-birthday">
          <label for="" class="font-weight-normal text-muted">{{ user.data.birthdayDay }}</label>
        </b-form-group>
      </div>
      <!--      <div class="col-md-6">
              <b-form-group label="País" label-for="input-country">
                <b-form-input id="input-country" class="form-control"
                              placeholder="Ingresa tu país">
                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Género">
                <b-form-radio-group
                    :options="gender"
                    name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
            </div>-->
      <!-- BUTTONS -->
      <div class="col-md-6">
        <!--        <button class="btn btn-round btn-secondary ripple" @click="onUpdate">Actualizar datos</button>-->
        <button class="btn btn-round btn-secondary-outline py-2 px-5 ripple ripple-dark" @click.prevent="signOut">Cerrar
          sesión
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*
import {Datetime} from 'vue-datetime';
*/
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from 'luxon'
import {mapGetters} from "vuex";
import firebase from "firebase";

Settings.defaultLocale = 'es'

export default {
  name: 'AccountDetails',
  data() {
    return {
      gender: [
        {text: 'Hombre', value: 'man'},
        {text: 'Mujer', value: 'woman'},
      ],
    }
  },
  components: {},
  methods: {
    signOut() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "Login"
            });
          });
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
}
</script>

<style scoped>
label.custom-control-label span {
  color: #000000 !important;
}
</style>