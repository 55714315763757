<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"/>
    </transition>
  </div>
</template>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import "./assets/sass/main.scss";
</style>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'App',
  metaInfo: {
    title: 'Xplits',
    titleTemplate: '%s | Xplits',
    meta: [
      //Primary Meta Tags
      {
        name: "title",
        content: "Xplits - Múltiples oportunidades de desarrollo."
      },
      {
        name: "description",
        content: "Somos un sistema que busca un bienestar y fortalecimiento completo del consumidor: físico, emocional, económico y de desarrollo personal."
      },
      {
        name: "keywords",
        content: "Tienda en linea, venta de proteinas, productos nutricionales, venta de productos nutricionales, ultra pure"
      },
      // Twitter Card
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:url', content: 'https://xplits.com'},
      {name: 'twitter:title', content: 'Xplits - Múltiples oportunidades de desarrollo.'},
      {
        name: 'twitter:description',
        content: 'Somos un sistema que busca un bienestar y fortalecimiento completo del consumidor: físico, emocional, económico y de desarrollo personal.'
      },
      {
        name: 'twitter:image',
        content: `${window.location.protocol}//${window.location.host}` + require('./assets/seo.png')
      },
      // Facebook OpenGraph
      {property: 'og:type', content: 'website'},
      {property: 'og:url', content: 'https://xplits.com'},
      {property: "og:title", content: "Xplits - Múltiples oportunidades de desarrollo."},
      {
        property: 'og:description',
        content: 'Somos un sistema que busca un bienestar y fortalecimiento completo del consumidor: físico, emocional, económico y de desarrollo personal.'
      },
      {property: 'og:site_name', content: 'Xplits'},
      {
        property: 'og:image',
        content: `${window.location.protocol}//${window.location.host}` + require('./assets/seo.png')
      },
    ]
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    const currentRouteName = this.$route.name

    if (currentRouteName !== 'AccountBillingRegister'
        && this.user.loggedIn && !this.user?.data?.openpayBillingReady) {

      console.log("Redireccionando. Billing info del user completa: ", this.user?.data?.openpayBillingReady)
      this.$router.replace({name: 'AccountBillingRegister'})
          .catch(err => console.debug(err.message))

    }
  }
}
</script>