<template>
  <div>
    <Navbar />
    <Sidebar/>

    <b-container class="mt-3">
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items" />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col md="12">
          <form-category-filters :header="titleHeader"
                                 :category-id="categoryId"
                                 :subcategory-id="subcategoryId"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import FormCategoryFilters from "@/components/admin/products/FormCategoryFilters";
export default {
  name: "SubcategoryFilterForm",
  components: {FormCategoryFilters, Sidebar, Navbar},
  data() {
    let categoryId = this.$route.params.categoryId
    let subcategoryId = this.$route.params.subcategoryId

    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Productos',
        },
        {
          text: 'Categorías',
          to: {name: 'Categories'}
        },
        {
          text: 'Subcategoría',
          to: {name: 'EditCategory', params: { id: categoryId }}
        },
        {
          text: 'Formulario',
        },
      ],
      categoryId: categoryId,
      subcategoryId: subcategoryId,
      category: {name: ''},
      subcategory: {}
    }
  },
  mounted() {
    this.checkSubcategory()
  },
  methods: {
    async checkSubcategory() {
      let dbCat = firebase.firestore().collection('categories')
      let category = await dbCat.doc(this.categoryId).get()
      if (!category.exists) this.$router.back()

      let subcategory = await category.ref.collection('subcategories').doc(this.subcategoryId).get()
      if (!subcategory.exists) this.$router.back()

      this.category = category.data()
      this.subcategory = subcategory.data()

      this.items[3].text = this.category.name
    },
  },
  computed: {
    titleHeader() {
      return this.category.name+' | '+this.subcategory.title
    }
  }
}
</script>

<style scoped>

</style>