<template>
  <div>
    <b-container class="mt-3">
      <b-row class="justify-content-center">
        <b-col md="8">
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="searchText"
                            class="form-control form-control-flat"
                            placeholder="Busca más de 100 productos"
                            @update="searchSubmit"
                            type="search"
                            lazy/>
              <b-input-group-append>
                <b-button variant="link" class="m-0 py-0 ripple ripple-dark" style="background-color: #f3f3f3">
                  <i class="material-icons">search</i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    currentView: {
      default: false,
      type: Boolean
    },
    value: {
      default: '',
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    searchSubmit(text) {
      if (this.currentView) {
        this.$emit('search-submit', text)
        this.searchText = text
      } else {
        this.$router.push({
          name: 'Store',
          query: {
            search: text
          }
        })
      }
    }
  },
  computed: {
    searchText: {
      get() {
        return this.value;
      },
      set(s) {
        this.$emit('input', s);
      }
    }
  }
}
</script>