import { render, staticRenderFns } from "./PackageProductList.vue?vue&type=template&id=6b4e56b3&scoped=true&"
import script from "./PackageProductList.vue?vue&type=script&lang=js&"
export * from "./PackageProductList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4e56b3",
  null
  
)

export default component.exports