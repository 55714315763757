<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container class="mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>

      <b-card class="card-outline" header="Formulario de Objetivo">
        <b-row class="mb-3">
          <b-col md="12">
            <b-form-group label="Imagen del Objetivo" description="Imagen que se visualizará en la página del objetivo">
              <input-file v-model="imgFile" accept="image/*"/>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <b-form-group label="Título">
              <b-form-input v-model="objective.title"/>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <b-form-group label="Color">
              <b-form-input type="color" v-model="objective.color"/>
            </b-form-group>
          </b-col>
        </b-row>

        <template #footer>
          <div class="text-right">
            <b-button variant="light" class="btn-round ripple mr-3" @click="$router.back()">
              <i class="material-icons-outlined">arrow_back</i>Regresar
            </b-button>
            <b-button variant="primary"
                      @click="saveObjective"
                      class="btn-round ripple px-5">
              Guardar
            </b-button>
          </div>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import InputFile from "@/components/InputFile";
import firebase from "firebase/app";
export default {
  name: "ObjectiveForm",
  metaInfo: {
    title: 'Registro de Proveedores',
  },
  components: {InputFile, Sidebar, Navbar},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Objetivos',
          to: {name: 'ObjectivesView' }
        },
        {
          text: 'Formulario',
        }
      ],
      imgFile: null,
      objective: {
        url: '',
        image_path: '',
        color: '',
        title: '',
        created_at: firebase.firestore.Timestamp.now()
      },
      objectiveId: null,
    }
  },
  mounted() {
    let id = this.$route.params.id
    if (id) this.getObjectiveById(id)
  },
  methods: {
    async getObjectiveById(id) {
      let doc = await firebase.firestore().collection('goal_categories').doc(id).get()
      if (doc.exists) {
        this.objectiveId = id;
        this.objective = doc.data()
        if (!this.objective.created_at) {
          this.objective.created_at = firebase.firestore.Timestamp.now()
        }
      }
    },
    async saveObjective() {
      let db = firebase.firestore().collection('goal_categories')
      let store = firebase.storage().ref().child('goal_categories')

      let doc = this.objectiveId ?  db.doc(this.objectiveId) : db.doc()
      if (this.objectiveId) await doc.update(this.objective)
      else await doc.set(this.objective)

      if (this.imgFile) {
        let splitName = this.imgFile.name.split('.')
        let ext = splitName[splitName.length - 1]
        let route = store.child(doc.id+'.'+ext)

        route.put(this.imgFile).then(async uploadTaskSnapshot => {
          const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()

          await doc.update({
            url: downloadURL,
            image_path: 'goal_categories/' + doc.id + '.' + ext
          })

          this.$bvToast.toast('Objetivo guardado con Imagen', {
            title: 'Guardado',
            autoHideDelay: 6500,
            variant: 'primary',
          });
        })
      } else {
        this.$bvToast.toast('Objetivo guardado', {
          title: 'Guardado',
          autoHideDelay: 6500,
          variant: 'primary',
        });
      }
    }
  }
}
</script>

<style scoped>

</style>