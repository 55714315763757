<template>
  <div>
    <div class="menu-categories-container" :class="[isMenuCategoriesOpen ? 'd-block' : '']">
      <div class="menu-categories-header">
        <h6 class="subtitle-1 font-weight-bold">Categorias</h6>
        <button class="btn btn-link ripple ripple-dark my-0">
          <i class="material-icons-outlined">close</i>
        </button>
      </div>
      <div class="menu-categories-body">
        <div class="row">
          <div class="col-md-3 mb-3">
            <router-link to="/store" class="font-weight-bold text-underline">
              <i class="material-icons-outlined text-secondary">store</i>
              Tienda
            </router-link>
          </div>
          <div class="col-md-3 mb-3" v-for="(category, index) in categories"
               v-bind:key="index + 'categories-nav'">
            <router-link :to="'/store/categories/' + category.id" class="font-weight-bold subtitle-1 text-underline">
              <i class="material-icons-outlined text-secondary mr-1">category</i>
              {{ category.text }}
            </router-link>
            <div class="mt-3">
              <ul v-for="(subcategory, index) in subCategories[category.id]" v-bind:key="index + 'subcategories-nav'"
                  class="list-unstyled mb-2">
                <li>
                  <router-link class="subtitle-2 text-underline"
                               :to="'/store/categories/' + category.id + '/' + subcategory.id">
                    {{ subcategory.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuStoreCategories',
  props: [
    'categories',
    'subCategories',
    'isMenuCategoriesOpen'
  ]
}
</script>