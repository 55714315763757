<template>
  <div>
    <div class="card card-outline">
      <div class="card-body">
        Estos premios se acumulan por cada cliente que completa su meta mensual. El sistema no realiza el pago de manera
        automatica, si no que de manera manual el administrador lo transfiere desde una cuenta de banco.
        <hr>

        <BTable borderless hover :items="items1"></BTable>
        <BTable borderless hover :items="items2"></BTable>

      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/functions'
import 'firebase/firestore'

export default {
  mounted() {
    this.fetchRanking()
  },
  data() {
    return {
      items1: [
        {por_RED: 'Primer lugar', acumulado: '0.00', Openpay_ID: "", Firebase_UUID: ""},
        {por_RED: 'Segundo lugar', acumulado: '0.00',Openpay_ID: "", Firebase_UUID: ""},
      ],
      items2: [
        {por_NUCLEOS: 'Primer lugar', acumulado: '0.00', Openpay_ID: "", Firebase_UUID: ""},
        {por_NUCLEOS: 'Segundo lugar', acumulado: '0.00', Openpay_ID: "", Firebase_UUID: ""},
        {por_NUCLEOS: 'Tercer lugar', acumulado: '0.00', Openpay_ID: "", Firebase_UUID: ""},
      ],
      core_first: {},
      core_second: {},
      core_third: {}
    }
  },
  methods: {
    async fetchRanking() {
      const db = firebase.firestore()
      const getRankings = firebase.functions().httpsCallable('getRankings')
      getRankings()
          .then(res => res.data)
          .then(data => {
            console.debug("[EntrepreneurSummary.vue]", "Getting rankings:", data)
            const prizes = data.prizes
            this.items2[0].acumulado = `$ ${prizes.cores.first} MXN`
            this.items2[1].acumulado = `$ ${prizes.cores.second} MXN`
            this.items2[2].acumulado = `$ ${prizes.cores.third} MXN`

            this.items1[0].acumulado = `$ ${prizes.network.first} MXN`
            this.items1[1].acumulado = `$ ${prizes.network.second} MXN`

            return data
          })
          .then(async data => {
            // TODO WRITE MORE EFFICIENT CODE
            const coresFirst = data.cores.top3[0]
            const coresSecond = data.cores.top3[1]
            const coresThird = data.cores.top3[2]
            const networkFirst = data.network.top3[0]
            const networkSecond = data.network.top3[1]

            if (coresFirst) {
              const snap = await db.collection('openpay_customers').doc(coresFirst.firebase_uuid).get()
              const data = snap.data()
              this.items2[0].Openpay_ID = data.customer_id
              this.items2[0].Firebase_UUID = snap.id
            }

            if (coresSecond) {
              const snap = await db.collection('openpay_customers').doc(coresSecond.firebase_uuid).get()
              const data = snap.data()
              this.items2[2].Openpay_ID = data.customer_id
              this.items2[2].Firebase_UUID = snap.id
            }

            if (coresThird) {
              const snap = await db.collection('openpay_customers').doc(coresThird.firebase_uuid).get()
              const data = snap.data()
              this.items2[3].Openpay_ID = data.customer_id
              this.items2[3].Firebase_UUID = snap.id
            }

            /////////////////// network

            if (networkFirst) {
              const snap = await db.collection('openpay_customers').doc(networkFirst.firebase_uuid).get()
              const data = snap.data()
              this.items1[0].Openpay_ID = data.customer_id
              this.items1[0].Firebase_UUID = snap.id
            }

            if (networkSecond) {
              const snap = await db.collection('openpay_customers').doc(networkSecond.firebase_uuid).get()
              const data = snap.data()
              this.items1[2].Openpay_ID = data.customer_id
              this.items1[2].Firebase_UUID = snap.id
            }

          })
    }
  }
}
</script>