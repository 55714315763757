<template>
  <div class="bg-primary">
    <section class="section-login">
      <b-container class="container-login">
        <b-row class="justify-content-center">
          <b-col md="12" class="text-center">
            <!-- LOGO -->
            <img src="../../assets/img/logos/logo-white.png" class="img-fluid mb-5" alt="Logo de Xplits" width="200px">
            <h6 class="text-white font-weight-bold mb-4">Regístrate</h6>
          </b-col>
        </b-row>

        <div v-if="isNucleoRequestOn">

          <p class="text-center my-4 py-3 font-weight-bolder text-secondary badge badge-secondary w-100"
             style="font-size: 1rem">
            Te ha invitado a unirte a Xplits
            {{ nucleosRequest.data.emitterUser.displayName + ' ' + nucleosRequest.data.emitterUser.lastName }}.
            <br> Regístrate para unirte a su núcleo.</p>
        </div>

        <!-- FORM REGISTER -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <form class="form-row justify-content-center" action="#" @submit.prevent="submit">

          <b-col md="6" class="text-center px-5 border-right">
            <b-form-group>
              <b-form-input type="email" id="email" name="email" required autofocus v-model="form.email"
                            class="form-control form-control-outline"
                            placeholder="Email"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="text" id="username" name="username" required v-model="form.username"
                            class="form-control form-control-outline" autocomplete="off"
                            placeholder="Nombre de usuario"></b-form-input>
            </b-form-group>

            <b-form-row>
              <b-col md="6">
                <b-form-group>
                  <b-form-input type="password" id="password" name="password" required v-model="form.password"
                                class="form-control form-control-outline"
                                placeholder="Contraseña"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <b-form-input type="password" id="confirm-password" name="confirm-password" required
                                class="form-control form-control-outline"
                                placeholder="Confirmar contraseña"></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

          </b-col>

          <b-col md="6" class="text-center px-5">
            <b-form-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input type="text" id="name" name="name" required v-model="form.name"
                                class="form-control form-control-outline"
                                placeholder="Nombre"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <b-form-input type="text" id="lastname" name="last-name" required v-model="form.lastName"
                                class="form-control form-control-outline"
                                placeholder="Apellido"></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="12">
                <b-form-group>
                  <datetime input-class="text-dark form-control form-control-border-bottom"
                            @input="birthdayDatetime = new Date($event)"
                            :value="birthdayDatetime ? birthdayDatetime.toISOString() : null"
                            :phrases="datetimeConfig.phrases"
                            placeholder="Fecha de nacimiento"
                            class="theme-datepicker"
                            :format="datetimeConfig.format"
                            required/>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>

          <b-col md="12" class="text-center mt-3">
            <b-form-row class="no-gutter justify-content-center">
              <b-col cols="12" class="my-1">

                <b-form-checkbox required
                                 class="text-white"
                                 v-model="valueConditions"
                                 :state="stateConditions"
                                 name="checkbox-validation"
                >
                  <span class="text-white">Acepto los</span> <span
                    class="text-secondary">términos y condiciones</span>
                </b-form-checkbox>

              </b-col>
              <b-col cols="12" class="text-center my-1">
                <b-button type="submit" class="btn btn-secondary btn-round ripple ripple-dark m-0 px-5">Crear cuenta
                </b-button>
              </b-col>
            </b-form-row>

            <!-- FOLLOW US -->
            <div class="d-flex justify-content-center mt-5">
              <div class="align-self-center">
                <h6 class="text-white subtitle-2 mb-0">Síguenos en</h6>
              </div>
              <div class="mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                      fill="rgba(255,255,255,1)"/>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                      fill="rgba(255,255,255,1)"/>
                </svg>
              </div>
            </div>

          </b-col>

        </form>

      </b-container>
    </section>
  </div>
</template>

<script>
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from 'luxon'
import firebase from "firebase/app";

Settings.defaultLocale = 'es'

export default {
  name: 'Register',
  metaInfo: {
    title: 'Regístrate',
  },

  components: {
    Datetime
  },

  data() {
    return {
      form: {
        email: '',
        name: '',
        lastName: '',
        birthday: firebase.firestore.Timestamp.now()
      },
      error: null,
      valueConditions: [],
      nucleosRequest: {
        uid: null,
        doc: null,
        data: null,
      },
      datetimeConfig: {
        phrases: {
          ok: 'Continuar',
          cancel: 'Salir'
        },
        format: 'dd/MM/yyyy'
      }
    }
  },

  mounted() {
    let rUID = this.$route.params.requestUID;
    if (rUID) {
      this.nucleosRequest.uid = rUID;
      this.getNucleosRequestUID();
    }
  },

  methods: {
    async getNucleosRequestUID() {
      const db = firebase.firestore().collection('nucleos_requests')
      this.nucleosRequest.doc = await db.doc(this.nucleosRequest.uid).get();
      if (this.nucleosRequest.doc.exists) {
        this.nucleosRequest.data = this.nucleosRequest.doc.data();
        this.form.email = this.nucleosRequest.data.senderEmail;
        this.form.name = this.nucleosRequest.data.senderDisplayName;
        this.form.lastName = this.nucleosRequest.data.senderLastName;
      }
    },
    async submit() {
      const db = firebase.firestore()
      try {
        const data = await firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)

        await data.user.updateProfile({displayName: this.form.name})
        await db.collection('users').doc(data.user.uid)
            .set({
              'displayName': this.form.name,
              'email': this.form.email,
              'lastName': this.form.lastName,
              'birthdayDay': this.birthdayDatetime.toISOString(),
              'birthday': this.form.birthday,
              'username': this.form.username,
              'firstTimeLogIn': true
            }, {merge: true})

        await db.collection('nucleos').doc(data.user.uid).set({
          'displayName': this.form.name,
          'lastName': this.form.lastName,
          'parentUID': this.isNucleoRequestOn ? this.nucleosRequest.data.emitterUserUID : null,
          'parentDisplayName': this.isNucleoRequestOn ? (this.nucleosRequest.data.emitterUser.displayName ?? null) : null,
          'status': 0,
          'level': 1
        });

        if (this.isNucleoRequestOn) {
          await db.collection('nucleos').doc(this.nucleosRequest.data.emitterUserUID)
              .collection('children').doc(data.user.uid).set({
                'displayName': this.form.name,
                'lastName': this.form.lastName,
                'status': 0,
                'level': 1
              });
        }

        await this.$store.dispatch("fetchUser", data.user)

      } catch (e) {
        this.error = e.message
      }
    }
  },

  computed: {
    stateConditions() {
      return this.valueConditions.length === 1
    },
    isNucleoRequestOn() {
      return this.nucleosRequest.doc != null && this.nucleosRequest.doc.exists;
    },
    birthdayDatetime: {
      get() {
        let d = this.form.birthday;
        if (d == null) return null;
        return d.toDate();
      },
      set(val) {
        if (val == null) {
          this.form.birthday = null;
          return;
        }
        this.form.birthday = firebase.firestore.Timestamp.fromDate(val);
      }
    }
  }

}
</script>

<style scoped>
.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  height: 100vh;
}

.container-login {
  margin: auto;
}

.b-form-btn-label-control.form-control > label {
  color: #ffffff !important;
}

.b-form-btn-label-control.form-control > .btn {
  background: transparent;
  color: #fff;
  height: 30px !important;
}

.custom-control-label {
  color: #ffffff !important;
}

.custom-control-label::before {
  background-color: transparent !important;
}

</style>

