<template>
  <div>
    <div class="card card-outline">
      <div class="card-header">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 col-lg-4">
            <h6 class="subtitle-1 font-weight-bold">Fecha</h6>
            <format-date-time :datetime="order.orderAt"/>
          </div>
          <div class="col-md-6 col-lg-2">
            <h6 class="subtitle-1 font-weight-bold">Estado</h6>
            <b-badge variant="secondary" v-if="order.deliveryStatus === 0">Pendiente de envío</b-badge>
            <b-badge variant="primary" v-else-if="order.deliveryStatus === 1">Empaquetando</b-badge>
            <b-badge variant="success" v-else-if="order.deliveryStatus === 2">Enviado</b-badge>
          </div>
          <div class="col-md-6 col-lg-3">
            <h6 class="subtitle-1 font-weight-bold">No. de Orden</h6>
            <p v-text="orderId"></p>
          </div>
          <div class="col-md-6 col-lg-3" v-if="order.deliveryStatus >= 2">
            <button class="btn btn-link btn-round ripple ripple-dark mx-0" @click="openGuide">Ver Guía</button>

            <b-modal v-model="isVisibleGuide"
                     title="Guía del producto"
                     ok-title="Aceptar"
                     ok-variant="link"
                     ok-only>
              <order-guide :product="product" :order="order" :product-id="productId" :order-id="orderId"/>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-row class="align-items-center">
            <b-col md="3" lg="2">
              <product-image-hero :value="getImage(product)"/>
            </b-col>
            <b-col md="5" lg="6">
              <h5 v-text="product.title != null ? product.title : product.name"></h5>
              <p class="subtitle text-wrap" v-text="getStandardDetail(product)"></p>
            </b-col>
            <b-col md="4">
              <p>Cantidad: {{ product.count }}</p>
              <format-price :value="product.finalUnitAmount" class="font-weight-bold"></format-price>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatDateTime from "@/components/share/formatDateTime";
import ProductImageHero from "@/components/product/ProductImageHero";
import FormatPrice from "@/components/share/formatPrice";
import OrderGuide from "@/components/xplits/orders/OrderGuide";
export default {
  name: "OrdersOpen",
  components: {OrderGuide, FormatPrice, ProductImageHero, FormatDateTime},
  props: {
    product: Object,
    productId: String,
    orderId: String,
    order: Object,
  },
  data() {
    return {
      isVisibleGuide: false,
    }
  },
  mounted() {

  },
  methods: {
    openGuide() {
      this.isVisibleGuide = true;
    },

    getImage(product) {
      if (product.photoURL) {
        return product.photoURL
      } else if (product.images) {
        return product.images[0]
      } else return null
    },
    getStandardDetail(p) {
      let t = String(p.details != null ? p.details : p.description)
      return t.length > 240 ? t.slice(0, 240)+'...' : t
    }
  },
  computed: {

  }
}
</script>