<template>
  <b-row>
    <b-col md="3">
      <product-image-hero v-model="value.photoURL"/>
    </b-col>
    <b-col md="5">
      <p class="overline text-primary text-truncate mb-2" v-text="value.brand_label"/>
      <router-link :to="{ name: 'Product', params: { categoryId: value.category, productId: value.id } }"
                   v-text="value.title"
                   v-if="productLinkOnName"/>
      <p class="subtitle-1 font-weight-bold mb-2"
         v-text="value.title"
         v-else/>
    </b-col>
    <b-col md="4">
      <p class="subtitle-2 text-right mb-2">Cantidad: {{ value.count }}</p>
      <format-price v-model="value.finalTotalPrice"
                    class="subtitle-2 font-weight-bolder text-right"/>
    </b-col>
  </b-row>
</template>

<script>
import ProductImageHero from "@/components/product/ProductImageHero";
import FormatPrice from "@/components/share/formatPrice";

export default {
  name: "ProductHorizontalItem",
  components: {FormatPrice, ProductImageHero},
  props: {
    value: Object,
    productLinkOnName: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>