<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <h6>Mis bonos</h6>
      </div>
      <div class="col-md-12 mb-3">
        <b-tabs content-class="mt-3">
          <!-- TAB VOUCHERS -->
          <b-tab title="Mis Bonos" title-link-class="ripple ripple-dark" active>
<!--            <h6 class="my-4">Historial de bonos agrupados</h6>-->
            <!-- FILTERS -->
<!--            <div class="form-row">-->
<!--              <div class="col-md-6 col-lg-4">-->
<!--                <b-form-group label-cols="3" label-cols-md="3" label-cols-lg="2" label="Mes"-->
<!--                              label-for="input-size">-->
<!--                  <b-form-select v-model="selected" :options="month"-->
<!--                                 class="form-control-flat"></b-form-select>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--              <div class="col-md-6 col-lg-4">-->
<!--                <b-form-group label-cols="3" label-cols-md="3" label-cols-lg="2" label="Año"-->
<!--                              label-for="input-size">-->
<!--                  <b-form-select v-model="selected" :options="year"-->
<!--                                 class="form-control-flat"></b-form-select>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--              <div class="col-md-12">-->
<!--                <button class="btn btn-secondary btn-round ripple px-5">Consultar</button>-->
<!--              </div>-->
<!--            </div>-->

            <!-- VOUCHERS CARDS -->
            <div class="row">
              <div class="col-md-12">
                <VoucherCard></VoucherCard>
              </div>
            </div>
          </b-tab>

          <!-- VOUCHER ENTREPRENEURS -->
          <b-tab title="Bono emprendedor" title-link-class="ripple ripple-dark">
            <div class="col-md-12">
              <VoucherEntrepreneursCard></VoucherEntrepreneursCard>
            </div>
          </b-tab>

        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import VoucherCard from "@/components/xplits/vouchers/VoucherCard";
import VoucherEntrepreneursCard from "@/components/xplits/vouchers/VoucherEntrepreneursCard";

export default {
  name: 'MyVouchers',
  components: {VoucherEntrepreneursCard, VoucherCard},
  data() {
    return {
      month: [
        {value: null, text: 'Seleccionar mes'},
        {value: 'a', text: 'Agosto'},
      ],
      year: [
        {value: null, text: 'Seleccionar año'},
        {value: 'a', text: '2020'},
      ],
      selected: null
    }
  }
}
</script>