<template>
  <div class="justify-content-center">
    <b-card class="card-outline" header-class="my-2">
      <template #header>
        <b-row>
          <div class="col-md-6 col-lg-4 mb-3">
            <b-form>
              <div class="d-flex align-items-center">

                <i class="material-icons-outlined mr-2">search</i>
                <b-form-input v-model="searchText"
                              class="form-control-flat"
                              type="search"
                              placeholder="Buscar por título, marca, categoría, detalles u otro campo"
                              lazy/>
              </div>
            </b-form>
          </div>
          <div v-if="withCreateBtn" class="col-md-6 col-lg-8 text-md-right">
            <router-link :to="{ name: 'CatalogProduct' }" class="btn btn-primary">
              <i class="material-icons-outlined">add</i>
              Agregar
            </router-link>
          </div>
        </b-row>
      </template>

      <b-row>
        <b-col md="12">
          <b-table id="table-products"
                   ref="table"
                   class="text-wrap"
                   borderless
                   hover
                   size="sm"
                   :items="getProducts"
                   :fields="tableFields"
                   :filter="searchText"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :per-page="perPage"
                   :current-page.sync="currentPage"
                   :busy="isBusy"
                   @row-selected="$emit('row-selected', $event)"
                   :selectable="selectable"
                   :select-mode="selectMode"
                   selected-variant="primary"
                   responsive="md">
            <template v-slot:cell(title)="d"><p v-text="d.value" class="text-wrap"></p></template>
            <template v-slot:cell(brand_label)="d"><p v-text="d.value" class="text-wrap"></p></template>
            <template v-slot:cell(category_label)="d"><p v-text="d.value" class="text-wrap"></p></template>
            <template v-slot:cell(subcategory_label)="d"><p v-text="d.value" class="text-wrap"></p></template>
            <template v-slot:cell(details)="d">
              <p v-text="d.value.length > 256 ? d.value.substring(0, 256) + '...' : d.value" class="text-wrap"></p>
            </template>
            <template v-slot:cell(goal_label)="d"><p v-text="d.value" class="text-wrap"></p></template>
            <template v-slot:cell(price)="d">
              <format-price :value="d.value" class="text-wrap text-right" />
            </template>
            <template v-slot:cell(stock)="d"><p v-text="d.value" class="text-wrap text-right"></p></template>

            <template v-slot:cell(options)="data">
              <router-link :to="{ name: 'CatalogProductEdit', params: {id: data.item.id} }"
                           variant="link"
                           class="ripple ripple-dark"
                           v-b-tooltip.hover
                           title="Editar">
                <i class="material-icons-outlined">edit</i>
              </router-link>
            </template>

          </b-table>
        </b-col>
      </b-row>
      <b-button @click="currentPage--" variant="light"
                :disabled="currentPage === 1">
        <i class="material-icons-outlined">arrow_back</i> Anterior
      </b-button>
      <b-button @click="currentPage++" variant="light" :disabled="isCurrentPageLastPage">
        Siguiente <i class="material-icons-outlined">arrow_forward</i>
      </b-button>
    </b-card>
  </div>
</template>

<script>
// import ProductsService from '@/services/products-service'
import FormatPrice from "@/components/share/formatPrice";
import firebase from "firebase";

export default {
  name: 'TableProducts',
  components: {FormatPrice},
  props: {
    withCreateBtn: {
      default: false,
      type: Boolean
    },
    withMinimumFields: {
      default: false,
      type: Boolean
    },
    supplierId: {
      default: null,
      type: String
    },
    withoutProductGroup: {
      default: false,
      type: Boolean
    },
    perPage: {
      default: 10,
      type: Number
    },
    selectable: {
      default: false,
      type: Boolean
    },
    selectMode: {
      default: 'single',
      type: String
    },
  },
  mounted() {

  },
  data() {
    return {
      searchText: null,
      lastDocumentFetched: null,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {key: 'id', label: 'UUID', sortable: false},
        {key: 'title', label: 'Título', sortable: false},
        {key: 'details', label: 'Detalles', sortable: false},
        {key: 'price', label: 'Precio', sortable: false},
        {key: 'stock', label: 'Stock', sortable: false},
        {key: 'brand_label', label: 'Marca', sortable: false},
        {key: 'category_label', label: 'Categoría', sortable: false},
        {key: 'subcategory_label', label: 'Subcategoría', sortable: false},
        {key: 'options', label: 'Acciones'}
      ],
      minimumFields: [
        {key: 'id', label: 'UUID', sortable: false},
        {key: 'title', label: 'Título', sortable: false},
        {key: 'price', label: 'Precio', sortable: false},
        {key: 'brand_label', label: 'Marca', sortable: false},
        {key: 'category_label', label: 'Categoría', sortable: false},
      ],
      products: [],
      startAfterDocsList: []
    }
  },
  methods: {
    clearSelection() {
      this.$refs.table.clearSelected()
    },
    getProducts() {
      let query = firebase.firestore().collection('products')
          .limit(this.perPage)
          .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.searchText !== '' && this.searchText !== null) {
        query = query.where('filterKeywords', 'array-contains-any', this.searchText.toLowerCase().split(' '))
      }

      if (this.supplierId) {
        query = query.where('supplier', '==', this.supplierId)

        if (this.withoutProductGroup) {
          query = query.where('productsGroupUID', '==', null)
        }
      }

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.products[this.products.length - 1].doc
        query = query.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      let productsArray = []
      return query.get().then(products => {
        products.forEach(s => {
          productsArray.push({
            id: s.id,
            ...s.data(),
            doc: s
          })
        })

        this.products = productsArray;
        return productsArray;
      }).catch(error => {
        console.log(error)
        this.products = []
        return []
      })
    },
    // async setFilterKey() {
    //   let products = await firebase.firestore().collection('products').get()
    //
    //   for await (let p of products.docs) {
    //     let data = p.data()
    //
    //     let ref = firebase.storage().ref()
    //     let descriptionRef = ref.child('products').child('descriptions').child(p.id).child('description.html')
    //     descriptionRef.putString(data.description ?? '')
    //
    //     let brandRef = await firebase.firestore().collection('brands').doc(data.brand).get()
    //     let brandData = brandRef.data()
    //     await brandRef.ref.update({
    //       products_count: brandData.products_count + 1
    //     })
    //   }
    // }

  },
  computed: {
    isCurrentPageLastPage() {
      return this.products.length < this.perPage
    },
    tableFields() {
      return this.withMinimumFields ? this.minimumFields : this.fields
    },
    rows() {
      return this.products.length
    },
  },
}
</script>

<style>

#table-products p {
  font-size: 12px;
}

</style>