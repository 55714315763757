<template>
  <b-card header-tag="h5" header="Producto Enviado" header-class="text-center font-weight-bold mt-2" class="card-outline">
    <div class="text-center my-3">
      <img src="@/assets/img/empty-states/order_confirmed.svg" class="img-fluid" width="250px" alt="Producto enviado">
    </div>

    <div class="text-center">
      <h6 class="text-center mt-5">El producto fue enviado con éxito</h6>
      <router-link to="/admin/orders" class="btn btn-primary btn-round ripple px-3">Regresar a las ordenes</router-link>
    </div>

  </b-card>
</template>

<script>
export default {
  name: "SentStep"
}
</script>

<style scoped>

</style>