<template>
  <b-container :fluid="true">
    <b-card class="card-outline">
      <template #header>
        <b-row>
          <b-col md="6" lg="4" class="mb-3">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter"
                            type="search"
                            id="filterInput"
                            class="form-control-flat"
                            placeholder="Buscar por nombre del impuesto"/>
            </div>
          </b-col>

          <b-col md="6" lg="8" class="text-md-right">
            <b-button variant="primary"
                      @click="openFormModal(null)"
                      class="ripple">
              <i class="material-icons-outlined">add</i>
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </template>

      <b-table :fields="fields"
               :filter="filter"
               :per-page="perPage"
               :filter-ignored-fields="filterIgnoredFields"
               :current-page.sync="currentPage"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :items="taxes">
        <template #cell(percentage)="d">
          {{ d.value }} %
        </template>

        <template #cell(options)="d">
          <b-button variant="link" class="ripple ripple-dark"
                    @click="openFormModal(d.item.doc)"
                    title="Editar">
            <i class="material-icons-outlined">edit</i>
          </b-button>
        </template>
      </b-table>


      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
      ></b-pagination>
    </b-card>

    <b-modal v-model="formModal.visible"
             ok-title="Guardar"
             cancel-title="Cancelar"
             cancel-variant="link"
             :ok-disabled="!(typeValid && descriptionValid && percentageValid)"
             @ok="saveTax"
             title="Marca">
      <b-form-group label="Impuesto"
                    :validated="typeValid">
        <b-form-input v-model="formModal.tax.type"/>
      </b-form-group>
      <b-form-group label="Descripción"
                    :validated="descriptionValid">
        <b-form-input v-model="formModal.tax.description"/>
      </b-form-group>
      <b-form-group label="Porcentaje"
                    :validated="percentageValid">
        <b-input-group append="%">
          <b-form-input v-model="formModal.tax.percentage"
                        :formatter="formatPercentage"
                        type="number"/>
        </b-input-group>
      </b-form-group>
    </b-modal>

  </b-container>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "TableTaxes",
  components: {},
  data() {
    return {
      filter: '',
      perPage: 10,
      currentPage: 1,
      sortBy: 'type',
      sortDesc: false,
      filterIgnoredFields: ['id', 'doc'],
      fields: [
        {key: 'id', label: 'ID', sortable: true },
        {key: 'type', label: 'Tipo', sortable: true },
        {key: 'description', label: 'Descripción', sortable: true },
        {key: 'percentage', label: 'Porcentaje', sortable: true },
        {key: 'options', label: 'Acciones', sortable: false },
      ],
      taxes: [],

      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      },
      formModal: {
        visible: false,
        tax: {
          type: '',
          description: '',
          percentage: 0
        },
        taxId: null
      }
    }
  },
  mounted() {
    this.getTaxes();
  },
  methods: {
    async getTaxes() {
      const db = firebase.firestore()
      const taxes = await db.collection('taxes').get()
      this.taxes = []

      taxes.forEach(t => {
        let d = t.data()
        d.id = t.id
        d.doc = t
        this.taxes.push(d)
      })
    },
    saveTax() {
      const db = firebase.firestore().collection('taxes');
      if (this.formModal.taxId) {
        db.doc(this.formModal.taxId).update(this.formModal.tax)
      } else {
        db.doc().set(this.formModal.tax)
      }

      this.$bvToast.toast('Impuesto guardado', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
      });

      this.getTaxes();
    },
    openFormModal(doc = null) {
      if (doc) {
        this.formModal.taxId = doc.id;
        this.formModal.tax = doc.data();
        this.formModal.visible = true;
      } else {
        this.formModal.taxId = null;
        this.formModal.tax = {
          type: '',
          description: '',
          percentage: 0
        };
        this.formModal.visible = true;
      }
    },
    formatPercentage(n) {
      n = Number.parseInt(n)
      return n < 0 ? 0 : n > 100 ? 100 : n;
    }
  },
  computed: {
    typeValid() {
      let x = this.formModal.tax.type
      if (x === '' || x == null) return false;
      return x.length > 2 && x.length < 32;
    },
    descriptionValid() {
      let x = this.formModal.tax.description
      if (x == null) return false;
      return x.length < 1024
    },
    percentageValid() {
      let x = this.formModal.tax.percentage
      if (x === '' || x == null) return false;
      return x >= 0 && x <= 100
    },
    totalRows() {
      return this.taxes.length
    }
  }
}
</script>

<style scoped>

</style>