<template>
<b-row>

  <b-col md="12">
    <b-form-group label="Nombre" label-for="discount-form-name">
      <b-form-input id="discount-form-name" v-model="value.name"/>
    </b-form-group>
  </b-col>


  <b-col md="12">
    <b-form-group label="Descuento" label-for="discount-form-percentage">
      <b-input-group>
        <template #append>
          <b-input-group-text>%</b-input-group-text>
        </template>
        <b-form-input id="discount-form-percentage"
                      type="number"
                      :formatter="formatDiscount"
                      v-model="stringPercentage"/>
      </b-input-group>
    </b-form-group>
  </b-col>


  <b-col md="12">
    <b-form-group label="Cantidad Mínima" label-for="discount-form-quantity">
      <b-form-input id="discount-form-quantity"
                    :formatter="formatQuantity"
                    type="number"
                    v-model="stringQuantity"/>
    </b-form-group>
  </b-col>


  <b-col md="1">
    <b-form-group class="text-right mt-4">
      <b-form-checkbox id="discount-form-code-check"
                       :checked="!isCodeDisabled"
                       @change="onCodeCheckboxChange"/>
    </b-form-group>
  </b-col>

  <b-col md="11">
    <b-form-group label="Código" label-for="discount-form-code">
      <b-form-input id="discount-form-code"
                    v-model="value.code"
                    :formatter="formatCode"
                    :disabled="isCodeDisabled"/>
    </b-form-group>
  </b-col>


  <b-col md="1">
    <b-form-group class="text-right mt-4">
      <b-form-checkbox :checked="!isPeriodDisabled"
                       id="discount-form-period-check"
                       @change="onPeriodCheckboxChange"/>
    </b-form-group>
  </b-col>

  <b-col md="11">
    <b-form-group label="Periodo de Validez">
      <b-row>
        <b-col>
          <b-form-group label="Inicio">
            <b-input :value="null" :disabled="true" v-if="periodStart == null"/>
            <date-time :value="periodStart.toISOString()"
                       @input="periodStart = new Date($event)"
                       :phrases="datetime.phrases"
                       :format="datetime.format"
                       use12-hour
                       type="datetime"
                       v-else/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fin">
            <b-input :value="null" :disabled="true" v-if="periodEnd == null"/>
            <date-time :value="periodEnd.toISOString()"
                       @input="periodEnd = new Date($event)"
                       :phrases="datetime.phrases"
                       :format="datetime.format"
                       use12-hour
                       type="datetime"
                       v-else/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>

  <b-col md="12" v-if="withSaveButton">
    <b-button variant="primary"
              class="btn-round float-right ripple mt-3 px-5"
              @click="saveDiscount">Guardar</b-button>
  </b-col>
</b-row>
</template>

<script>

import firebase from "firebase/app";
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';


export default {
  name: "DiscountForm",
  components: {
    'date-time': Datetime
  },
  props: {
    withSaveButton: {
      default: false,
      type: Boolean
    },
    productId: String,
    discountId: String,
    value: Object,
  },
  data: () => {
    return {
      db: firebase.firestore(),
      datetime: {
        phrases: {
          ok: 'Continuar',
          cancel: 'Salir'
        },
        format: 'dd/MM/yyyy hh:mm a'
      }
    }
  },
  mounted() {
    this.$set(this.value, 'code', this.value.code === undefined
        ? null : this.value.code);

    this.$set(this.value, 'quantity', this.value.quantity === undefined
        ? 0 : this.value.quantity);

    this.$set(this.value, 'percentage', this.value.percentage === undefined
        ? 0 : this.value.percentage);

    this.$set(this.value, 'name', this.value.name === undefined
        ? '' : this.value.name);

    this.$set(this.value, 'period_start', this.value.period_start === undefined
        ? null : this.value.period_start);

    this.$set(this.value, 'period_end', this.value.period_end === undefined
        ? null : this.value.period_end);

  },
  methods: {
    onCodeCheckboxChange(check) {
      this.value.code = check ? '' : null;
    },
    onPeriodCheckboxChange(check) {
      if (check) {
        this.periodStart = new Date();
        this.periodEnd = new Date();
        this.periodStart.setSeconds(0);
        this.periodEnd.setSeconds(0);
        this.periodEnd.setDate(this.periodEnd.getDate() + 1);
      } else {
        this.value.period_start = null;
        this.value.period_end = null;
      }
    },
    formatCode(value) {
      return value.toUpperCase()
          .replaceAll(' ', '')
          .replaceAll('-', '_');
    },
    formatDiscount(value) {
      return value < 0 ? 0 : value > 100 ? 100 : value;
    },
    formatQuantity(value) {
      return value < 0 ? 0 : value;
    },
    saveDiscount() {
      let refCollect = this.db.collection('products')
          .doc(this.productId)
          .collection('discounts');

      let ref = this.discountId
          ? refCollect.doc(this.discountId)
          : refCollect.doc();

      ref.set(this.value);

      this.$emit('update:product-id', ref.id);
      this.$emit('saved', this.value, ref.id);
    }
  },
  computed: {
    isCodeDisabled: function () {
      return this.value.code === null || this.value.code === undefined;
    },
    isPeriodDisabled: function () {
      return (this.value.period_end === null || this.value.period_end === undefined) &&
          (this.value.period_start === null || this.value.period_start === undefined)
    },
    periodStart: {
      get() {
        let d = this.value.period_start;
        if (d == null) return null;
        return d.toDate();
      },
      set(val) {
        if (val == null) {
          this.value.period_start = null;
          return;
        }
        this.value.period_start = firebase.firestore.Timestamp.fromDate(val);
      }
    },
    periodEnd: {
      get() {
        let d = this.value.period_end;
        if (d == null) return null;
        return d.toDate();
      },
      set(val) {
        if (val == null) {
          this.value.period_end = null;
          return;
        }
        this.value.period_end = firebase.firestore.Timestamp.fromDate(val);
      }
    },
    stringPercentage: {
      get() {
        return this.value.percentage;
      },
      set(val) {
        this.value.percentage = parseFloat(val);
      }
    },
    stringQuantity: {
      get() {
        return this.value.quantity;
      },
      set(val) {
        this.value.quantity = parseFloat(val);
      }
    }
  }
}
</script>

<style scoped>
fieldset.form-group {
  color: var(--theme-on-primary);
  font-size: 14px;
  font-weight: 500;
}
</style>