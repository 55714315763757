<template>
  <div class="profile">
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- SIDE MENU -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card card-no-border">
            <BTabs pills card vertical>
              <BTab title="Ajustes de cuenta" active>
                <AccountDetails></AccountDetails>
              </BTab>

              <!--              <BTab @click="manageBillingInStripe" title="Metodos de pago & facturación"></BTab>-->

              <!--              <BTab title="Notificaciones">
                              <Notifications></Notifications>
                            </BTab>-->

              <BTab title="Centro de ayuda">
                <!-- HELP -->
                <div class="text-center mb-3">
                  <img src="@/assets/img/empty-states/support.svg" width="200px" class="img-fluid" alt="Soporte">
                </div>
                <div class="text-center align-content-center">
                  <a class="btn btn-primary  btn-round ripple px-5" href="mailto:soporte@xplits.com">Solicitar ayuda por
                    correo</a>
                </div>
              </BTab>
            </BTabs>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import AccountDetails from '@/components/profile/AccountDetails'
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  name: 'Profile',
  metaInfo: {
    title: 'Mi perfil',
  },
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Home'}
        },
        {
          text: 'Mi perfil',
        },
      ]
    }
  },
  components: {
    AccountDetails,
    Navbar,
    Footer,
    SearchBar,
  },
  methods: {
    manageBillingInStripe() {
      // Docs: https://stripe.com/docs/billing/subscriptions/integrating-customer-portal#redirect
      //TODO Implement loading

      // this.$store.dispatch('updateLoading', { loading: true, text: "Opening billing manager..." })
      const createCustomerPortalSession = firebase.functions().httpsCallable('stripeCreateCustomerPortalSession')
      createCustomerPortalSession()
          .then(result => window.location.href = result.data.url)
          .catch(console.error)
      // .finally(() => this.$store.dispatch('updateLoading', { loading: false }))
    }
  }
}
</script>
