<template>
  <div>
    <CardCountdown v-if="showCountdown" :nextCutString="nextCutString"></CardCountdown>

    <div v-else class="card card-outline">
      <div class="card-body text-center">
        <img src="@/assets/img/empty-states/credit_card.svg" class="img-fluid mb-5" width="200px"
             alt="Imagen de pagos">
        <h6>
          El saldo de la cuenta a dispersión de Openpay debe ser igual a el total a pagar antes de activar el
          sistema de realización de transferencias automáticas a los colaboradores.
        </h6>
        <h2 class="font-weight-bold">
          <format-price tag="span" :value="total"/>
          <span class="subtitle-1 text-muted">MXN</span>
        </h2>
        <button class="btn btn-primary btn-round ripple my-3 px-5">Enviar pagos</button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import CardCountdown from "@/components/admin/bonos/CardCountdown";
import formatPrice from "@/components/share/formatPrice";

export default {
  components: {CardCountdown, formatPrice},
  data() {
    return {
      showCountdown: false,
      total: 0,
      nextCutString: ''
    }
  },
  mounted() {
    this.fetchPayoutStatus()
  },
  methods: {
    async fetchPayoutStatus() {
      const db = firebase.firestore()
      const nowStatus = await db.collection('payouts_status').doc('now').get()
          .then(doc => doc.data())

      if (nowStatus && nowStatus.status) {
        switch (nowStatus.status) {
          case 'awaiting_cut':
            this.showCountdown = true
            this.nextCutString = nowStatus.nextCutDate

            break
          case 'pending_payouts':
            this.showCountdown = false
            break
        }
      }
    }
  }
}
</script>