<template>
  <div>
    <b-carousel v-model="slide"
                :interval="4000"
                indicators
                controls>
      <b-carousel-slide v-for="img in images" :key="img.id" :img-src="img.url"/>
    </b-carousel>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  components: {},
  data() {
    return {
      images: [],
      slide: 0
    }
  },
  mounted() {
    this.getImages()
  },
  methods: {
    async getImages() {
      let img = await firebase
          .firestore()
          .collection('image_banners')
          .doc('carouselImage')
          .collection('items')
          .get()

      this.images = []
      let count = 0
      img.forEach(i => {
        this.images.push({
          id: i.id,
          position: count,
          ...i.data()
        })

        count++
      })
    }
  }
}
</script>

<style scoped>

</style>