<template>
  <div class="store">
    <Navbar></Navbar>
    <SearchBar v-model="filter"
               @search-submit="handleSearch"
               current-view/>

    <b-container>
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="showSearchProducts" class="mb-3">
      <b-row>
        <product-item v-for="product in search.products"
                      :key="product.id"
                      :value="product"
                      cols="6"
                      md="4"
                      lg="3"/>
      </b-row>

      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
        <div slot="no-more">No hay más resultados.</div>
        <div slot="no-results">No hay resultados.</div>
      </infinite-loading>
    </b-container>

    <b-container v-show="!showSearchProducts">
      <!-- BANNER -->
      <b-row class="mb-4" v-if="banner != null">
        <b-col md="12" class="mb-3">
          <img :src="banner.data().url" class="img-fluid border-radius-25 w-100" alt="Banner publicitario">
        </b-col>
      </b-row>

      <!-- CATEGORIES CARDS -->
      <b-row class="mb-4">

        <b-col v-for="goal in goals"
               :key="goal.id"
               md="4"
               class="mb-3">
          <router-link class="category"
                       :to="{name: 'Goal', params: {goalId: goal.id}}"
                       :style="goalStyle(goal)">
            <div class="category-header text-center">
              <h6 class="text-white" v-text="goal.data.title"></h6>
            </div>
          </router-link>
        </b-col>
      </b-row>

      <!-- TOP PRODUCTS -->
      <div class="row mb-4">
        <div class="col-md-3 col-lg-2 mb-3">
          <div class="card bg-secondary-gradient bg-top-products h-100">
            <div class="card-body text-center d-flex flex-wrap align-items-center">
              <h5 class="text-white"><span class="subtitle-1">Top</span> <br> Más Vendidos
                <hr class="bg-white w-50">
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-lg-10">
          <TopProductsCarousel></TopProductsCarousel>
        </div>
      </div>
      <!-- NEW PRODUCTS -->
      <div class="row mb-4">
        <div class="col-md-3 col-lg-2 mb-3">
          <div class="card bg-primary-gradient bg-new-products h-100">
            <div class="card-body text-center d-flex flex-wrap align-items-md-center">
              <h5 class="text-white">Nuevos Productos
                <hr class="bg-white w-50">
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-lg-10">
          <NewProductsCarousel></NewProductsCarousel>
        </div>
      </div>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import TopProductsCarousel from "@/components/store/TopProductsCarousel";
import NewProductsCarousel from "@/components/store/NewProductsCarousel";
import firebase from "firebase";
import ProductItem from "@/components/product/ProductItem";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: 'Store',
  components: {
    ProductItem,
    NewProductsCarousel,
    TopProductsCarousel,
    Navbar,
    Footer,
    SearchBar,
    InfiniteLoading
  },
  metaInfo: {
    title: 'Tienda',
  },
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Home'}
        },
        {
          text: 'Tienda',
        },
      ],
      goals: [],
      banner: null,
      filter: '',
      search: {
        products: [],
        lastDocInfiniteScroll: null,
        productsPerPage: 12
      }
    }
  },
  beforeMount() {
    this.getGoals()
    this.getMainStoreImage()
  },
  mounted() {
    let f = this.$route.query.search;

    if (f) {
      this.filter = f
      this.handleSearch(f)
    }
  },
  methods: {
    async getGoals() {
      let goals = await firebase.firestore().collection('goal_categories').get()
      this.goals = []
      for (const g of goals.docs) {
        this.goals.push({
          id: g.id,
          data: g.data(),
          doc: g,
          url: g.data().url
        });
      }
    },
    goalStyle(goal) {
      return {
        backgroundSize: 'cover !important',
        background: 'url(' + goal.url + ') no-repeat center' + goal.data.color,
        cursor: 'pointer',
      }
    },
    async getMainStoreImage() {
      let img = await firebase.firestore()
          .collection('image_banners')
          .doc('mainStoreImage')
          .get()

      if (img.exists) {
        this.banner = img;
      }
    },
    handleSearch(text) {
      console.log('search', text)
      this.filter = text
      this.search.selectedFilterItems = []
      this.search.products = []
      this.$refs.infiniteLoading.stateChanger.reset() // reset infinite loader, so it will fetch products again
    },
    async fetchProducts(overrideProducts = true) {
      console.log(overrideProducts)
      const db = firebase.firestore()
      let products = db.collection('products')

      if (this.filter) {
        // search only 10 first words because firebase only allows 10 word comparisons
        let searchable10WordArray = this.filter.toString()
            .toLowerCase()
            .split(' ')
            .slice(0, 10)
            .map(word => word.toLowerCase())
            .filter(word => word.length > 2)

        searchable10WordArray.push(this.filter.toString().toLowerCase())
        products = products.where('filterKeywords', 'array-contains-any', searchable10WordArray)
      }

      products = products.orderBy('created_at', 'asc')

      if (this.search.lastDocInfiniteScroll) {
        products = products.startAfter(this.search.lastDocInfiniteScroll)
      }

      products = await products.limit(this.search.productsPerPage).get()

      if (overrideProducts) {
        this.products = []
      }

      let queryResultsCount = 0

      products.forEach(doc => {
        queryResultsCount += 1

        this.search.products.push({
          id: doc.id,
          ...doc.data()
        })
      })

      return {
        count: queryResultsCount,
        lastDocumentSnapshot: products.docs[products.docs.length - 1]
      }
    },
    async infiniteHandler(state) {
      const productsFetchResult = await this.fetchProducts(false)

      this.search.lastDocInfiniteScroll = productsFetchResult.lastDocumentSnapshot

      if (productsFetchResult.count > 0) {
        console.debug(`Infinite scroll loaded ${productsFetchResult.count} products.`)
        state.loaded()
      } else {
        console.debug("Infinite scroll was completed.")
        state.complete()
      }
    }
  },
  computed: {
    showSearchProducts() {
      return this.filter != null && this.filter.length > 1
    }
  }
}
</script>