<template>
  <div>
    Redireccionando...
  </div>
</template>
<script>
import firebase from "firebase/app"
import 'firebase/functions'

export default {
  beforeCreate() {
    console.log("Stripe express reauth")
    const createStripeExpressAccountLink = firebase.functions().httpsCallable('createStripeExpressAccountLink')

    createStripeExpressAccountLink()
        .then(res => window.location.replace(res.data.url))
        .catch(console.error)
  }
}
</script>