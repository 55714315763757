<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">

          <b-tabs content-class="mt-3" justified>
            <b-tab title="General" active>
              <div class="card card-outline">
                <div class="card-body">
                  <div class="">
                    <div class="form-group">
                      <InputFile id="input-profile-picture" v-model="profilePicture" label="Foto de perfil" :accept="accept"></InputFile>
                    </div>
                    <b-form-group class=""
                                  label="Nombre de usuario"
                                  label-for="input-username"
                    >
                      <b-form-input id="input-username" type="text" class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <b-form-group class=""
                                  label="Nombre"
                                  label-for="input-name"
                    >
                      <b-form-input id="input-name" type="text" class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <b-form-group class=""
                                  label="Email"
                                  label-for="input-email"
                    >
                      <b-form-input id="input-email" type="email" class="form-control-border-bottom"></b-form-input>
                    </b-form-group>

                    <!--                    <div class="alert alert-warning d-flex justify-content-between align-items-center">
                                          Tu email no ha sido confirmado. Por favor revisa tu inbox.
                                          <b-button variant="link" class="ripple ripple-dark mx-0">Reenviar confirmación</b-button>
                                        </div>-->

                    <b-form-group class=""
                                  label="Compañia"
                                  label-for="input-company"
                    >
                      <b-form-input id="input-company" type="text" class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <div class="">
                      <b-button variant="primary" class="btn-round ripple px-5 float-right">Guardar</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title="Cambiar contraseña">
              <div class="card card-outline">
                <div class="card-body">
                  <div class="">
                    <b-form-group class=""
                                  label="Contraseña actual"
                                  label-for="input-current-password"
                    >
                      <b-form-input id="input-current-password" type="password"
                                    class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <b-form-group class=""
                                  label="Nueva contraseña"
                                  label-for="input-new-password"
                    >
                      <b-form-input id="input-new-password" type="password"
                                    class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <b-form-group class=""
                                  label="Repetir nueva contraseña"
                                  label-for="input-repeat-new-password"
                    >
                      <b-form-input id="input-repeat-new-password" type="password"
                                    class="form-control-border-bottom"></b-form-input>
                    </b-form-group>
                    <div class="">
                      <b-button variant="primary" class="btn-round ripple px-5 float-right">Guardar</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import InputFile from "../../components/InputFile";

export default {
  metaInfo: {
    title: 'Configuración',
  },
  components: {InputFile, Sidebar, Navbar},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Cuenta',
        },
        {
          text: 'Configuración',
        },
      ],
      profilePicture: null,
      accept: 'image/*',
    }
  },
}
</script>