<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container class="mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>

      <!-- TABLE -->
      <b-row class="mb-3">
        <b-col md="12">

          <b-tabs content-class="mt-3" justified>
            <b-tab title="Información General"
                   title-item-class="text-uppercase ripple ripple-dark"
                   active>
              <form-suppliers-info
                  v-model="supplier.data"
                  @save="saveSupplier"/>
            </b-tab>
            <b-tab title="Direcciones"
                   title-item-class="text-uppercase ripple ripple-dark"
                   :disabled="supplier.id == null">
              <form-suppliers-address v-if="supplier.id != null"
                                      :supplier-id="supplier.id"/>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import FormSuppliersInfo from "@/components/admin/Suppliers/FormSuppliersInfo";
import firebase from "firebase/app";
import FormSuppliersAddress from "@/components/admin/Suppliers/FormSuppliersAddress";

export default {
  metaInfo: {
    title: 'Registro de Proveedores',
  },
  components: {FormSuppliersAddress, FormSuppliersInfo, Sidebar, Navbar},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Proveedores',
          to: {name: 'Suppliers'}
        },
        {
          text: 'Registro',
        }
      ],
      price: '100',
      options: [
        {value: null, text: 'Selecciona una opción'},
        {value: 'a', text: 'Cualquiera'},
      ],
      suppliersDocs: null,
      accept: 'image/*',
      supplier: {
        doc: null,
        data: {
          name: '',
          rfc: '',
          contactName: '',
          status: 1,
          created_at: firebase.firestore.Timestamp.now(),
          contactMails: [],
          contactPhones: []
        },
        id: null
      }
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.getSupplier(id)
    }
  },
  methods: {
    saveSupplier(supplier) {
      if (this.supplier.id) {
        let doc = firebase.firestore().collection('suppliers').doc(this.supplier.id);
        doc.update(supplier)
      } else {
        let doc = firebase.firestore().collection('suppliers').doc();
        doc.set(supplier).then(() => {
          this.supplier.id = doc.id;
        })
        this.supplier.doc = doc;
      }

      this.$bvToast.toast('Proveedor guardado en la base.', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
        href: '/admin/suppliers'
      });
    },
    async getSupplier(id) {
      let doc = await firebase.firestore().collection('suppliers').doc(id).get();
      if (doc.exists) {
        this.supplier.doc = doc;
        this.supplier.data = doc.data();
        this.supplier.id = doc.id;
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.b-form-tags.form-control {
  background-color: #ffffff00;
}
</style>