<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <h6>Mis pedidos</h6>
      </div>
      <div class="col-md-12 mb-3" v-for="prod in products" :key="prod.id+prod.orderId">
        <OrdersOpen :product="prod.data"
                    :product-id="prod.id"
                    :order-id="prod.orderId"
                    :order="prod.order"/>
      </div>

      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
        <div slot="no-more">No hay más ordenes.</div>
        <div slot="no-results">No hay ordenes.</div>
      </infinite-loading>

    </div>
  </div>
</template>

<script>
import OrdersOpen from "@/components/xplits/orders/OrdersOpen";
import InfiniteLoading from 'vue-infinite-loading';
import firebase from "firebase/app";

export default {
  name: 'MyOrders',
  components: { OrdersOpen, InfiniteLoading},
  props: {
    user: Object
  },
  data() {
    return {
      orders: [],
      products: [],
      query: {
        perPage: 4,
        lastDocInfiniteScroll: null,
      },
    }
  },
  mounted() {
    // this.getOrders()
  },
  methods: {
    async getOrders(overrideOrders = false) {
      let uid = this.user.data.uid

      let db = firebase.firestore()
      let ordersSnap = db.collection('users').doc(uid).collection('orders').orderBy('orderAt', 'desc')
      if (this.query.lastDocInfiniteScroll) {
        ordersSnap = ordersSnap.startAfter(this.query.lastDocInfiniteScroll)
      }
      ordersSnap = await ordersSnap.limit(this.query.perPage).get()

      if (overrideOrders) {
        this.orders = []
        this.products = []
      }

      let queryResultsCount = 0

      for await (const o of ordersSnap.docs) {
        this.orders.push({
          id: o.id,
          data: o.data(),
          doc: o
        })
        let productsSnap = await db.collection('users').doc(uid)
            .collection('orders').doc(o.id).collection('products').get()
        productsSnap.forEach(p => {
          this.products.push({
            id: p.id,
            data: p.data(),
            doc: p,
            orderId: o.id,
            order: o.data(),
          })

          queryResultsCount++;
        })
      }

      return {
        count: queryResultsCount,
        lastDocumentSnapshot: this.orders[this.orders.length - 1].doc
      }
    },
    async infiniteHandler(state) {
      const productsFetchResult = await this.getOrders(false)

      this.query.lastDocInfiniteScroll = productsFetchResult.lastDocumentSnapshot

      if (productsFetchResult.count > 0) {
        state.loaded()
      } else {
        state.complete()
      }
    }
  }
}
</script>