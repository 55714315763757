import store from '../../store/index';

export default function authorizedMiddleware({next, roleIds = [1], redirectTo = null}) {
    let data = store.state.user.data;
    if (data == null) return next({ name: 'Login' })
    let roleUID = data.roleUID;
    if (roleUID == null) return next({ name: 'Home' })
    if (roleIds.includes(Number.parseInt(roleUID))) {
        return next()
    } else {
        if (redirectTo) return next({ name: redirectTo })

        switch(Number.parseInt(roleUID)) {
            case 1: return next({ name: 'Home' })
            case 2: return next({ name: 'Home' })
            case 3: return next({ name: 'Banners' })
            case 4: return next({ name: 'Orders' })
            case 5: return next({ name: 'Orders' })
            default: return next({ name: 'Home' })
        }
    }
}