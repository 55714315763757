<template>
  <div>
    <div v-if="groupedPayouts.length < 1" class="text-center">
      <img src="@/assets/img/empty-states/waiting.svg" class="img-fluid mt-3 mb-5" width="260px"
           alt="Imagen de pagos">
      <h6 class="font-weight-normal">
        {{ onboardingMessage }}
      </h6>
    </div>

    <div v-for="(payout, index) in groupedPayouts" v-bind:key="'index-' + index" class="card shadow-sm mb-3">
      <div class="card-header mt-2">
        <h6>Fecha de generación del bono:
          {{ new Date(payout.created_at).toLocaleDateString('es-MX', {timeZone: 'America/Monterrey'}) }}</h6>
        <span class="subtitle-1 text-muted">No. identificador: {{ payout.group_payout_uuid }}</span>
      </div>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-4">
            <h6 class="subtitle-1">Suma total de bonos:</h6>
            <p class="font-weight-bold text-secondary">$ {{ payout.total_amount }} MXN</p>
          </div>
          <div class="col-md-4 col-lg-4">
            <h6 class="subtitle-1">Estatus:</h6>
            <p class="font-weight-bold text-secondary">
              {{ payout.is_paid === 0 ? 'Pendiente' : 'Pagado' }}
            </p>
          </div>
          <div class="col-md-4 col-lg-4 text-right">
            <button @click="printReceipt(payout.group_payout_uuid)"
                    :disabled="isPrintLoading"
                    class="btn btn-primary-outline">
              {{ isPrintLoading ? "Generando recibo..." : "Descargar" }}
              <!--              <span class="material-icons-outlined">file_download</span>-->
            </button>

            <iframe :src="payoutReceiptUrl" frameborder="0" style="width:0;height:0;"></iframe>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  name: 'VoucherCard',
  mounted() {
    this.fetchGroupedPayouts()
  },
  data() {
    return {
      onboardingMessage: "Cargando...",
      groupedPayouts: [],
      payoutReceiptUrl: null,
      isPrintLoading: false,
    }
  },
  methods: {
    printReceipt(uuid) {
      this.isPrintLoading = true
      const random = Math.random()
      this.payoutReceiptUrl = `/xplits/panel/payouts/${uuid}/receipt?iframeTrigger=${random}`
      setTimeout(() => this.isPrintLoading = false, 5000)
    },
    fetchGroupedPayouts() {
      const getGroupedPayouts = firebase.functions().httpsCallable('getGroupedPayouts')

      getGroupedPayouts()
          .then(res => res.data)
          .then(data => this.groupedPayouts = data.data)
          .then(() => this.onboardingMessage = "Aún no se genera ningún grupo de bonos.")
          .catch(() => this.onboardingMessage = "Ocurrió un error al cargar el historial de bonos.")
    }
  }
}
</script>