<template>
  <div>
    <div v-for="(product, index) in products" :key="'cart-item-' + index" class="shopping-cart-item my-3">
      <b-row class="align-items-center">

        <b-col md="2">
          <b-form-spinbutton :disabled="loading"
                             @change="(to) => handleCounterChange(product, to)"
                             :max="product.data.stock"
                             id="sb-vertical-item-1" min="0"
                             v-model="product.data.count"
                             vertical/>
        </b-col>

        <b-col md="4">
          <p class="overline text-truncate mb-0" v-text="product.data.brand_label"/>
          <router-link :to="{ name: 'Product', params: { categoryId: product.data.category, productId: product.id } }"
                       class="subtitle-1 font-weight-bold"
                       v-text="product.data.title"
                       v-if="productLinkOnName"/>
          <p class="subtitle-1 font-weight-bold"
             v-text="product.data.title"
             v-else/>
        </b-col>

        <b-col md="3">
          <product-image-hero v-model="product.data.photoURL" style="max-width: 180px; margin-left: auto; margin-right: auto; display: block"/>
        </b-col>

        <b-col md="3" v-if="optimalDiscounts[product.id] == null">
          <p class="subtitle-2 font-weight-bold text-primary text-right"
             v-text="formatPrice(product.data.price * product.data.count)"/>
        </b-col>
        <b-col md="3" v-else>
          <div class="subtitle-2 text-muted text-right">
            <b-badge variant="secondary">-{{ optimalDiscounts[product.id].data.percentage }}%</b-badge>
            <del v-text="formatPrice(product.data.price * product.data.count)"/>
          </div>
          <p class="subtitle-2 font-weight-bold text-primary text-right"
             v-text="formatPrice(totalPriceWithDiscount(product, optimalDiscounts[product.id]))"/>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import ProductImageHero from "@/components/product/ProductImageHero";
import {mapGetters} from "vuex";

export default {
  name: "ShoppingProductsList",
  components: {ProductImageHero},
  props: {
    products: {
      default: () => {
        return []
      },
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean
    },
    productLinkOnName: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    async handleCounterChange(product, newCount) {
      this.$emit('isCartBusy', true)

      let products = this.products
      let totalCount = 0

      await this.$store.dispatch('updateCartProductCount', {product, newCount})

      if (newCount === 0) {
        products = this.products.filter(p => p.id !== product.id)
      }

      products.forEach((product) => totalCount += product.data.count)

      this.$store.commit('SET_CART_PRODUCTS_COUNT', totalCount)
      this.$emit('productsChanged', products)
      this.$emit('cartBusy', false)
    },
    closeShoppingCart() {
      this.$store.dispatch('toggleClassCart')
    },
    getOptimalDiscount(product) {
      let ds = product.discounts.filter(d => d.data.quantity <= product.data.count);
      ds = ds ? ds.sort((a, b) => b.data.percentage - a.data.percentage)[0] : null;
      return ds;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
    totalPriceWithDiscount(product, discount) {
      let percentage = discount.data.percentage;
      let price = product.data.price;
      let count = product.data.count;

      return (price * count) * ((100 - percentage) / 100)
    },
  },
  computed: {
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
    optimalDiscounts() {
      let discounts = {}
      this.products.forEach(product => discounts[product.id] = this.getOptimalDiscount(product));
      return discounts;
    },
  }
}
</script>

<style scoped>

</style>