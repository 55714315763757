<template>
  <b-overlay :show="isBusy">
    <template #overlay>

      <div class="text-center m-3">
        <h5 class="mb-3 text-primary">Cargando terminal de pagos...</h5>
        <b-spinner style="width: 4rem; height: 4rem; margin-bottom: 1rem"
                   label="Cargando terminal de pagos"
                   variant="primary"/>
      </div>

    </template>
    <Navbar hide-shopping-cart></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>


      <div class="bs-stepper" ref="stepper">
        <div class="bs-stepper-header" role="tablist">
          <!-- your steps here -->
          <bs-stepper-step stepper-id="stepper-address-view"
                           button-icon="house"
                           label="Dirección de Envío"/>
          <div class="line"></div>
          <bs-stepper-step stepper-id="stepper-products-view"
                           button-icon="shopping_bag"
                           label="Productos"/>
          <div class="line"></div>
          <!--          <bs-stepper-step stepper-id="stepper-delivery-view"-->
          <!--                           button-icon="local_shipping"-->
          <!--                           label="Envío"/>-->
          <!--          <div class="line"></div>-->
          <bs-stepper-step stepper-id="stepper-confirmation-view"
                           button-icon="shopping_cart"
                           label="Confirmación"/>

        </div>
        <div class="bs-stepper-content">
          <!-- your steps content here -->
          <div id="stepper-address-view"
               aria-labelledby="stepper-address-view-trigger"
               class="content"
               role="tabpanel">
            <address-step @selected="selectAddressStep"/>
          </div>
          <div id="stepper-products-view"
               aria-labelledby="stepper-products-view-trigger"
               class="content"
               role="tabpanel">
            <products-list-step @selected="selectProductsStep"/>
          </div>
          <!--          <div id="stepper-delivery-view"-->
          <!--               aria-labelledby="stepper-delivery-view-trigger"-->
          <!--               class="content"-->
          <!--               role="tabpanel">-->
          <!--            <delivery-option-step />-->
          <!--          </div>-->
          <div id="stepper-confirmation-view"
               aria-labelledby="stepper-confirmation-view-trigger"
               class="content"
               role="tabpanel">
            <confirmation-step :checkout="checkout"
                               @back-checkout="moveToStepperPosition"
                               @confirmed="handlePay"/>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </b-overlay>
</template>

<script>

import Navbar from "@/components/Navbar";
import SearchBar from "@/components/Home/SearchBar";
import Footer from "@/components/Footer";
// import firebase from "firebase/app";
import Stepper from 'bs-stepper'
import BsStepperStep from "@/components/share/bsStepper/bsStepperStep";
import AddressStep from "@/components/checkout/AddressStep";
import ProductsListStep from "@/components/checkout/ProductsListStep";
import ConfirmationStep from "@/components/checkout/ConfirmationStep";
import {mapGetters} from "vuex";
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  name: 'Checkout',
  metaInfo: {
    title: 'Checkout',
  },
  components: {
    ConfirmationStep, ProductsListStep, AddressStep, BsStepperStep, Footer, SearchBar, Navbar
  },
  data() {
    return {
      items: [
        {text: 'Inicio', to: {name: 'Home'}},
        {text: 'Envío',},
      ],
      stepper: null,//new Stepper(null),
      checkout: {
        address: null,
        products: [],
        delivery: {
          id: null,
          type: 'Normal',
          price: 0,
          maxDays: 5
        },
      },
      isBusy: false
    }
  },
  created() {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://js.openpay.mx/openpay.v1.min.js");
    tag.addEventListener('load', this.openPayLoaded)
    document.head.appendChild(tag);
  },
  mounted() {
    this.stepper = new Stepper(this.$refs.stepper);
  },
  methods: {
    openPayLoaded() {
      window.OpenPay.setId('ms3ooklxrl070carncgr');
      window.OpenPay.setApiKey('pk_d54c973cbd22404aa4e9c3fc103dc94d');
      window.OpenPay.setSandboxMode(true);
    },
    handlePay() {
      if (this.userId) {
        this.isBusy = true
        const createChargeSession = firebase.functions().httpsCallable('openpayCreateChargeSession')
        createChargeSession({
          address: this.checkout.address
        })
            .then(res => window.location.replace(res.data.payment_method.url))
            .catch(err => {
              this.isBusy = false
              console.error(err)
            })
      }
    },
    selectAddressStep(address) {
      this.checkout.address = address
      this.stepper.next()
    },
    selectProductsStep(products) {
      this.checkout.products = products
      this.stepper.next()
    },
    moveToStepperPosition(index) {
      this.stepper.to(index)
    }
  },
  computed: {
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
    userId() {
      return this.user ? this.user.data.uid : null
    }
  }
}
</script>


<style lang="scss">
.bs-stepper-circle {
  width: 2.5rem;
  height: 2.5rem
}
</style>