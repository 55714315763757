<template>
  <b-card class="card-outline" header-class="my-2">
    <template #header>
      <b-row>
        <b-col md="6" lg="4" class="mb-3">
          <div class="d-flex align-items-center">
           <i class="material-icons-outlined mr-2">search</i>
            <b-form-input type="search" id="filterInput" class="form-control-flat"
                          placeholder="subcategoría"/>
          </div>
        </b-col>
        <b-col md="6" lg="8" class="text-md-right">
          <b-btn variant="primary" @click="showFormModal(null, '')">
            <i class="material-icons-outlined">add</i>
            Agregar
          </b-btn>
        </b-col>
      </b-row>
    </template>

    <b-table ref="subcategoriesTable"
             :fields="fields"
             :items="getSubcategories"
             :filter="filter"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :per-page="perPage"
             :current-page="currentPage"
             hover>

      <template #cell(options)="d">
        <b-btn variant="link" size="sm" @click="showFormModal(d.item.id, d.item.title)">
          <i class="material-icons">edit</i>
        </b-btn>
        <router-link class="btn btn-link btn-sm"
                     :to="{name: 'EditFiltersCategory', params: {categoryId: categoryId, subcategoryId: d.item.id }}"
                     v-slot="{ href, navigate }">
          <b-button variant="link" size="sm" :href="href" @click="navigate">
            <i class="material-icons">filter_alt</i>
          </b-button>
        </router-link>
        <b-btn variant="link" size="sm" @click="showDeleteModal(d.item.id, d.item.title)">
          <i class="material-icons">delete</i>
        </b-btn>
      </template>
    </b-table>

    <b-button @click="currentPage--"
              variant="light"
              :disabled="currentPage === 1">
      <i class="material-icons-outlined">arrow_back</i> Anterior
    </b-button>
    <b-button @click="currentPage++"
              variant="light"
              :disabled="isCurrentPageLastPage">
      Siguiente <i class="material-icons-outlined">arrow_forward</i>
    </b-button>

    <b-modal v-model="formModal.show"
             :title="formTitle"
             ok-variant="primary"
             ok-title="Guardar"
             :ok-disabled="!titleValid"
             cancel-variant="link"
             cancel-title="Cancelar"
             @ok="saveMethod">
      <b-form-group label="Título" :validated="titleValid">
        <b-form-input v-model="formModal.data.title"/>
      </b-form-group>
    </b-modal>

    <b-modal v-model="deleteModal.show"
             :title="deleteModalTitle"
             ok-variant="danger"
             ok-title="Eliminar"
             cancel-variant="link"
             cancel-title="Cancelar"
             @ok="deleteSubcategory">
      Al eliminar la subcategoría los productos asosiados a ella se quedarán sin categoría asociada, podrás asignarles
      una nueva categoría en el formulario de productos.
    </b-modal>
  </b-card>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "TableSubcategories",
  components: {},
  props: {
    categoryId: String
  },
  data() {
    return {
      subcategories: [],
      formModal: {
        id: null,
        show: false,
        data: {
          title: ''
        }
      },
      deleteModal: {
        show: false,
        id: null,
        title: '',
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'title', label: 'Título', sortable: true },
        { key: 'options', label: 'Opciones' }
      ],
      filter: '',
      perPage: 10,
      currentPage: 1,
      sortBy: 'title',
      sortDesc: false,
      startAfterDocsList: []
    }
  },
  mounted() {

  },
  methods: {
    async getSubcategories() {
      let c = firebase.firestore().collection('categories').doc(this.categoryId)
      let subcategories = c.collection('subcategories')
          .limit(this.perPage)
          .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.filter !== '') {
        subcategories = subcategories.where('filterWords', 'array-contains-any', this.filter.split(' '))
      }

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.suppliers[this.suppliers.length - 1].doc
        subcategories = subcategories.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []


      let subcategoriesArr = []

      return subcategories.get().then(subcat => {
        subcat.forEach(s => {
          subcategoriesArr.push({
            id: s.id,
            doc: s,
            ...s.data(),
          })
        })

        this.subcategories = subcategoriesArr;
        return subcategoriesArr;
      }).catch(error => {
        console.log(error)
        this.subcategories = []
        return []
      })
    },
    async saveMethod() {
      let ref = firebase.firestore().collection('categories').doc(this.categoryId).collection('subcategories')
      ref = this.formModal.id ? ref.doc(this.formModal.id) : ref.doc()

      await ref.set(this.formModal.data, { merge: true})

      this.$refs.subcategoriesTable.refresh()
    },
    async deleteSubcategory() {
      let ref = firebase.firestore()
          .collection('categories')
          .doc(this.categoryId)
          .collection('subcategories')
          .doc(this.deleteModal.id)

      await ref.delete()

      this.$refs.subcategoriesTable.refresh()
    },
    showFormModal(id = null, title = '') {
      this.formModal.show = true
      this.formModal.id = id
      this.formModal.data = {title: title}
    },
    showDeleteModal(id, title) {
      this.deleteModal.show = true
      this.deleteModal.id = id
      this.deleteModal.title = title
    }
  },
  computed: {
    formTitle() {
      return this.formModal.id ? 'Editar Subcategoría' : 'Guardar Subcategoría'
    },
    deleteModalTitle() {
      return "¿Estás seguro que quieres eliminar "+this.deleteModal.title+"?"
    },
    titleValid() {
      return this.formModal.data.title.length > 3
    },
    isCurrentPageLastPage() {
      return this.subcategories.length < this.perPage;
    }
  },
  watch: {
    categoryId() {
      this.getSubcategories()
    }
  }
}
</script>

<style scoped>

</style>