<template>
  <div>
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- SIDE MENU -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card card-no-border">
            <b-tabs v-model="panelIndex"
                    pills
                    card
                    vertical lazy>
              <b-tab title="Mi panel">
                <!-- PANEL -->
                <MyPanel :user="user.data" :userMicroserviceInfo="userMicroserviceInfo"></MyPanel>
              </b-tab>
              <b-tab title="Mis invitados">
                <!-- INVITADOS -->
                <MyInvites :user="user.data" :userMicroserviceInfo="userMicroserviceInfo"></MyInvites>
              </b-tab>
              <b-tab title="Mis pedidos">
                <!-- PEDIDOS -->
                <MyOrders :user="user"></MyOrders>
              </b-tab>
              <b-tab title="Mis bonos">
                <!-- BONOS -->
                <MyVouchers></MyVouchers>
              </b-tab>
              <b-tab title="Dale Xplits">
                <!-- DALE XPLITS -->
                <DaleXplits></DaleXplits>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import SearchBar from "@/components/Home/SearchBar";
import Footer from "@/components/Footer";
import MyPanel from "@/components/xplits/MyPanel";
import MyInvites from "@/components/xplits/MyInvites";
import DaleXplits from "@/components/xplits/DaleXplits";
import MyOrders from "@/components/xplits/MyOrders";
import MyVouchers from "@/components/xplits/MyVouchers";
import {mapGetters} from 'vuex'
import firebase from "firebase";

export default {
  name: 'Panel',
  metaInfo: {
    title: 'Panel',
  },
  data() {
    return {
      items: [ { text: 'Inicio', to: {name: 'Home'} }, { text: 'Mi Xplits', },],
      panelIndex: 0,
      userMicroserviceInfo: {},
    }
  },
  mounted() {
    this.fetchUserInfo()
  },
  created() {
    let panelIndex = this.$route.query.tabIndex || 0;
    this.panelIndex = panelIndex < 0 || panelIndex > 5 ? 0 : parseInt(panelIndex);
  },
  components: {MyVouchers, MyOrders, DaleXplits, MyInvites, MyPanel, Footer, SearchBar, Navbar},
  computed: {
    ...mapGetters(['isCartOpen', 'user', 'cartProductsCount'])
  },
  methods: {
    async fetchUserInfo() {
      const getUserInfo = firebase.functions().httpsCallable('getUserInfo')
      const info = await getUserInfo()
      this.userMicroserviceInfo = info.data.data

      console.debug("[Panel.vue]", "User info from microservice...", info.data.data)
    },
  }
}
</script>