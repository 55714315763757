var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-outline",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn-round ripple mr-3",attrs:{"variant":"light"},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("arrow_back")]),_vm._v("Regresar ")]),_c('b-button',{staticClass:"btn-round ripple px-5",attrs:{"variant":"primary","disabled":!(_vm.validName && _vm.validContactName && _vm.validRFC)},on:{"click":_vm.saveSupplier}},[_vm._v(" Guardar ")])],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre del Proveedor","validated":_vm.validName}},[_c('b-form-input',{attrs:{"placeholder":"Nutrición Universal S.A. de C.V."},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"RFC","validated":_vm.validRFC}},[_c('b-form-input',{model:{value:(_vm.value.rfc),callback:function ($$v) {_vm.$set(_vm.value, "rfc", $$v)},expression:"value.rfc"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre del contacto","validated":_vm.validContactName}},[_c('b-form-input',{attrs:{"placeholder":"Mauricio Gaytán"},model:{value:(_vm.value.contactName),callback:function ($$v) {_vm.$set(_vm.value, "contactName", $$v)},expression:"value.contactName"}})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfonos de contacto"}},[_c('b-form-tags',{staticStyle:{"padding":"0","border":"0"},attrs:{"tag-validator":_vm.phoneValidator,"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"placeholder":"Agrega nuevo telefono"}},'b-form-input',inputAttrs,false),inputHandlers),[_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Agregar")])],1)],1),_c('b-list-group',{staticClass:"my-1"},_vm._l((tags),function(tag){return _c('b-list-group-item',{key:tag},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"m-0",domProps:{"textContent":_vm._s(tag)}}),_c('b-button-close',{on:{"click":function($event){return removeTag(tag)}}})],1)])}),1)]}}]),model:{value:(_vm.value.contactPhones),callback:function ($$v) {_vm.$set(_vm.value, "contactPhones", $$v)},expression:"value.contactPhones"}})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Correos de contacto"}},[_c('b-form-tags',{staticStyle:{"padding":"0","border":"0"},attrs:{"tag-validator":_vm.emailValidator,"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"placeholder":"Agrega nuevo correo"}},'b-form-input',inputAttrs,false),inputHandlers),[_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Agregar")])],1)],1),_c('b-list-group',{staticClass:"my-1"},_vm._l((tags),function(tag){return _c('b-list-group-item',{key:tag},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('p',{staticClass:"m-0",domProps:{"textContent":_vm._s(tag)}}),_c('b-button-close',{on:{"click":function($event){return removeTag(tag)}}})],1)])}),1)]}}]),model:{value:(_vm.value.contactMails),callback:function ($$v) {_vm.$set(_vm.value, "contactMails", $$v)},expression:"value.contactMails"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }