<template>
  <div class="row">
    <div class="col-md-6 mb-3">
      <h6>Mis núcleos</h6>
    </div>
    <div class="col-md-6 text-right">
      <b-button variant="default-outline"
                @click="modalAddInvites.visible = true"
                class="btn-round btn-full-width ripple ripple-dark mx-0">
        <i class="material-icons">add</i> Invitar
      </b-button>
    </div>
    <div class="col-md-12">
      <BTabs content-class="mt-3" lazy>
        <!--Core 0 is remainder users-->
        <BTab title="Nucleo incompleto">
          <InvitedPeople :core-number="0"></InvitedPeople>
        </BTab>
        
        <!--        <BTab :title="'Núcleo ' + coreNumber">-->
        <BTab v-for="index in userMicroserviceInfo.cores_count" :key="index + '-nucleos-index'" :title="`Núcleo #${index}`">
          <InvitedPeople :coreNumber="index"></InvitedPeople>
        </BTab>

      </BTabs>

      <!-- MODAL -->
      <ModalAddInvites v-model="modalAddInvites.visible" :user="user"></ModalAddInvites>
    </div>
  </div>
</template>

<script>
import ModalAddInvites from "@/components/xplits/invites/ModalAddInvites";
import InvitedPeople from "@/components/xplits/invites/InvitedPeople";

export default {
  name: 'MyInvites',
  components: {ModalAddInvites, InvitedPeople},
  props: [
    'user',
      'userMicroserviceInfo'
  ],
  data() {
    return {
      modalAddInvites: {
        visible: false
      },
    }
  },
}
</script>