<template>
  <div>
    <b-sidebar id="sidebar-admin" class="sidebar" title="Menú" backdrop>
      <div class="px-3 py-2">

        <b-list-group v-if="hasAccess([1])">
          <b-list-group-item to="/admin/dashboard" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">home</i>
            Inicio
          </b-list-group-item>

          <b-list-group-item to="/admin/store/banners"
                             class="list-group-item-admin ripple ripple-dark d-flex justify-content-between align-items-center">
            <div>
              <i class="material-icons-outlined mr-2">image</i>
              Banners
            </div>
          </b-list-group-item>

        </b-list-group>
        <!-- SUB MENUS -->
        <b-list-group v-if="hasAccess([1])">
          <h6 class="subtitle-1 my-3">Productos</h6>
          <b-list-group-item :to="{ name: 'Catalog' }" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">local_mall</i>
            Catálogo
          </b-list-group-item>
          <b-list-group-item :to="{ name: 'CatalogGroups' }" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">layers</i>
            Grupo de Productos (variantes)
          </b-list-group-item>
          <b-list-group-item to="/admin/products/categories" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">category</i>
            Categorías
          </b-list-group-item>
<!--          <b-list-group-item to="/admin/products/orders" class="list-group-item-admin ripple ripple-dark">-->
<!--            <i class="material-icons-outlined mr-2">local_shipping</i>-->
<!--            Pedidos-->
<!--          </b-list-group-item>-->
<!--          <b-list-group-item to="/admin/products/bonos" class="list-group-item-admin ripple ripple-dark">-->
<!--            <i class="material-icons-outlined mr-2">money</i>-->
<!--            Bonos-->
<!--          </b-list-group-item>-->
          <b-list-group-item to="/admin/brands" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">group_work</i>
            Marcas
          </b-list-group-item>
          <b-list-group-item to="/admin/objectives" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">fitness_center</i>
            Objetivos
          </b-list-group-item>
          <b-list-group-item to="/admin/suppliers" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">business</i>
            Proveedores
          </b-list-group-item>
          <b-list-group-item to="/admin/taxes" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">monetization_on</i>
            Impuestos
          </b-list-group-item>
        </b-list-group>
        <!-- SUB MENUS -->
<!--        <h6 class="subtitle-1 my-3">Pagos y facturas</h6>-->
<!--        <b-list-group>-->
<!--          <b-list-group-item to="/admin/payment/pays" class="list-group-item-admin ripple ripple-dark">-->
<!--            <i class="material-icons-outlined mr-2">payment</i>-->
<!--            Pagos-->
<!--          </b-list-group-item>-->
<!--          <b-list-group-item to="/admin/payment/invoices" class="list-group-item-admin ripple ripple-dark">-->
<!--            <i class="material-icons-outlined mr-2">receipt</i>-->
<!--            Facturas-->
<!--          </b-list-group-item>-->
<!--        </b-list-group>-->
        <!-- SUB MENUS -->

        <b-list-group v-if="hasAccess([4, 5])">
          <h6>Proveedores</h6>

          <b-list-group-item to="/admin/orders" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">local_shipping</i>
            Pedidos
          </b-list-group-item>
        </b-list-group>

        <h6 v-if="hasAccess([1])" class="subtitle-1 my-3">Usuarios</h6>
        <b-list-group v-if="hasAccess([1])">
          <b-list-group-item to="/admin/bonos" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">money</i>
            Pago de bonos
          </b-list-group-item>
          <b-list-group-item to="/admin/users" class="list-group-item-admin ripple ripple-dark">
            <i class="material-icons-outlined mr-2">person_add</i>
            Registros
          </b-list-group-item>
        </b-list-group>
        <!-- SUB MENUS -->
        <!--        <h6 v-if="hasAccess([1])" class="subtitle-1 my-3">Cuentas</h6>
                <b-list-group v-if="hasAccess([1])">
                  <b-list-group-item to="/admin/account/settings" class="list-group-item-admin ripple ripple-dark">
                    <i class="material-icons-outlined mr-2">settings</i>
                    Configuración
                  </b-list-group-item>

                  &lt;!&ndash;
                  <b-list-group-item to="/admin/" class="list-group-item-admin ripple ripple-dark">
                    <i class="material-icons-outlined mr-2">web</i>
                    Blog
                  </b-list-group-item>
                  &ndash;&gt;

                </b-list-group>-->
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    hasAccess(roles = [1]) {
      let x = roles.find(r => r == this.roleUID)
      return x != null;
    }
  },
  computed: {
    ...mapGetters(['user']),
    roleUID() {
      return this.user.adminData.roleUID;
    }
  },
}
</script>