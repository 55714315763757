<template>
  <div class="shopping-cart">

    <div class="shopping-cart-container">

      <div class="shopping-cart-header">
        <div class="d-flex justify-content-between">
          <h6 class="subtitle-1">Carrito ({{ cartProductsCount }} {{
              cartProductsCount > 1 ? 'articulos' : 'articulo'
            }})</h6>
          <button class="btn btn-link btn-sm ripple ripple-dark m-0" @click="closeShoppingCart">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
      <shopping-products-list class="shopping-cart-body"
                              @productsChanged="onProductsChanged"
                              @cartBusy="onCartBusy"
                              :products="products"/>

      <div v-if="products.length > 0" class="shopping-cart-footer text-center mt-3">
        <h6 class="subtitle-1 mb-3">Total:
          <span class="font-weight-bold" v-text="formatPrice(totalPrice)+' MXN'"></span>
        </h6>
        <router-link :disabled="isBusy" @click="() => $store.dispatch('toggleClassCart')"
                     to="/checkout" class="btn btn-primary btn-round btn-block ripple">
          Comprar
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import firebase from 'firebase/app'
import 'firebase/firestore'
import ShoppingProductsList from "@/components/share/ShoppingProductsList";

export default {
  components: {ShoppingProductsList},
  mounted() {
    if (this.isCartOpen) {
      this.onCartOpen()
    }
  },
  data() {
    return {
      products: [],
      isBusy: false,
    }
  },
  methods: {
    async onCartOpen() {
      this.products = []
      await this.fetchCartProducts();
      for (let i = 0; i < this.products.length; i++) {
        await this.getDiscounts(this.products[i]);
      }
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
    closeShoppingCart() {
      this.$store.dispatch('toggleClassCart')
    },
    async fetchCartProducts() {
      const productsSnap = await firebase.firestore()
          .collection('shopping_carts')
          .doc(this.user.data.uid)
          .collection('products')
          .get()

      productsSnap.forEach(product => {
        this.products.push({
          data: product.data(),
          id: product.id,
          doc: product,
          discounts: []
        })
      })
    },
    async getDiscounts(product) {
      let id = product.id;
      const today = firebase.firestore.Timestamp.now();
      const discounts = await firebase.firestore()
          .collection('products')
          .doc(id)
          .collection('discounts')
          .where('code', '==', null)
          .get()
      const discountCode = await firebase.firestore().collection('products')
          .doc(id)
          .collection('discounts')
          .where('code', '==', product.data.discountCode)
          .get()

      let discountsEl = []
      discounts.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          discountsEl.push({
            id: d.id,
            data: data,
            doc: d
          })
        }
      })
      discountCode.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          discountsEl.push({
            id: d.id,
            data: data,
            doc: d
          })
        }
      })

      product.discounts = discountsEl;
    },
    getOptimalDiscount(product) {
      let ds = product.discounts.filter(d => d.data.quantity <= product.data.count);
      ds = ds ? ds.sort((a, b) => b.data.percentage - a.data.percentage)[0] : null;
      return ds;
    },
    onProductsChanged(products) {
      console.log("PRODUCTS CHANGED", products)
      this.products = products
    },
    onCartBusy(isCartBusy) {
      this.isBusy = isCartBusy
    }
  },
  computed: {
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
    optimalDiscounts() {
      let discounts = {}
      this.products.forEach(product => discounts[product.id] = this.getOptimalDiscount(product));
      return discounts;
    },
    totalPrice() {
      let c = 0;
      this.products.forEach(p => {
        let d = this.optimalDiscounts[p.id];
        c += d
            ? (p.data.price * p.data.count) * ((100 - d.data.percentage) / 100)
            : p.data.price * p.data.count
      })
      return c;
    },
  },
  watch: {
    isCartOpen: function (newValue) {
      if (newValue) {
        this.onCartOpen()
      }
    },
    // products: {
    //   handler(newProducts) {
    //     if (newProducts.length < 1) {
    //       return
    //     }
    //     let totalCount = 0
    //     newProducts.forEach((product, index, self) => {
    //       totalCount += product.data.count
    //       // if (product.data.count === 0) {
    //       //   self.splice(index, 1)
    //       // }
    //     })
    //     this.$store.commit('SET_CART_PRODUCTS_COUNT', totalCount)
    //   }, deep: true
    // }
  },
}
</script>

<style scoped lang="scss">

</style>