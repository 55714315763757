<template>
  <div class="home">
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <Carousel></Carousel>

    <!-- SHORTCUTS -->
    <div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <div class="card card-home-link bg-primary-gradient py-5">
            <div class="card-body">
              <h6 class="text-white">Mi Xplits</h6>
              <hr class="bg-white w-25 mx-0" style="max-width: 100px;">
              <router-link to="/xplits/panel" class="text-light">
                Ver más
                <i class="material-icons">add</i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-home-link bg-secondary-gradient py-5">
            <div class="card-body">
              <h6 class="text-white">Cómo funciona</h6>
              <hr class="bg-white w-25 mx-0" style="max-width: 100px;">
              <router-link to="/about-us" class="text-light">
                Ver más
                <i class="material-icons">add</i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-home-link bg-dark-gradient py-5">
            <div class="card-body">
              <h6 class="text-white">Tienda</h6>
              <hr class="bg-white w-25 mx-0" style="max-width: 100px;">
              <router-link to="/store" class="text-light">
                Ver más
                <i class="material-icons">add</i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- STORE PRODUCTS -->
    <div class="container py-5">
      <div class="row mb-3">
        <div class="col-md-12 text-center">
          <h4 class="font-weight-bold">Tienda</h4>
          <h6 class="subtitle-1 text-muted">Encuentra tus favoritos en nuestro catálogo de productos.</h6>
        </div>
      </div>

      <b-row>
        <product-item v-for="product in products"
                      :key="product.id"
                      :value="product"
                      sm="6"
                      md="4"
                      lg="3"
                      xl="3"
                      cols="6"/>
      </b-row>

      <div class="row">
        <div class="col-md-12 text-center mt-3">
          <router-link to="/store" class="btn btn-primary btn-round ripple px-5">Ver más</router-link>
        </div>
      </div>

      <ModalWelcome/>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import Carousel from '@/components/Home/Carousel'
import firebase from "firebase";
import ProductItem from "@/components/product/ProductItem";
import ModalWelcome from "@/components/profile/ModalWelcome";


export default {
  name: 'Home',
  metaInfo: {
    title: 'Inicio',
  },
  components: {
    ModalWelcome,
    ProductItem,
    Navbar,
    Footer,
    SearchBar,
    Carousel
  },
  data() {
    return {
      products: [],
      db: firebase.firestore()
    }
  },
  mounted() {
    this.fetchProducts()
  },
  methods: {
    async fetchProducts() {
      try {
        const productsSnap = await this.db
            .collection('products')
            .orderBy('created_at', "desc")
            .limit(8)
            .get()

        this.products = productsSnap.docs.map(doc => {
          return {id: doc.id, ...doc.data()}
        })
      } catch (e) {
        console.log(e.message)
      }
    }
  }
}
</script>