<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container fluid class="mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col md="12" class="mb-3">
          <h6 class="font-weight-bold">Imágenes del carrusel del inicio</h6>
        </b-col>
        <b-col md="12" class="mb-3">
          <b-card class="card-outline" header="Banner del carrusel (múltiples banners)">
            <b-overlay :show="carouselImg.isBusy">
              <b-row class="align-items-center">
                <b-col md="6" lg="6" xl="4">
                  <InputFile id="banner-carousel"
                             v-model="carouselImage"
                             label="Banner del carrusel (múltiples)"
                             @input="uploadCarouselFile"
                             :accept="accept"/>
                </b-col>

                <b-col v-for="img in carouselImg.items"
                       :key="img.id"
                       class="p-3"
                       md="6"
                       lg="6"
                       xl="4">
                  <b-button-close class="float-right"
                                  @click="deleteCarouselFile(img.doc)"/>
                  <b-img fluid :src="img.data.url" />
                </b-col>

              </b-row>
            </b-overlay>
          </b-card>
        </b-col>
        <div class="col-md-12 my-3">
          <h6 class="font-weight-bold">Multimedia de la tienda</h6>
        </div>
        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <InputFile id="banner-main-store"
                               v-model="mainStoreImage"
                               label="Banner de la tienda"
                               @input="uploadImageFile($event, 'mainStoreImage')"
                               :accept="accept"/>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <InputFile id="banner-about-us"
                               v-model="aboutUsImage"
                               label="Video de 'Nosotros'"
                               @input="uploadImageFile($event, 'aboutUsImage')"
                               :accept="acceptImgVideos"/>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <InputFile id="banner-material-xplits"
                               v-model="materialSocialXplit"
                               @input="uploadImageFile($event, 'materialSocialXplits')"
                               label="Material Dale Xplits Redes Sociales"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/admin/includes/Navbar";
import Footer from "@/components/Footer";
import Sidebar from "@/components/admin/includes/Sidebar";
import InputFile from "@/components/InputFile";
import firebase from "firebase/app";

export default {
  metaInfo: {
    title: 'Banners',
  },
  components: {InputFile, Sidebar, Footer, Navbar},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Tienda',
        },
        {
          text: 'Banners',
        },
      ],
      carouselImage: null,
      mainStoreImage: null,
      aboutUsImage: null,
      materialSocialXplit: null,
      accept: 'image/*',
      acceptImgVideos: 'video/*',
      uuid: require('uuid'),

      carouselImg: {
        items: [],
        isBusy: false
      },
      multimediaImg: {
        isBusy: false,
        bannerMainStore: null,
        bannerAboutUs: null,
        xplitsMaterial: null
      }
    }
  },
  mounted() {
    this.getCarouselItems()
  },
  methods: {
    async getCarouselItems() {
      let doc = await firebase
          .firestore()
          .collection('image_banners')
          .doc('carouselImage')
          .collection('items')
          .get()

      this.carouselImg.items = []
      doc.forEach(i => {
        this.carouselImg.items.push({
          id: i.id,
          doc: i,
          data: i.data()
        })
      })
    },
    uploadCarouselFile(file) {
      let doc = firebase
          .firestore()
          .collection('image_banners')
          .doc('carouselImage')
          .collection('items')
          .doc()

      let store = firebase
          .storage()
          .ref()
          .child('image_banners')
          .child('carouselImage')


      let uuid = this.uuid.v4()

      let splitName = file.name.split('.');
      let ext = splitName[splitName.length - 1]
      let route = store.child(uuid+'.'+ext);

      this.carouselImg.isBusy = true;

      route.put(file).then(async uploadTaskSnapshot => {
        const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL();
        await doc.set({
          url: downloadUrl,
          image_path: route.fullPath
        })

        this.getCarouselItems()

        this.$bvToast.toast('Imagen de Carrusel cargado correctamente.', {
          title: 'Guardado',
          autoHideDelay: 6500,
          variant: 'primary',
        });
      }).finally(() => {
        this.carouselImg.isBusy = false
      })
    },
    async deleteCarouselFile(doc) {
      this.carouselImg.isBusy = true;

      let data = doc.data()
      await doc.ref.delete()

      let store = firebase.storage().ref().child(data.image_path)
      await store.delete()

      this.carouselImg.isBusy = false;

      this.$bvToast.toast('Imagen eliminada', {
        title: 'Imagen eliminada',
        autoHideDelay: 3500,
        variant: 'primary',
      });
      await this.getCarouselItems();
    },
    async uploadImageFile(file, name) {
      let doc = await firebase
          .firestore()
          .collection('image_banners')
          .doc(name)
          .get()

      let store = firebase
          .storage()
          .ref()
          .child('image_banners')
          .child(name)

      let splitName = file.name.split('.');
      let ext = splitName[splitName.length - 1]

      let uuid = this.uuid.v4()
      let route = store.child(uuid+'.'+ext);

      route.put(file).then(async uploadTaskSnapshot => {
        const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL();

        if (doc.exists) {
          let fullPath = doc.data().image_path
          await firebase.storage().ref().child(fullPath).delete()
        }

        await doc.ref.set({
          url: downloadUrl,
          image_path: route.fullPath
        })

        this.$bvToast.toast('Imagen cargado correctamente.', {
          title: 'Guardado',
          autoHideDelay: 5500,
          variant: 'primary',
        });
      })
    }
  }
}
</script>