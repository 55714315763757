<template>
  <div>
    <b-card class="card-outline" header-class="my-2">
      <template #header>
        <b-row>
          <b-col md="6" lg="4" class="mb-3">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter" type="search" id="filterInput" class="form-control-flat"
                            placeholder="Buscar por sujeto, fecha, proridad u otro campo"/>
            </div>
          </b-col>
          <b-col md="8" lg="8" class="text-md-right">
            <router-link to="/admin/suppliers/register" class="btn btn-primary">
              <i class="material-icons-outlined">add</i>
              Agregar
            </router-link>
          </b-col>
        </b-row>
      </template>

      <b-table :items="getSuppliers"
               :fields="fields"
               :filter="filter"
               :busy="tableBusy"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :per-page="perPage"
               :current-page="currentPage"
               responsive="xl"
               borderless
               hover
               small>
        <template #cell(name)="d">
          <p v-text="d.item.data.name"
             class="text-wrap"/>
        </template>
        <template #cell(rfc)="d">
          <p v-text="d.item.data.rfc"
             class="text-wrap"/>
        </template>
        <template #cell(contactName)="d">
          <p v-text="d.item.data.contactName"
             class="text-wrap"/>
        </template>
        <template #cell(contactPhones)="d">
          <p v-text="d.item.data.contactPhones.join(', ')"
             class="text-wrap"/>
        </template>
        <template #cell(contactMails)="d">
          <p v-text="d.item.data.contactMails.join(', ')"
             class="text-wrap"/>
        </template>
        <template v-slot:cell(options)="data">
          <router-link :to="{ name: 'SuppliersRegisterEdit', params: { id: data.item.id } }"
                       variant="link"
                       class="ripple ripple-dark"
                       v-b-tooltip.hover
                       title="Editar">
            <i class="material-icons-outlined">edit</i>
          </router-link>
        </template>

      </b-table>

      <b-button @click="currentPage--"
                variant="light"
                :disabled="currentPage === 1">
        <i class="material-icons-outlined">arrow_back</i> Anterior
      </b-button>
      <b-button @click="currentPage++"
                variant="light"
                :disabled="isCurrentPageLastPage">
        Siguiente <i class="material-icons-outlined">arrow_forward</i>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "TableSuppliers",
  data() {
    return {
      filter: "",
      tableBusy: false,
      perPage: 10,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {key: "id", label: 'UUID', sortable: false},
        {key: "name", label: 'Nombre del Proveedor', sortable: true},
        {key: "rfc", label: 'RFC', sortable: true},
        {key: "contactName", label: 'Nombre de contacto', sortable: true},
        {key: "contactPhones", label: 'Teléfonos', sortable: true},
        {key: "contactMails", label: 'Correos', sortable: true},
        {key: "options", label: 'Acciones', sortable: false}
      ],
      suppliers: [],
      datetime: {
        phrases: {
          ok: 'Continuar',
          cancel: 'Salir'
        },
        format: 'dd/MM/yyyy hh:mm a',
        dateOptions: {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          // second: '2-digit'
        },
      },
      startAfterDocsList: []
    }
  },
  methods: {
    getSuppliers() {
      let query = firebase.firestore().collection('suppliers')
          .limit(this.perPage)
          .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.filter !== '') {
        query = query.where('filterWords', 'array-contains-any', this.filter.split(' '))
      }

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.suppliers[this.suppliers.length - 1].doc
        query = query.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      let suppliersArray = []
      return query.get().then(suppliers => {
        suppliers.forEach(s => {
          suppliersArray.push({
            id: s.id,
            data: s.data(),
            doc: s
          })
        })

        this.suppliers = suppliersArray;
        return suppliersArray;
      }).catch(error => {
        console.log(error)
        this.suppliers = []
        return []
      })
    }
  },
  computed: {
    rows() {
      return this.suppliers.length
    },
    isCurrentPageLastPage() {
      return this.suppliers.length < this.perPage;
    }
  },
}
</script>