<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card card-outline">
            <div class="card-header my-2">
              <h6 class="subtitle-1 font-weight-bold">Estatus:
                <div class="badge badge-success">Enviado</div>
                <span class="subtitle-1 text-muted">02/25/2020</span>
              </h6>
              <hr>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <h6 class="subtitle-2 text-muted">
                    Envío:
                  </h6>
                  <h6 class="subtitle-1">02/10/2020 / 13:00</h6>
                </div>

                <div class="col-md-3">
                  <h6 class="subtitle-2 text-muted">
                    Productos:
                  </h6>
                  <h6 class="subtitle-1">4</h6>
                </div>

                <div class="col-md-3">
                  <h6 class="subtitle-2 text-muted">
                    Total:
                  </h6>
                  <h6 class="subtitle-1">$3,000.00</h6>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h6 class="subtitle-1 font-weight-bold mb-3">Información de facturación</h6>
              <div class="row align-items-center mb-3">
                <div class="col-6 col-md-6 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Nombre:
                  </h6>
                  <h6 class="subtitle-1">John Doe</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Teléfono:
                  </h6>
                  <h6 class="subtitle-1">81 1020 3040</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Email:
                  </h6>
                  <h6 class="subtitle-1">correo@gmail.com</h6>
                </div>
              </div>

              <div class="row align-items-center mb-3">
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    País:
                  </h6>
                  <h6 class="subtitle-1">México</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Estado:
                  </h6>
                  <h6 class="subtitle-1">Nuevo León</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Ciudad:
                  </h6>
                  <h6 class="subtitle-1">Monterrey</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Código postal:
                  </h6>
                  <h6 class="subtitle-1">66780</h6>
                </div>
              </div>

              <div class="row align-items-center mb-3">
                <div class="col-md-3">
                  <h6 class="subtitle-2 text-muted">
                    Dirección:
                  </h6>
                  <h6 class="subtitle-1">Calle 19, #600, Los Morales</h6>
                </div>
              </div>

              <hr>

              <h6 class="subtitle-1 font-weight-bold">Información de envío</h6>
              <div class="row align-items-center mb-3">
                <div class="col-6 col-md-6 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Nombre:
                  </h6>
                  <h6 class="subtitle-1">John Doe</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Teléfono:
                  </h6>
                  <h6 class="subtitle-1">81 1020 3040</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Email:
                  </h6>
                  <h6 class="subtitle-1">correo@gmail.com</h6>
                </div>
              </div>

              <div class="row align-items-center mb-3">
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    País:
                  </h6>
                  <h6 class="subtitle-1">México</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Estado:
                  </h6>
                  <h6 class="subtitle-1">Nuevo León</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Ciudad:
                  </h6>
                  <h6 class="subtitle-1">Monterrey</h6>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <h6 class="subtitle-2 text-muted">
                    Código postal:
                  </h6>
                  <h6 class="subtitle-1">66780</h6>
                </div>
              </div>

              <div class="row align-items-center mb-3">
                <div class="col-md-3">
                  <h6 class="subtitle-2 text-muted">
                    Dirección:
                  </h6>
                  <h6 class="subtitle-1">Calle 19, #600, Los Morales</h6>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <h6 class="subtitle-1 font-weight-bold mb-3">Productos</h6>

              <div class="card card-outline">
                <div class="card-body d-flex justify-content-between align-items-center">
                  <img src="https://via.placeholder.com/150" class="img-fluid" width="100px" alt="">
                  <h6 class="subtitle-1 w-75">Whey Vainila 2kg<span class="text-muted">#0001</span></h6>
                  <h6 class="subtitle-2">x2</h6>
                  <h6 class="subtitle-2">$1,000.00</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";

export default {
  metaInfo: {
    title: 'Resumen del pedido',
  },
  components: {Navbar, Sidebar,},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Productos',
        },
        {
          text: 'Pedidos',
        },
        {
          text: 'Resumen del pedido',
        },
      ]
    }
  },
}
</script>