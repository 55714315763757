<template>
  <b-container>
    <b-form-group label="Paquetería">
      <b-input :value="order.deliveryCompany" disabled/>
    </b-form-group>
    <b-form-group label="Código de Rastreo">
      <b-input :value="order.deliveryCode" disabled/>
    </b-form-group>
    <b-form-group label="Paquetería de Guía">
      <b-input :value="order.deliveryGuideNumber" disabled/>
    </b-form-group>
  </b-container>
</template>

<script>
export default {
  name: "OrderGuide",
  props: {
    product: Object,
    order: Object,
    productId: String,
    orderId: String
  }
}
</script>

<style scoped>

</style>