<template>
  <!-- CARD -->
  <div class="card card-outline">
    <div class="card-header mt-2">
      <h6 class="subtitle-1 text-uppercase">Núcleo</h6>
      <h6>Integrantes</h6>
    </div>

    <div class="card-body" v-if="isLoading">
      Cargando nucleo...
    </div>

    <div class="card-body" v-else>
      <div class="row">
                <div class="col text-center"
                     style="width: 128px;"
                     v-for="coreUser in people"
                     :key="coreUser.id">
                  <b-avatar :text="getAvatarAcronym(coreUser.display_name, coreUser.last_name)"
                            :class="[!coreUser.has_completed_purchase_goal ? 'border-danger' : 'border-success',
                                      'shadow-4dp', 'mb-3']"
                            size="5rem" />
<!--                  <h6 class="subtitle-2 text-wrap">Nivel {{ coreUser.level }}</h6>-->
                  <h6 class="subtitle-1 mx-1 text-wrap" v-text="`${coreUser.display_name} ${coreUser.last_name}`"></h6>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
  props: ['coreNumber'],
  mounted() {
    this.fetchCorePeople()
  },
  data() {
    return {
      people: [],
      isLoading: false
    }
  },
  methods: {
    fetchCorePeople() {
      this.isLoading = true
      const getCoreUsers = firebase.functions().httpsCallable('getCoreUsers')
      getCoreUsers({coreNumber: this.coreNumber})
          .then(response => response.data)
          .then(data => this.people = data)
          .then(() => console.debug('[InvitedPeople.vue]', `Fetched core users for core #${this.coreNumber}`, this.people))
          .finally(() => this.isLoading = false)
    },
    getAvatarAcronym(name, lastName) {
      if (!name || !lastName) {
        return ''
      }

      return name.charAt(0) + lastName.charAt(0);
    }
  }
}
</script>