<template>
  <b-card class="card-outline">
    <template #header>
      <h6 class="font-weight-bold">Direcciones de Envío</h6>
      <div class="d-flex justify-content-between">
        <h6 class="subtitle-1 text-primary">Direcciones guardadas</h6>
        <b-button variant="link"
                  class="ripple ripple-dark m-0"
                  @click="openModal(null)">
          <i class="material-icons">add</i>
          Agregar
        </b-button>
      </div>
      <!-- MODAL ADD ADDRESS -->
      <modal-add-address :show.sync="modalAddAddress"
                         :address-id.sync="modalAddressId"
                         :supplier-id="supplierId"
                         @saved="refreshAddress"/>
    </template>

    <b-row>
      <address-item v-for="address in addresses"
                    v-model="address.data"
                    :key="address.id">
        <b-row class="mt-3">
          <b-col>
            <b-button variant="link"
                      @click="openModal(address.id)"
                      size="sm"
                      pill>
              Editar
            </b-button>
          </b-col>
          <b-col>
            <b-button variant="link"
                      size="sm"
                      class="float-right"
                      @click="openDeleteModal(address.id, address.data)"
                      pill>
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </address-item>
    </b-row>

    <b-modal v-model="modalDelete.show"
             title="¿Quieres eliminar la dirección?"
             cancel-variant="link btn-round ripple"
             cancel-title="Cancelar"
             ok-variant="primary btn-round ripple"
             ok-title="Eliminar"
             @ok="deleteAddress">
      <address-item v-model="modalDelete.data"
                    :address-id="modalDelete.id"
                    lg="12"
                    md="12"/>
    </b-modal>
  </b-card>
</template>

<script>
import ModalAddAddress from "@/components/profile/ModalAddAddress";
import AddressItem from "@/components/checkout/components/AddressItem";
import firebase from "firebase";

export default {
  name: "FormSuppliersAddress",
  components: {AddressItem, ModalAddAddress },
  props: {
    supplierId: String
  },
  data() {
    return {
      modalAddAddress: false,
      modalAddressId: null,
      addresses: [],
      modalDelete: {
        show: false,
        data: {},
        id: null
      },
    }
  },
  mounted() {
    if (this.supplierId) {
      this.getAddresses()
    }
  },

  methods: {
    selectAddress(id, data) {
      this.$emit('selected', {id, ...data})
    },
    async getAddresses() {
      let addresses = await firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('addresses')
          .get()

      this.addresses = []
      addresses.forEach(a => {
        this.addresses.push({
          id: a.id,
          doc: a,
          data: a.data()
        })
      })
    },
    refreshAddress() {
      this.getAddresses()
    },
    openModal(id = null) {
      this.modalAddAddress = true
      this.modalAddressId = id
    },
    openDeleteModal(id, data) {
      this.modalDelete.show = true
      this.modalDelete.id = id
      this.modalDelete.data = data
    },
    async deleteAddress() {
      let address = firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('addresses')
          .doc(this.modalDelete.id)

      await address.delete()

      this.modalDelete.show = false
      this.modalDelete.id = null
      this.modalDelete.data = {}

      this.refreshAddress()

      this.$bvToast.toast('Dirección eliminada', {
        title: 'Eliminado',
        autoHideDelay: 6500,
        variant: 'primary',
      });
    }
  },
}
</script>

<style scoped>

</style>