<template>
  <div class="bs-stepper" ref="stepper">
    <div class="bs-stepper-header" role="tablist">

      <bs-stepper-step stepper-id="stepper-packaging-view"
                       button-icon="fact_check"
                       label="Empaquetado"/>

      <bs-stepper-step stepper-id="stepper-address-view"
                       button-icon="house"
                       label="Dirección"/>

      <bs-stepper-step stepper-id="stepper-shipping-view"
                       button-icon="local_shipping"
                       label="Envío de Empaquetado"/>

      <bs-stepper-step stepper-id="stepper-sent-view"
                       button-icon="near_me"
                       label="En la paquetería"/>
    </div>

    <div class="bs-stepper-content">
      <div id="stepper-packaging-view"
           aria-labelledby="stepper-packaging-view-trigger"
           class="content"
           role="tabpanel">
        <packaging-step :order="order"
                        :assigned-member.sync="order.assignedMember"
                        :assigned-member-uid.sync="order.assignedMemberUID"
                        :user-list="userOptions"
                        @next="saveAssignedMember"/>
      </div>

      <div id="stepper-address-view"
           aria-labelledby="stepper-address-view-trigger"
           class="content"
           role="tabpanel">
        <address-step :order="order" :supplier-id="supplierId" @save="saveAddress"/>
      </div>

      <div id="stepper-shipping-view"
           aria-labelledby="stepper-shipping-view-trigger"
           class="content"
           role="tabpanel">
        <shipping-step :order="order"
                       :order-id="orderId"
                       :products="products"
                       :delivery-code.sync="order.deliveryCode"
                       :delivery-company.sync="order.deliveryCompany"
                       :delivery-guide-number.sync="order.deliveryGuideNumber"
                       :supplier-id="supplierId" @next="saveDeliveryShippingData"/>
      </div>

      <div id="stepper-sent-view"
           aria-labelledby="stepper-sent-view-trigger"
           class="content"
           role="tabpanel">
        <sent-step />
      </div>
    </div>
  </div>
</template>

<script>
import Stepper from "bs-stepper";
import firebase from "firebase/app";
import BsStepperStep from "@/components/share/bsStepper/bsStepperStep";
import PackagingStep from "@/components/admin/orders/process/PackagingStep";
import ShippingStep from "@/components/admin/orders/process/ShippingStep";
import SentStep from "@/components/admin/orders/process/SentStep";
import AddressStep from "@/components/admin/orders/process/AddressStep";

export default {
  name: "PackageDeliveryProcess",
  components: {
    AddressStep,
    SentStep,
    ShippingStep,
    PackagingStep,
    BsStepperStep
  },
  props: {
    value: Object,
    supplierId: String,
    orderId: String,
    order: Object,
    products: Array,
  },
  data () {
    return {
      stepper: null,
      firstStep: {
        assignedMember: ''
      },
      secondStep: {
        deliveryCompany: '',
        deliveryCode: ''
      },
      userOptions: []
    }
  },
  mounted() {
    this.getUsers()
    this.stepper = new Stepper(this.$refs.stepper);
    this.stepper.to(this.order.deliveryStatus)
  },
  methods: {
    async getUsers() {
      let db = firebase.firestore()
      let users = await db.collection('admin_users')
          .where('roleUID', 'in', ['4', '5'])
          .where('suppliersUID', '==', this.supplierId)
          .where('status', '==', 1)
          .get()

      let list = []
      users.forEach(u => {
        let data = u.data()
        list.push({
          value: u.id,
          text: data.displayName
        })
      })
      this.userOptions = list
    },
    async saveAssignedMember(assignedMember, assignedMemberUid) {
      let snap = firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')
          .doc(this.orderId)

      let userId = this.order.userId

      let userSnap =  firebase.firestore()
          .collection('users')
          .doc(userId)
          .collection('orders')
          .doc(this.orderId)

      let deliveryStatus = this.order.deliveryStatus
      deliveryStatus = deliveryStatus > 1 ? deliveryStatus : 1

      await snap.update({
        assignedMember: assignedMember,
        assignedMemberUID: assignedMemberUid,
        deliveryStatus: deliveryStatus,
      })

      await userSnap.update({
        assignedMember: assignedMember,
        assignedMemberUID: assignedMemberUid,
        deliveryStatus: deliveryStatus,
      })

      let order = await snap.get()
      this.$emit('update:order', order.data())
      this.stepper.reset()
      this.stepper.next()
    },
    async saveDeliveryShippingData(code, company, guideNumber) {
      let snap = firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')
          .doc(this.orderId)

      let userId = this.order.userId

      let userSnap =  firebase.firestore()
          .collection('users')
          .doc(userId)
          .collection('orders')
          .doc(this.orderId)

      let deliveryStatus = this.order.deliveryStatus
      deliveryStatus = deliveryStatus > 2 ? deliveryStatus : 2

      await snap.update({
        deliveryCode: code,
        deliveryCompany: company,
        deliveryGuideNumber: guideNumber,
        deliveryStatus: deliveryStatus,
      })

      await userSnap.update({
        deliveryCode: code,
        deliveryCompany: company,
        deliveryGuideNumber: guideNumber,
        deliveryStatus: deliveryStatus,
      })

      let order = await snap.get()
      this.$emit('update:order', order.data())
    },
    async saveAddress(address) {
      console.log('address select', address)
      let snap = firebase.firestore()
          .collection('suppliers')
          .doc(this.supplierId)
          .collection('orders')
          .doc(this.orderId)

      let userId = this.order.userId

      let userSnap =  firebase.firestore()
          .collection('users')
          .doc(userId)
          .collection('orders')
          .doc(this.orderId)

      await snap.update({
        emitterAddress: address
      })

      await userSnap.update({
        emitterAddress: address
      })

      let order = this.value
      order.emitterAddress = address
      this.$emit('input', order)
      this.changePosition(this.statusPosition)
    },
    changePosition(point) {
      this.stepper.reset()
      for (let i = 0; i < point; i++) {
        this.stepper.next()
      }

      if (point >= 1 && this.hasEmitterAddress) {
        this.stepper.next()
      }
    }
  },
  computed: {
    firstStepCheck() {
      return this.assignedMember.length > 2
    },
    statusPosition() {
      return this.order ? this.order.deliveryStatus : 0
    },
    hasEmitterAddress() {
      return this.order.emitterAddress && Object.keys(this.order.emitterAddress).length > 0
    }
  },
  watch: {
    statusPosition(newVal) {
      this.changePosition(newVal)
    }
  }
}
</script>

<style scoped>

</style>
