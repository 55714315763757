<template>
  <div class="rounded">
    <div v-for="(product, index) in products"
         :key="product.id">
      <hr v-if="index !== 0">
      <b-row>
        <b-col md="3" lg="2">
          <product-image-hero :value="getImage(product)"/>
        </b-col>
        <b-col md="5" lg="6">
          <h5 v-text="product.data.name"></h5>
          <p class="subtitle text-wrap" v-if="product.data.details" v-text="getDescription(product.data.details)"></p>
          <p class="subtitle text-wrap" v-else v-text="getDescription(product.data.description)"></p>
        </b-col>
        <b-col md="4">
          <p>Cantidad: {{ product.data.count }}</p>
          <format-price :value="product.data.finalTotalAmount" class="font-weight-bold"></format-price>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProductImageHero from "@/components/product/ProductImageHero";
import FormatPrice from "@/components/share/formatPrice";
export default {
  name: "PackageProductList",
  components: {FormatPrice, ProductImageHero},
  props: {
    products: Array
  },
  methods: {
    getDescription(description) {
      return description.length > 240 ? description.substring(0, 240) + '...' : description
    },
    getImage(product) {
      if (product.data.photoURL) {
        return product.data.photoURL
      } else if (product.data.images) {
        return product.data.images[0]
      } else return null
    }
  },
}
</script>

<style scoped>

</style>