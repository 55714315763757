<template>
<div class="justify-content-center">
  <b-card class="card-outline" header-class="my-2">
    <template #header>
      <b-row>
        <b-col md="6" lg="4" class="mb-3">
          <div class="d-flex align-items-center">
            <i class="material-icons-outlined mr-2">search</i>
            <b-form-input v-model="table.filter"
                          class="form-control-flat"
                          type="search"
                          placeholder="Buscar por título, marca, categoría, detalles u otro campo"
                          lazy/>
          </div>
        </b-col>
        <b-col md="6" lg="8" class="text-right">
          <router-link :to="{ name: 'CatalogGroupsCreate' }" class="btn btn-primary">
            <i class="material-icons-outlined">add</i>
            Agregar
          </router-link>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col md="12">
        <b-table class="text-wrap"
                 :items="getProductsGroup"
                 :fields="table.fields"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc"
                 :per-page.sync="table.perPage"
                 :current-page.sync="table.currentPage"
                 :busy.sync="table.busy"
                 responsive="md"
                 hover
                 borderless
                 small>
          <template v-slot:cell(name)="d">
            <p v-text="d.item.data.name" class="text-wrap"/>
          </template>
          <template v-slot:cell(description)="d">
            <p v-text="d.item.data.description" class="text-wrap"/>
          </template>
          <template v-slot:cell(counts)="d">
            <p v-text="d.item.data.counts" class="text-wrap"/>
          </template>
          <template v-slot:cell(variants)="d">
            <p v-if="Array.isArray(d.item.data.filters)"
               v-text="d.item.data.filters.map(f => ' '+f.name).toLocaleString()"
               class="text-wrap"/>
          </template>
          <template v-slot:cell(options)="d">
            <router-link :to="{ name: 'CatalogGroupsUpdate', params: { id: d.item.id } }"
                         variant="link"
                         class="ripple ripple-dark"
                         v-b-tooltip.hover
                         title="Editar">
              <i class="material-icons-outlined">edit</i>
            </router-link>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-button variant="light" :disabled="table.currentPage === 1" @click="table.currentPage--">
      <i class="material-icons-outlined">arrow_back</i> Anterior
    </b-button>
    <b-button variant="light" :disabled="isCurrentPageLastPage" @click="table.currentPage++">
      Siguiente <i class="material-icons-outlined">arrow_forward</i>
    </b-button>

  </b-card>
</div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "TableProductsGroup",
  data() {
    return {
      searchText: '',
      productsGroups: [],
      table: {
        fields: [
          { key: 'id', label: 'UID'},
          { key: 'name', label: 'Nombre', sortable: true },
          { key: 'description', label: 'Descripción', sortable: true },
          { key: 'counts', label: 'Conteo', sortable: true },
          { key: 'variants', label: 'Variantes' },
          { key: 'options', label: 'Acciones'},

        ],
        sortBy: 'name',
        sortDesc: false,
        perPage: 10,
        currentPage: 1,
        filter: '',
        busy: false
      },
      startAfterDocsList: []
    }
  },
  methods: {
    getProductsGroup() {
      let query = firebase.firestore().collection('products_groups')
          .limit(this.table.perPage)
          .orderBy(this.table.sortBy, this.table.sortDesc ? 'desc' : 'asc')

      if (this.table.filter !== '')
        query = query.where('filterWords', 'array-contains-any', this.filter.split(' '))

      if (this.table.currentPage > 1) {
        if (this.startAfterDocsList[this.table.currentPage] == null)
          this.startAfterDocsList[this.table.currentPage] = this.productsGroups[this.productsGroups.length - 1].doc;
        query = query.startAfter(this.startAfterDocsList[this.table.currentPage])
      } else this.startAfterDocsList = []

      let pgArray = [];
      return query.get().then(pg => {
        pg.forEach(p => {
          pgArray.push({
            id: p.id,
            data: p.data(),
            doc: p
          })
        })

        this.productsGroups = pgArray;
        return pgArray;
      }).catch((error) => {
        console.log(error)
        this.productsGroups = [];
        return []
      })
    },
  },
  computed: {
    isCurrentPageLastPage() {
      return this.productsGroups.length < this.table.perPage;
    }
  }
}
</script>

<style scoped>

</style>