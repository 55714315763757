<template>
  <component :is="tag" v-text="formatPrice(value)">

  </component>
</template>

<script>
export default {
  name: "formatPrice",
  props: {
    value: [String, Number],
    tag: {
      type: String,
      default: 'p'
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
  }
}
</script>

<style scoped>

</style>