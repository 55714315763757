<template>
<b-card class="card-outline">

  <b-row>
    <b-col cols="12">
      <b-table :fields="tableFields" :items="products" responsive="lg" small>
        <template v-slot:cell(uid)="d">
          <p v-text="d.item.id"></p>
        </template>
        <template v-slot:cell(title)="d">
          <p v-text="d.item.data.title" class="text-wrap"></p>
        </template>
        <template v-slot:cell(cost)="d">
          <format-price :value="d.item.data.cost" />
        </template>
        <template v-slot:cell(price)="d">
          <format-price :value="d.item.data.price" />
        </template>
        <template v-slot:cell(options)="data">
          <router-link :to="{ name: 'CatalogProductEdit', params: {id: data.item.id} }"
                       variant="link"
                       class="ripple ripple-dark"
                       v-b-tooltip.hover
                       title="Editar">
            <i class="material-icons">edit</i>
          </router-link>
          <b-button variant="link"
                    @click="openEditModal(data.item)"
                    class="ripple ripple-dark"
                    v-b-tooltip.hover
                    title="Editar">
            <i class="material-icons">filter_alt</i>
          </b-button>
        </template>

        <template v-slot:cell()="d">
          <p v-text="d.item.data.filters[d.field.key]" class="text-wrap"></p>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12" class="text-right">
      <b-button variant="secondary-outline"
                @click="openCreateModal(true)"
                size="sm">
        <i class="material-icons-outlined">add</i>Agregar Producto
      </b-button>
      <b-button variant="secondary-outline"
                @click="openCreateModal(false)"
                size="sm">
        <i class="material-icons-outlined">add_link</i>Vicular Producto
      </b-button>
    </b-col>
  </b-row>

  <template #footer>
    <div class="text-right">
      <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
        <i class="material-icons-outlined">arrow_back</i>Regresar
      </b-button>
    </div>
  </template>

  <b-modal v-model="modal.show"
           :title="modalFormTitle"
           cancel-variant="link"
           cancel-title="Cancelar"
           ok-variant="primary"
           :ok-title="modalFormOkTitle"
           @ok="saveProduct">
    <b-form-group v-for="filter in modal.filters"
                  :key="filter.name"
                  :label="filter.name">
      <b-form-input :list="'form-modal-pg-filter-list-'+filter.name"
                    v-model="modal.data[filter.name]"/>
      <datalist :id="'form-modal-pg-filter-list-'+filter.name">
        <option v-for="opt in filter.options"
                :key="opt"
                :value="opt"/>
      </datalist>
    </b-form-group>
    <b-form-input v-if="modal.addFilterOn"
                  placeholder="Filtro"
                  @blur="addFilter"
                  v-model="modal.filterOpt"
                  autofocus/>
    <div class="text-right mt-3">
      <b-button variant="secondary-outline"
                @click="modal.addFilterOn = true"
                size="sm">Agregar Filtro</b-button>
    </div>
  </b-modal>

  <b-modal v-model="productsModal.show"
           title="Productos"
           cancel-variant="link"
           cancel-title="Cancelar"
           ok-variant="primary"
           :ok-disabled="productModalOkDisabled"
           ok-title="Vincular"
           @cancel="closeProductModal"
           @ok="addExistingProduct"
           size="xl">
    <table-products @row-selected="productModalSelected"
                    ref="tableProducts"
                    without-product-group
                    with-minimum-fields
                    :per-page="5"
                    selectable />
  </b-modal>
</b-card>
</template>

<script>
import firebase from "firebase/app";
import FormatPrice from "@/components/share/formatPrice";
import TableProducts from "@/components/admin/products/TableProducts";

export default {
  name: "FormProductsGroupList",
  components: {TableProducts, FormatPrice},
  props: {
    value: Object,
    id: String
  },
  data() {
    return {
      normalFields: [
        { key: 'uid', label: 'UID de Producto'},
        { key: 'title', label: 'Título' },
        { key: 'cost', label: 'Costo' },
        { key: 'price', label: 'Precio' },
      ],
      products: [],
      modal: {
        show: false,
        title: '',
        data: {},
        addFilterOn: false,
        filterOpt: '',
        filters: [],
        productUID: null,
        newProduct: false
      },
      productsModal: {
        show: false,
        product: {},
      }
    }
  },
  mounted() {
    this.getProductsList()
  },
  methods: {
    async getProductsList() {
      let productsList = await firebase.firestore()
          .collection('products_groups')
          .doc(this.id)
          .collection('products')
          .get();

      this.products = []

      productsList.forEach(p => {
        this.products.push({
          id: p.id,
          data: p.data(),
          doc: p
        })
      })

      this.generateFilters()
    },
    openCreateModal(isNew = false) {
      this.modal.newProduct = isNew
      this.modal.show = true;
      this.modal.data = {};
      this.modal.productUID = null;
      this.modal.filters = this.value.filters.map(f => {
        return {
          name: f.name,
          options: f.options.map(o => o)
        }
      });
    },
    openEditModal(d) {
      this.modal.newProduct = false
      this.modal.title = 'Editar Filtros de Producto';
      this.modal.show = true;
      this.modal.data = d.data.filters ;
      this.modal.productUID = d.id;
      this.modal.filters = this.value.filters.map(f => {
        return {
          name: f.name,
          options: f.options.map(o => o)
        }
      });
    },
    addFilter() {
      if (this.modal.filterOpt != null && this.modal.filterOpt !== '') {
        if (!this.modal.filters.find(f => f.name === this.modal.filterOpt)) {
          this.modal.filters.push({
            name: this.modal.filterOpt,
            options: []
          })
        }
      }
      this.modal.addFilterOn = false;
      this.modal.filterOpt = ''
    },
    addExistingProduct() {
      if (Object.keys(this.modal.filters).length === 0) {
        return;
      }
      if (this.modal.productUID) {
        firebase.firestore()
            .collection('products_groups')
            .doc(this.id)
            .collection('products')
            .doc(this.modal.productUID)
            .set({
              filters: this.modal.data,
              brand: this.productsModal.product.brand ?? '',
              brand_label: this.productsModal.product.brand_label ?? '',
              category: this.productsModal.product.category ?? '',
              category_label: this.productsModal.product.category_label ?? '',
              cost: this.productsModal.product.cost ?? '',
              price: this.productsModal.product.price ?? '',
              title: this.productsModal.product.title ?? '',
            }, { merge: true })

        firebase.firestore()
            .collection('products')
            .doc(this.modal.productUID)
            .update({
              productsGroupFilters: this.modal.data,
              productsGroupUID: this.id,
            })

        firebase.firestore()
            .collection('products_groups')
            .doc(this.id).update({
          filters: this.modal.filters
        })

        this.getProductsList()
      }
    },
    saveProduct() {
      if (Object.keys(this.modal.filters).length === 0) {
        return;
      }
      if (this.modal.productUID) {
        firebase.firestore()
            .collection('products_groups')
            .doc(this.id)
            .collection('products')
            .doc(this.modal.productUID)
            .update({
          filters: this.modal.data
        })

        firebase.firestore()
            .collection('products')
            .doc(this.modal.productUID)
            .update({
              productsGroupFilters: this.modal.data
          })

        firebase.firestore()
            .collection('products_groups')
            .doc(this.id).update({
          filters: this.modal.filters
        })
      } else {

        if (this.modal.newProduct) {
          this.$router.push({
            name: 'CatalogProduct',
            query: {
              productsGroupUID: this.id,
              productsGroupFilter: JSON.stringify(this.modal.data)
            }
          })
        } else {
          this.productsModal.show = true
        }
      }
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      console.log(formatter.format(price))
      return formatter.format(price)
    },
    closeProductModal() {
      this.modal.productUID = null
      this.productsModal.product = {}
      this.$refs.tableProducts.clearSelection()
    },
    productModalSelected(rows) {
      if (rows.length > 0) {
        this.modal.productUID = rows[0].id
        this.productsModal.product = rows[0]
      } else {
        this.modal.productUID = null
        this.productsModal.product = {}
      }
    },
    generateFilters() {
      let filters = []

      this.products.forEach(product => {

        let keys = Object.keys(product.data.filters)

        keys.forEach(key => {
          let i = filters.findIndex(x => x.name === key)
          if (i === -1) {
            filters.push({
              name: key,
              options: [product.data.filters[key]]
            })
          } else {
            let ii = filters[i].options.findIndex(x => x === product.data.filters[key])
            if (ii === -1) {
              filters[i].options.push(product.data.filters[key])
            }
          }
        })
      })

      firebase.firestore()
          .collection('products_groups')
          .doc(this.id)
          .update({
            filters: filters,
            counts: this.products.length
          })
    }
  },
  computed: {
    tableFields() {
      let fields = JSON.parse(JSON.stringify(this.normalFields));
      this.value.filters.forEach(f => {
        fields.push({
          key: f.name,
          variant: 'secondary'
        })
      })
      fields.push({ key: 'options', label: 'Acciones' })
      return fields;
    },
    modalFormOkTitle() {
      return this.modal.newProduct ? 'Guardar y Continuar' : 'Guardar y Vincular'
    },
    modalFormTitle() {
      return this.modal.newProduct ? 'Agregar Nuevo Producto' : 'Agregar Producto Existente'
    },
    productModalOkDisabled() {
      return this.modal.productUID === '' || this.modal.productUID == null
    }
  }
}
</script>

<style scoped>
/deep/ .table-secondary {
  background-color: #ffcc8e !important;
}
</style>