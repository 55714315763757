<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card class="card-outline">
          <b-row>
            <b-col md="12">
              <b-form-group
                  label="Nombre"
                  :validated="isNameValid"
                  invalid-feedback="El nombre debe tener al menos 3 letras"
                  label-for="input-name">
                <b-form-input id="input-name"
                              v-model="value.name"
                              class="form-control-border-bottom"/>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Descripción"
                  label-for="input-description">
                <b-form-textarea id="input-description"
                                 v-model="value.description"
                                 class="form-control-border-bottom"/>
              </b-form-group>
            </b-col>
          </b-row>

          <template #footer>
            <div class="text-right">
              <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
                <i class="material-icons-outlined">arrow_back</i>Regresar
              </b-button>
              <b-button variant="primary"
                        :disabled="!(isNameValid)"
                        @click="submit"
                        class="btn-round ripple mt-3 px-5">Guardar</b-button>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FormCategoryInfo",
  props: {
    value: Object,
    categoryId: String
  },
  methods: {
    submit() {
      this.$emit('save', this.value);
    }
  },
  computed: {
    isNameValid: function () {
      return this.value.name ? (this.value.name.replaceAll(' ', '')).length > 2 : false;
    }
  }
}
</script>

<style scoped>

</style>