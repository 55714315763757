<template>
  <!-- MODAL ADD CARDS -->
  <b-modal @change="$emit('update:show', $event)"
           :title="modalTitle"
           title-class="font-weight-bold"
           :visible="show"
           size="lg"
           ok-title="Guardar"
           ok-variant="primary btn-round ripple"
           :ok-disabled="disabledOkBtn"
           @ok="save"
           cancel-title="Cancelar"
           cancel-variant="link btn-round ripple"
           @cancel="cancel"
           centered>
    <b-row>
      <b-col md="12">
        <b-form-group label="Pais o Región"
                      :validated="validCountry">
          <b-form-input placeholder="País"
                        v-model="address.data.country"
                        disabled/>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Nombre completo (Nombre y apellidos)"
                      :validated="validFullName">
          <b-form-input v-model="address.data.fullName"/>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Número de télefono"
                      :validated="validPhoneNumber">
          <b-form-input v-model="address.data.phoneNumber"/>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Dirección" :validated="validFirstAddress">
          <b-form-input v-model="address.data.firstAddress"
                        :validated="validFirstAddress"
                        placeholder="Nombre de la calle y colonia"/>
          <b-form-input v-model="address.data.secondAddress"
                        placeholder="Depto., unidad, edificio, piso, etc."
                        class="mt-2"/>
        </b-form-group>
      </b-col>
      <b-col md="12" lg="4">
        <b-form-group label="Ciudad"
                      :validated="validCity">
          <b-form-input v-model="address.data.city"
                        :validated="validCity"/>
        </b-form-group>
      </b-col>
      <b-col md="12" lg="4">
        <b-form-group label="Estado"
                      :validated="validState">
          <b-form-input v-model="address.data.state"
                        :validated="validState"/>
        </b-form-group>
      </b-col>
      <b-col md="12" lg="4">
        <b-form-group label="Código Postal"
                      :validated="validZipCode">
          <b-form-input v-model="address.data.zipCode"
                        :validated="validZipCode"
                        type="number"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <hr class="w-100">
      </b-col>

      <b-col md="12"><h5 class="mb-3 mt-2">Agregar instrucciones de entrega</h5></b-col>

      <b-col md="12">
        <b-form-group label="¿Necesitamos indicaciones adicionales para encontrar esta dirección?">
          <b-form-textarea placeholder="Proporcionamos detalles como la descripción del edificio, un punto de referencia cercano o alguna otra seña para llegar"
                           v-model="address.data.extraIndications"
                           rows="4"/>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="¿Necesitamos un código de seguridad o un número de teléfono para acceder a este edificio?">
          <b-form-input placeholder="1234" v-model="address.data.extraCodeNumber"/>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import firebase from 'firebase/app'
import {mapGetters} from "vuex";

export default {
  name: 'ModalAddAddress',
  components: {

  },
  props: {
    show: Boolean,
    addressId: String,
    supplierId: {
      default: '',
      type: String
    },
  },
  data() {
    return {
      address: {
        data: {
          country: 'México'
        },
        doc: null
      }
    }
  },
  mounted() {
    if (this.addressId) {
      this.getAddress()
    }
  },
  methods: {
    async save() {
      let db = this.supplierId === ''
          ? firebase.firestore()
              .collection('users')
              .doc(this.userId)
              .collection('addresses')
          : firebase.firestore()
              .collection('suppliers')
              .doc(this.supplierId)
              .collection('addresses')

      let doc = this.addressId ? db.doc(this.addressId) : db.doc()

      if (this.addressId) await doc.update(this.address.data)
      else await doc.set(this.address.data)

      this.$emit('saved', this.address.id, this.address.data)
      this.$emit('update:show', false)

      this.$bvToast.toast('Dirección guardada', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
      });
    },
    async cancel() {
      this.address.data = {country: 'México'}
      this.address.doc = null
      this.$emit('update:addressId', null)
    },
    async getAddress() {
      let address = this.supplierId === ''
          ? await firebase.firestore()
              .collection('users')
              .doc(this.userId)
              .collection('addresses')
              .doc(this.addressId)
              .get()
          : await firebase.firestore()
              .collection('suppliers')
              .doc(this.supplierId)
              .collection('addresses')
              .doc(this.addressId)
              .get()

      if (address.exists) {
        this.address.data = address.data()
        this.address.doc = address
      } else {
        this.$emit('update:addressId', null)
        this.address.data = {country: 'México'}
        this.address.doc = null
      }
    }
  },
  computed: {
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
    modalTitle() {
      return this.address.doc == null ? 'Agregar dirección' : 'Editar dirección'
    },
    validCountry() {
      let x = this.address.data.country
      if (x == null) return false
      return x.length > 2
    },
    validFullName() {
      let x = this.address.data.fullName
      if (x == null) return false
      return x.length > 2
    },
    validPhoneNumber() {
      let phone = this.address.data.phoneNumber
      if (phone == null) return false
      const r = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
      return r.test(phone);
    },
    validFirstAddress() {
      let x = this.address.data.firstAddress
      if (x == null) return false
      return x.length > 2
    },
    validCity() {
      let x = this.address.data.city
      if (x == null) return false
      return x.length > 2
    },
    validState() {
      let x = this.address.data.state
      if (x == null) return false
      return x.length > 2
    },
    validZipCode() {
      let zip = this.address.data.zipCode
      if (zip == null) return false
      const r = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return r.test(zip);
    },
    disabledOkBtn() {
      return !(this.validFullName
          && this.validPhoneNumber
          && this.validFirstAddress
          && this.validCity
          && this.validState
          && this.validZipCode)
    },
    userId() {
      let u = this.user
      if (u == null) return null
      return u.data.uid;
    }
  },
  watch: {
    addressId(newVal) {
      if (newVal) {
        this.getAddress()
      } else {
        this.address.data = {country: 'México'}
        this.address.doc = null
      }
    }
  }
}
</script>