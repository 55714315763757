<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
      </b-row>
      <!-- TABLE -->
      <b-row class="mb-3">
        <b-col md="12">
          <TableUsers></TableUsers>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Navbar from "../../../components/admin/includes/Navbar";
import Sidebar from "../../../components/admin/includes/Sidebar";
import TableUsers from "../../../components/admin/users/TableUsers";

export default {
  metaInfo: {
    title: 'Usuarios',
  },
  components: {Sidebar, Navbar, TableUsers},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Usuarios',
        }
      ],
    }
  }
}
</script>