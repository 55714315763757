<template>
  <b-aspect
      aspect="1:1"
      :style="{
          backgroundImage: 'url('+value+')',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundColor: 'var(--theme-surface)',
          backgroundSize: 'cover',
          borderRadius: '25px'
            }"/>
</template>

<script>
export default {
  name: "ProductImageHero",
  props: {
    value: String
  }
}
</script>

<style scoped>

</style>