<template>
  <component :is="tag" v-text="formatDateTimeFromTimestamp()"></component>
</template>

<script>
export default {
  name: "formatDateTime",
  components: {},
  props: {
    datetime: Object,
    tag: {
      type: String,
      default: 'p'
    }
  },
  data () {
    return {
      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        // second: '2-digit'
      },
    }
  },
  methods: {
    formatDateTimeFromTimestamp() {
      if (this.date == null) return null;
      return this.date.toLocaleString('es-MX', this.dateOptions);
    }
  },
  computed: {
    date() {
      if (this.datetime instanceof Date) return this.datetime;
      if (typeof this.datetime === 'string') return new Date(this.datetime)
      if (typeof this.datetime === 'number') return new Date(this.datetime)
      if (typeof(this.datetime) == 'object') return this.datetime.toDate()

      return null
    },
  },
}
</script>

<style scoped>

</style>