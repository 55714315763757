<template>
  <b-card class="card-outline" :title="header" title-tag="h5">
    <hr>
    <b-row>
      <b-col md="4" class="border-radius-12 p-2">
        <b-list-group>
          <b-list-group-item v-for="f in filters"
                             :key="f.id"
                             @click="selectFilter(f)"
                             :active="filter != null && f.id === filter.id"
                             button>
            <b-row>
              <b-col md="10">
                {{ f.data().name }}
              </b-col>
              <b-col md="2">
                <b-button class="float-right"
                          variant="link"
                          @click="openFilterModal(f)"
                          size="sm">
                  <i class="material-icons-outlined">edit</i>
                </b-button>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>

        <b-button variant="link"
                  @click="openFilterModal(null)"
                  class="float-right">
          <i class="material-icons-outlined">add</i> Agregar Filtro
        </b-button>

        <b-modal v-model="filterFormModal.visible"
                 title="Filtro"
                 ok-title="Guardar"
                 @ok="saveFilter"
                 cancel-variant="link"
                 cancel-title="Cancelar">
          <b-form-group label="Nombre" :validated="isFilterItemNameValid">
            <b-form-input v-model="filterFormModal.name"/>
          </b-form-group>
        </b-modal>

      </b-col>
      <b-col md="8">
        <b-card v-if="filter != null"
                :title="filter.data().name"
                title-tag="h5"
                class="card-outline"
                header-class="text-center">

          <b-list-group class="border-radius-8">
            <b-list-group-item v-for="fi in filter_items" :key="fi.id">
              <b-row>

                <b-col md="9">
                  <p v-text="fi.data().name"
                     style="font-size: 1rem; justify-content: center"></p>
                </b-col>
                <b-col md="3">
                  <div class="text-right">
                    <b-button variant="link"
                              class="ripple ripple-dark"
                              @click="openFilterItemModal(fi)"
                              size="sm"
                              title="Editar">
                      <i class="material-icons-outlined">edit</i>
                    </b-button>
                    <b-button variant="link"
                              class="ripple ripple-dark"
                              @click="deleteFilterItem(fi)"
                              size="sm"
                              title="Eliminar">
                      <i class="material-icons-outlined">close</i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>

          <b-button variant="link" class="float-right" @click="openFilterItemModal(null)">
            <i class="material-icons-outlined">add</i> Agregar Opción
          </b-button>

          <b-modal v-model="filterItemFormModal.visible"
                   :title="'Opción de filtro para \''+filter.data().name+'\''"
                   ok-title="Guardar"
                   @ok="saveFilterItem"
                   :ok-disabled="!isFilterItemNameValid"
                   cancel-variant="link"
                   cancel-title="Cancelar">
            <b-form-group label="Nombre" :validated="isFilterItemNameValid">
              <b-form-input v-model="filterItemFormModal.name"/>
            </b-form-group>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>

    <template #footer>
      <div class="text-right">
        <b-button variant="light" class="btn-round ripple mr-3" @click="$router.back()">
          <i class="material-icons-outlined">arrow_back</i>Regresar
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "FormCategoryFilters",
  props: {
    categoryId: String,
    subcategoryId: String,
    header: String
  },
  data: () => {
    return {
      filters: [],
      filters_ref: null,
      filter: null,
      filter_items: [],
      filterItemFormModal: {
        visible: false,
        filterItem: null,
        name: ''
      },
      filterFormModal: {
        visible: false,
        name: '',
        filter: null
      }
    }
  },
  mounted() {
    const db = firebase.firestore();
    this.filters_ref = db.collection('categories')
        .doc(this.categoryId)
        .collection('subcategories')
        .doc(this.subcategoryId)
        .collection('filters');
    this.getFilters();
  },
  methods: {
    async getFilters() {
      let filters = await this.filters_ref.orderBy('name').get();
      this.filters = [];
      filters.forEach(f => this.filters.push(f));
    },
    async selectFilter(filter) {
      this.filter = filter;
      let filter_items = await this.filters_ref.doc(filter.id).collection('filter_items').orderBy('name').get();
      this.filter_items = [];
      filter_items.forEach(fi => this.filter_items.push(fi));
      this.$forceUpdate();
    },
    openFilterItemModal(filterItem = null) {
      this.filterItemFormModal.visible = true;
      this.filterItemFormModal.filterItem = filterItem;
      this.filterItemFormModal.name = filterItem == null ? '' : filterItem.data().name;
    },
    openFilterModal(filter = null) {
      this.filterFormModal.visible = true;
      this.filterFormModal.filter = filter;
      this.filterFormModal.name = filter == null ? '' : filter.data().name;
    },
    saveFilterItem() {
      let fi = this.filterItemFormModal.filterItem;
      if (fi) {
        fi.ref.update({name: this.filterItemFormModal.name});
      } else {
        this.filters_ref.doc(this.filter.id).collection('filter_items').doc()
            .set({name: this.filterItemFormModal.name}, true);
      }
      this.selectFilter(this.filter);
    },
    saveFilter() {
      let f = this.filterFormModal.filter;
      f ? f.ref.update({name: this.filterFormModal.name})
          : this.filters_ref.doc().set({name: this.filterFormModal.name}, true);

      this.getFilters();
    },
    deleteFilterItem(fi) {
      fi.ref.delete();
      this.selectFilter(this.filter);
    }
  },
  computed: {
    isFilterItemNameValid: function () {
      return this.filterItemFormModal.name ?
          (this.filterItemFormModal.name.replaceAll(' ', '')).length > 2 : false;
    },
    isFilterNameValid: function () {
      return this.filterItemFormModal.name ?
          (this.filterFormModal.name.replaceAll(' ', '')).length > 2 : false;
    }
  },
}
</script>

<style scoped>

</style>