<template>
  <div>
    <div class="card card-outline mb-3">
      <div class="card-body text-center">
        <img src="@/assets/img/empty-states/payment.svg" class="img-fluid mb-5" width="260px"
             alt="Imagen de pagos">
        <h5 class="font-weight-bold mb-3">
          Próxima fecha de corte: {{ nextCutString }}
        </h5>
        <BProgress :value="30 - days" max="30"></BProgress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['nextCutString'],
  computed: {
    days() {
      const today = new Date()
      const next = new Date(this.nextCutString)
      const diffTime = Math.abs(next - today)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    }
  }
}
</script>