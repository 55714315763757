<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <!-- FILTERS -->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="card card-outline">
            <div class="card-body">
              <div class="form-row align-items-center">
                <b-form-group class="col-md-3"
                              label="Prioridad"
                              label-for="input-priority"
                >
                  <b-form-select v-model="selected" :options="options" id="input-priority"
                                 class="form-control-flat"></b-form-select>
                </b-form-group>
                <b-form-group class="col-md-3"
                              label="Estatus"
                              label-for="input-status"
                >
                  <b-form-select v-model="selected" :options="options" id="input-status"
                                 class="form-control-flat"></b-form-select>
                </b-form-group>
                <b-form-group class="col-md-3"
                              label="Fecha de creación"
                              label-for="input-datepicker"
                >
                  <datetime input-class="form-control form-control-flat"
                            class="theme-datepicker"
                            placeholder="Ej. 05-10-2020" format="dd-MM-yyyy"
                            :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"></datetime>
                </b-form-group>
                <div class="col-md-3 text-md-center">
                  <b-button class="btn-round px-5 ripple" variant="secondary">Filtrar</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12">
          <TableInvoices></TableInvoices>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";
import TableInvoices from "@/components/admin/payment/TableInvoices";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from "luxon";

Settings.defaultLocale = 'es'

export default {
  metaInfo: {
    title: 'Facturas',
  },
  components: {Navbar, Sidebar, Datetime, TableInvoices},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Pagos y Facturas',
        },
        {
          text: 'Facturas',
        },
      ],
      selected: null,
      options: [
        {value: null, text: 'Selecciona una opción'},
        {value: 'a', text: 'Cualquiera'},
      ]
    }
  },
}
</script>