<template>
  <b-row class="justify-content-center">
    <b-col md="12">
      <b-card class="card-outline">
        <b-row>
          <b-col md="12">
            <b-table :fields="fields"
                     :items="discounts.list"
                     size="sm"
                     borderless
                     hover
                     responsive="xl">
              <template #cell(percentage)="data">
                <p class="text-wrap"
                   v-text="formatDiscount(data.item.percentage)"/>
              </template>
              <template #cell(quantity)="data">
                <p class="text-wrap"
                   v-text="data.item.quantity ?  data.item.quantity : 'SIN MÍNIMO REQUERIDO'"/>
              </template>
              <template #cell(period_start)="data">
                <p class="text-wrap"
                   v-text="formatDateTimeFromTimestamp(data.item.period_start)"/>
              </template>
              <template #cell(period_end)="data">
                <p class="text-wrap"
                   v-text="formatDateTimeFromTimestamp(data.item.period_end)"/>
              </template>
              <template #cell(options)="data">
                <b-button variant="link"
                          class="ripple ripple-dark"
                          v-b-tooltip.hover
                          @click="editDiscountModal(data.item.id)"
                          title="Editar">
                  <i class="material-icons-outlined">edit</i>
                </b-button>

                <b-button variant="link"
                          class="ripple ripple-dark"
                          v-b-tooltip.hover
                          @click="deleteDiscountModal(data.item.id, data.item.name)"
                          title="Eliminar">
                  <i class="material-icons-outlined">close</i>
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-col sm="12">
            <b-button variant="secondary-outline" class="float-right" size="sm" @click="addNewDiscountModal">
              <i class="material-icons-outlined mr-2">add</i>Agregar Descuento
            </b-button>
          </b-col>
        </b-row>

        <template #footer>
          <b-button variant="light" class="btn-round ripple mt-3 float-right" @click="$router.back()">
            <i class="material-icons-outlined">arrow_back</i>Regresar
          </b-button>
        </template>
      </b-card>
    </b-col>

    <b-modal v-model="formModal.isVisible"
             title="Descuento"
             hide-footer>
      <discount-form v-model="formModal.discount"
                     :product-id="value"
                     :discount-id="formModal.discountId"
                     @saved="onDiscountSaved"
                     with-save-button/>
    </b-modal>

    <b-modal v-model="deleteModal.isVisible"
             @ok="deleteDiscount"
             title="Eliminar Descuento">
      <p class="my-2" style="font-size: 1rem">¿Quiere eliminar el descuento llamado
        <code v-text="deleteModal.discountName"></code>
        de forma permanente?
      </p>
      <template #modal-ok>
        Eliminar
      </template>
      <template #modal-cancel>
        Cancelar
      </template>
    </b-modal>
  </b-row>
</template>
<script>

import firebase from "firebase";
import DiscountForm from "@/components/admin/products/forms/DiscountForm";


export default {
  name: "FormProductDiscount",
  components: {DiscountForm},
  props: {
    value: String
  },
  data: () => {
    return {
      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
       // second: '2-digit'
      },
      fields: [
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },{
          key: "percentage",
          label: "Descuento",
          sortable: true,
        },{
        //   key: "type",
        //   label: "Tipo",
        //   sortable: true,
        // },{
          key: "code",
          label: "Código",
          sortable: true,
        },{
          key: "quantity",
          label: "Cantidad Mínima aplicable",
          sortable: true,
        },{
          key: "period_start",
          label: "Inicio de Periodo",
          sortable: true,
        },{
          key: "period_end",
          label: "Fin de Periodo",
          sortable: true,
        },{
          key: "options",
          label: ''
        }
      ],
      discount_types: [
          'General', 'Código'
      ],
      discounts: {
        ref: null,
        collection: null,
        list: []
      },
      formModal: {
        isVisible: false,
        discount: {},
        discountId: null
      },
      deleteModal: {
        isVisible: false,
        discountId: null,
        discountName: ''
      },
      db: firebase.firestore(),
    }
  },
  mounted() {
    this.initDiscounts();
  },
  methods: {
    async initDiscounts() {
      this.discounts.ref = this.db.collection('products').doc(this.value).collection('discounts');
      this.discounts.collection = await this.discounts.ref.get();
      this.discounts.list = [];
      this.discounts.collection.forEach(d => {
        let data = d.data();
        data.id = d.id;
        this.discounts.list.push(data);
      });
    },
    addNewDiscountModal() {
      this.formModal.isVisible = true;
      this.formModal.discount = {};
      this.formModal.discountId = null;
    },
    editDiscountModal(discountId) {
      this.formModal.isVisible = true;
      this.formModal.discountId = discountId;
      this.formModal.discount = {};
      this.discounts.ref.doc(discountId).get().then((data) => {
        this.formModal.discount = data.data();
      });
    },
    deleteDiscountModal(id, name) {
      this.deleteModal.isVisible = true;
      this.deleteModal.discountName = name;
      this.deleteModal.discountId = id;
    },
    deleteDiscount() {
      this.discounts.ref.doc(this.deleteModal.discountId).delete();
      this.initDiscounts();
      this.deleteModal.isVisible = false;
    },
    onDiscountSaved() {
      this.$bvToast.toast('Descuento guardado en la base de datos.', {
        title: 'Guardado',
        autoHideDelay: 6500,
        variant: 'primary',
      });

      this.formModal.isVisible = false;
      this.formModal.discountId = null;
      this.formModal.discount = {};

      this.initDiscounts();
    },
    formatDiscount(value) {
      value = Number.parseFloat(value);
      return value.toFixed(2) + '%';
    },
    formatDateTimeFromTimestamp(value) {
      if (value == null) return null;
      return value.toDate().toLocaleString('es-MX', this.dateOptions);
    }
  }
}
</script>

<style scoped>
table.table p {
  font-size: 12px;
}
</style>