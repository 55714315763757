<template>
  <b-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl"
         :class="fullHeight ? 'h-100' : ''">
    <router-link :to="'/store/product/' + value.id"
                 :class="fullHeight ? 'h-100' : ''">
      <div :class="cardClass">
        <product-image-hero v-model="value.photoURL"/>
        <div class="card-body text-center">
          <h6 class="subtitle-1 product-title" v-text="value.title"/>
          <h6 class="subtitle-1 font-weight-bold">{{ formatPrice(priceAfterDiscount) }} MXN</h6>


          <div v-if="normalDiscount" class="subtitle-1 text-muted">
            <div class="badge badge-secondary">-{{ normalDiscount.data.percentage }}%</div>
            <del>${{ value.price }} MXN</del>
          </div>
          <b-badge v-if="hasWholesaleDiscount"
                   variant="info"
                   style="font-size: 0.55rem">DESCUENTOS DE MAYOREO
          </b-badge>
        </div>
      </div>
    </router-link>
  </b-col>
</template>

<script>
import firebase from "firebase/app";
import ProductImageHero from "@/components/product/ProductImageHero";

export default {
  name: "ProductItem",
  components: {ProductImageHero},
  props: {
    value: Object,
    sm: String,
    md: String,
    lg: String,
    xl: String,
    cols: String,
    fullHeight: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      discounts: []
    }
  },
  mounted() {
    this.getDiscounts()
  },
  methods: {
    async getDiscounts() {
      const today = firebase.firestore.Timestamp.now();
      const discounts = await firebase.firestore()
          .collection('products')
          .doc(this.value.id)
          .collection('discounts')
          .where('code', '==', null)
          .get()

      this.discounts = [];
      discounts.forEach(d => {
        let data = d.data()
        if ((data.period_end > today && data.period_start < today)
            || (data.period_end === null && data.period_start === null)) {
          this.discounts.push({
            id: d.id,
            data: data,
            doc: d
          })
        }
      })
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    }
  },
  computed: {
    cardClass() {
      return [
        'card', 'card-product', 'ripple', 'ripple-dark', 'mb-3',
        this.fullHeight ? 'h-100' : ''
      ]
    },
    normalDiscount() {
      let ds = this.discounts.filter(d => d.data.quantity === 1 || d.data.quantity === 0);
      return ds ? ds.sort((a, b) => b.data.percentage - a.data.percentage)[0] : null;
    },
    hasWholesaleDiscount() {
      return this.discounts.findIndex(d => d.data.quantity > 1) > -1
    },
    priceAfterDiscount() {
      let d = this.normalDiscount;
      return d ? this.value.price * ((100 - d.data.percentage) / 100) : this.value.price;
    },
  }
}
</script>

<style scoped>

</style>