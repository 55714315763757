<template>
  <div class="bg-primary">
    <section class="section-login">
      <div class="container container-login">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <!-- LOGO -->
            <img src="../../assets/img/logos/logo-white.png" class="img-fluid mb-5" alt="Logo de Xplits"
                 width="200px">
            <h6 class="text-white font-weight-bold mb-4">Tu nueva cuenta Stripe Express + Xplits</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header mt-2">
                <h6 class="font-weight-bold">Verificación de la cuenta</h6>
              </div>
              <div class="card-body">
                <h6 class="text-roboto font-weight-normal mb-3">
                  No recargues la página. Estamos verificando la cuenta y sincronizando nuestros sistemas.
                  Agradecemos tu paciencia.
                </h6>
<!--                <img src="@/assets/img/stripe/SVG/Powered by Stripe - blurple.svg" class="img-fluid" width="150px"-->
<!--                     alt="Powered by Stripe">-->
                <div class="mt-3 text-center">
                  <b-spinner style="width: 4rem; height: 4rem; margin-bottom: 1rem"
                             label="Estamos configurando tu cuenta Xplits..."
                             variant="primary"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'
import {mapGetters} from "vuex";

export default {
  mounted() {
    if (this.user.data.stripeExpressReady) {
      console.debug("User is stripe express ready, redirecting...", this.user)
      return this.$router.replace({ name: 'Home' })
    }

    this.verifyAccount()
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    async verifyAccount() {
      const checkStripeExpressAccountStatus = firebase.functions().httpsCallable('checkStripeExpressAccountStatus')
      try {
        const response = await checkStripeExpressAccountStatus()

        console.log(response.data.message)

        if (response.data.expressAccountCompleted) {
          const user = this.user
          user.data.stripeExpressReady = response.data.expressAccountCompleted
          await new Promise(r => setTimeout(r, 5000));
          await this.$store.commit('SET_USER', user)
          await this.$router.replace({ name: 'Home' })
        }
      } catch(e) {
        if (e.code) {
          console.error(e.code)
          console.error(e.message)
          console.error(e.details)
        }
      }

    }
  }
}
</script>
<style>
.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  height: 100vh;
}

.container-login {
  margin: auto;
}
</style>