<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container fluid class="mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12">
          <TableOrders></TableOrders>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";
import TableOrders from "@/components/admin/orders/TableOrders";

export default {
  metaInfo: {
    title: 'Pedidos',
  },
  components: {TableOrders, Navbar, Sidebar,},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Pedidos',
        },
      ],
    }
  },
}
</script>