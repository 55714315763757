<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <b-card class="card-outline">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <b-row>
            <b-form-group class="col-md-12"
                          label="Marca"
                          label-for="input-brand">
              <v-select :options="companies"
                        label="text"
                        id="input-brand"
                        class="form-control-border-bottom"
                        :reduce="brand => brand.value"
                        :disabled="productsGroupEnabled"
                        v-model="product.brand"/>

            </b-form-group>

            <b-form-group class="col-md-12"
                          label="Proveedor"
                          label-for="input-brand">
              <v-select :options="suppliers"
                        label="text"
                        id="input-supplier"
                        class="form-control-border-bottom"
                        :reduce="supplier => supplier.value"
                        :disabled="productsGroupEnabled"
                        v-model="product.supplier"/>

            </b-form-group>

            <b-form-group class="col-md-12"
                          label="Título"
                          label-for="input-title">
              <b-form-input id="input-title"
                            type="text"
                            class="form-control-border-bottom"
                            required
                            v-model="product.title"/>
            </b-form-group>


            <b-form-group class="col-md-12"
                          label="Detalles (Descripción pequeña del producto)"
                          label-for="input-details">
              <b-form-textarea id="input-details"
                               type="text"
                               class="form-control-border-bottom"
                               required
                               v-model="product.details"/>
            </b-form-group>


            <b-form-group class="col-md-12"
                          label="Palabras Clave"
                          label-for="input-tags-keywords">

              <b-form-tags id="input-tags-keywords"
                           invalid-tag-text="Etiqueta no válida"
                           add-button-variant="secondary"
                           add-button-text="Agregar"
                           placeholder="Agregar etiqueta... (Fresa, Masa muscular, bajar de peso, etc...)"
                           v-model="product.keywords"
                           duplicate-tag-text="Etiqueta repetida"/>
            </b-form-group>


            <b-form-group class="col-md-4"
                          label="Impuesto"
                          v-model="product.tax"
                          label-for="input-tax">
              <b-form-select :options="taxes" v-model="product.tax" @change="updatePrice"/>
            </b-form-group>


            <b-form-group class="col-md-4"
                          label="Costo de Lista"
                          label-for="input-cost">
              <b-form-input id="input-cost"
                            type="number"
                            class="form-control-border-bottom"
                            @change="updatePrice"
                            min="0" required
                            :value="product.cost"
                            @input="product.cost = Number.parseFloat($event)"/>
            </b-form-group>


            <b-form-group class="col-md-4"
                          label="Precio de Venta"
                          label-for="input-price">
              <b-form-input id="input-price"
                            type="number"
                            class="form-control-border-bottom"
                            min="0" required
                            :value="product.price"
                            @focus="pricePopover.show = true"
                            @blur="pricePopover.show = false"
                            @input="product.price = Number.parseFloat($event)"/>

              <b-popover target="input-price"
                         trigger="manual"
                         title="Precio Recomendado"
                         @show="updatePrice(false)"
                         :show.sync="pricePopover.show">
                <p class="text-right"><code>Costo: {{ formatPrice(priceData.cost) }}</code></p>
                <p class="text-right"><code>Bonos: {{ formatPrice(priceData.bonus) }}</code></p>
                <p class="text-right"><code>Impuesto: {{ priceData.tax }}%</code></p>
                <p class="text-right"><code>Margen: {{ formatPrice(priceData.cost * 0.5) }}</code></p>
                <hr>
                El precio recomendado es {{ formatPrice(priceData.price) }}
              </b-popover>
            </b-form-group>


            <b-form-group class="col-md-6"
                          label="Objetos en existencia"
                          label-for="input-stock">
              <b-form-input id="input-stock"
                            type="number"
                            class="form-control-border-bottom"
                            required
                            :value="product.stock"
                            @input="product.stock = Number.parseFloat($event)"/>
            </b-form-group>

            <b-form-group class="col-md-6"
                          label="Unidad de Medida"
                          label-for="input-stock_unit">
              <b-form-input id="input-stock_unit"
                            class="form-control-border-bottom"
                            list="input-stock_unit-datalist"
                            required
                            v-model="product.stock_unit"/>
              <datalist id="input-stock_unit-datalist">
                <option v-for="opt in unitsOptionsList" :key="opt" :value="opt"></option>
              </datalist>
            </b-form-group>


            <b-form-group class="col-md-12"
                          label="Objetivo"
                          label-for="input-goal">
<!--              <b-form-select :options="goals"-->
<!--                             id="input-goal"-->
<!--                             class="form-control-border-bottom"-->
<!--                             required-->
<!--                             v-model="product.goal">-->
<!--                <template #first>Sin Objetivo</template>-->
<!--              </b-form-select>-->
              <b-form-tags v-model="goalsArr"
                           class="form-control-border-bottom"
                           required
                           add-on-change
                           no-outer-focus>
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                          @remove="removeTag(tag)"
                          :title="goals.find(g => g.value === tag) ? goals.find(g => g.value === tag).text : ' '"
                          :disabled="disabled"
                          variant="info"
                      >{{ goals.find(g => g.value === tag) ?  goals.find(g => g.value === tag).text : ' ' }}</b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || goals.length === 0"
                      :options="goals"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Elige el objetivo a que pertenece...</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
            </b-form-group>


            <b-form-group class="col-md-12"
                          label="Categoría"
                          label-for="input-category">
              <b-form-select :options="categories"
                             id="input-category"
                             class="form-control-border-bottom"
                             required
                             v-model="productCategory">
                <template #first>Sin Categoría</template>
              </b-form-select>
            </b-form-group>

            <b-form-group class="col-md-12"
                          label="Subcategoría">
              <b-form-select :options="subcategories"
                             class="form-control-border-bottom"
                             v-model="productSubcategory"
                             required>
                <template #first>Sin subcategoría</template>
              </b-form-select>
            </b-form-group>

              <div v-if="product.category && product.subcategory && filters.length > 0" class="col-md-12">
                <h6 class="mb-4">Filtros</h6>
                <div class="card card-outline">
                  <div class="card-body">
                    <b-form-group v-for="(filter, index) in filters" v-bind:key="index + 'filter'"
                                  :label="filter.name"
                                  :label-for="'input-filters-'+ index"
                    >
                      <b-form-select :options="filter.filterItems" :id="'input-filters-'+ index"
                                     class="form-control-border-bottom" required
                                     v-model="product.filters[filter.id]"></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
          </b-row>

          <template #footer>
            <div class="text-right">
              <b-button variant="light" class="btn-round ripple mt-3 mr-3" @click="$router.back()">
                <i class="material-icons-outlined">arrow_back</i>Regresar
              </b-button>
              <b-button variant="primary" class="btn-round ripple mt-3 px-5" @click="submit">Guardar</b-button>
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Vue from "vue";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
  mounted() {
    // http://localhost:8080/admin/products/new?productsGroupUID=UKUsFTKJ6euHY0oKxAEJ&productsGroupFilter=%7B%22Piezas%22%3A%2250%22%7D
    this.getCategories();
    this.getTaxes();
    this.getGoals();
    this.updatePrice();

    if (!this.productsGroupUid) {
      this.getCompanies();
      this.getSuppliers();
    } else {
      this.getProductsGroup()
    }

    if (this.value.category) {
      this.getSubcategories(this.value.category)
    }
  },
  props: {
    value: Object,
    productsGroupUid: String,
    productsGroup: Object,
    settings: Object,
  },
  data() {
    return {
      productUID: null,
      categories: [],
      subcategories: [],
      filters: [],
      companies: [],
      suppliers: [],
      taxes: [],
      goals: [],
      //-------------------------------------------
      error: null,
      selected: 'first',
      isVisible: [
        {text: 'Publicado', value: '0'},
        {text: 'No publicado', value: '1'},
      ],
      productsGroupEnabled: false,
      priceData: {},
      pricePopover: {
        show: false
      }
    }
  },
  methods: {
    submit() {
      if (this.product.brand) {
        let company = this.companies.filter(c => c.value === this.product.brand);
        if (company.length > 0) this.product.brand_label = company[0].text;
      }
      if (this.product.supplier) {
        let supplier = this.suppliers.filter(s => s.value === this.product.supplier);
        if (supplier.length > 0) this.product.supplier_label = supplier[0].text;
      }
      if (this.product.category) {
        let category = this.categories.filter(c => c.value === this.product.category);
        if (category.length > 0) this.product.category_label = category[0].text;
      } else {
        this.product.category = null
        this.product.category_label = ''
      }
      if (this.product.subcategory) {
        let subcategory = this.subcategories.filter(c => c.value === this.product.subcategory);
        if (subcategory.length > 0) this.product.subcategory_label = subcategory[0].text;
      } else {
        this.product.subcategory = null
        this.product.subcategory_label = ''
      }
      if (this.product.goal) {
        let goal = this.goals.filter(c => c.value === this.product.goal);
        if (goal.length > 0) this.product.goal_label = goal[0].text;
      }
      if (this.product.tax) {
        let tax = this.taxes.filter(c => c.value === this.product.tax);
        if (tax.length > 0) this.product.tax_label = tax[0].text;
      }

      this.product.created_at = firebase.firestore.Timestamp.fromDate(new Date());

      this.$emit('save', this.value);
    },
    async getCategories() {
      const db = firebase.firestore()
      const categories = await db.collection('categories').get();
      this.categories = [];
      categories.forEach(doc => this.categories.push({
        text: doc.data().name,
        value: doc.id
      }))
    },
    async getGoals() {
      const db = firebase.firestore();
      const goals = await db.collection('goal_categories').get();
      this.goals = [];
      goals.forEach(doc => this.goals.push({
        text: doc.data().title,
        value: doc.id
      }));
    },
    async getTaxes() {
      const db = firebase.firestore();
      const taxes = await db.collection('taxes').get();
      this.taxes = [];
      taxes.forEach(doc => this.taxes.push({
        text: doc.data().type + ' ('+doc.data().percentage+'%)',
        tax: doc.data().percentage,
        name: doc.data().type,
        value: doc.id
      }))
    },
    async getCompanies() {
      const db = firebase.firestore();
      const companies = await db.collection('brands').get();
      this.companies = [];
      companies.forEach(doc => this.companies.push({
        text: doc.data().company_name,
        value: doc.id
      }))
    },
    async getSuppliers() {
      const db = firebase.firestore();
      const suppliers = await db.collection('suppliers').get();
      this.suppliers = []
      suppliers.forEach(doc => this.suppliers.push({
        text: doc.data().name,
        value: doc.id
      }))
    },
    async getSubcategories(categoryUID) {
      const db = firebase.firestore()
      const subcategories = await db.collection('categories')
          .doc(categoryUID)
          .collection('subcategories')
          .get();
      this.subcategories = [];
      subcategories.forEach(doc => this.subcategories.push({
        text: doc.data().title,
        value: doc.id
      }))

      if (this.subcategories.length > 0) {
        // this.productSubcategory = this.subcategories[0].value
        if (this.productSubcategory !== '' && this.productSubcategory)
          await this.onSubcategoryChange(this.productSubcategory)
      }
    },
    async onCategoryChange(categoryUID) {
      return await this.getSubcategories(categoryUID)
    },
    async onSubcategoryChange(subcategoryUID) {
      const db = firebase.firestore()

      const filtersRef = db.collection('categories')
          .doc(this.product.category)
          .collection('subcategories')
          .doc(subcategoryUID)
          .collection('filters')

      const filters = await filtersRef.get();

      const filtersArr = []

      for (const doc of filters.docs) {
        const filterItems = await filtersRef.doc(doc.id)
            .collection('filter_items')
            .get()

        const filterItemsArr = filterItems.docs.map(f => {
          return {
            text: f.data().name,
            value: f.id
          }
        })

        filtersArr.push({
          id: doc.id,
          name: doc.data().name,
          filterItems: filterItemsArr
        })
      }
      this.filters = filtersArr
    },
    async getProductsGroup() {
      let pg = await firebase.firestore()
          .collection('products_groups')
          .doc(this.productsGroupUid)
          .get()

      if (pg.exists) {
        let d = pg.data()
        this.companies = [{
          text: d.brand_label,
          value: d.brand
        }]
        this.suppliers = [{
          text: d.supplier_label,
          value: d.supplier
        }]

        this.product.brand = d.brand;
        this.product.supplier = d.supplier;

        this.product.supplier_label = d.supplier_label;
        this.product.brand_label = d.brand_label;

        this.productsGroupEnabled = true;
      } else {
        await this.getCompanies()
        await this.getSuppliers()
      }
    },
    updatePrice(changePrice = true) {
      let cost = this.product.cost ?? 0;
      let bonus = this.product.cost * 0.5 ?? 0;
      let tax = this.taxes.find(t => t.value === this.product.tax) ?? {tax: 0}

      let price = Math.ceil((cost * 1.5 + bonus) * (1 + (tax.tax / 100)));
      if (changePrice) this.product.price = price;

      this.priceData =  {
        cost: cost,
        bonus: bonus,
        price: price,
        tax: tax.tax,
      }
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    }
  },
  watch: {
    productGroupUid: function (newVal) {
      if (newVal == null || !newVal.exists) {
        this.getCompanies();
        this.getSuppliers();
      } else {
        let d = newVal.data()
        this.companies = [{
          text: d.brand_label,
          value: d.brand
        }]
        this.suppliers = [{
          text: d.supplier,
          value: d.supplier_label
        }]
      }
    },
    productCategory: function (newVal) {
      this.getSubcategories(newVal)
    },
    productSubcategory: function (newVal) {
      console.log(newVal)
      //if (newVal !== '' && newVal) this.onSubcategoryChange(newVal)
    }
  },
  computed: {
    unitsOptionsList() {
      let list = this.settings.unitsOptionsList;
      return list ? list : []
    },
    goalsArr: {
      get() { return Array.isArray(this.product.goal) ? this.product.goal : [this.product.goal] },
      set(a) { this.product.goal = a }
    },
    product: {
      get() { return this.value },
      set(a) { this.$emit('input', a) }
    },
    productCategory: {
      get() { return this.value.category },
      set(a) { this.product.category = a }
    },
    productSubcategory: {
      get() { return this.value.subcategory },
      set(a) {
        this.product.subcategory = a;
        if (a !== '' && a) this.onSubcategoryChange(a)
      }
    }
  },
}
</script>

<style>

</style>