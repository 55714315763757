<template>
  <div class="bg-primary">
    <section class="section-login">
      <b-container class="container-login">
        <b-row class="justify-content-center">
          <b-col md="6" lg="4" class="text-center">
            <!-- LOGO -->
            <img src="../../assets/img/logos/logo-white.png" class="img-fluid mb-5" alt="Logo de Xplits" width="200px">
            <h4 class="text-white font-weight-bold mb-4">¡Bienvenido!</h4>

            <!-- FORM LOGIN -->
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <b-form action="#" @submit.prevent="submit">
              <b-form-group>
                <b-form-input type="text" id="email" name="email" required autofocus v-model="form.email"
                              class="form-control form-control-outline"
                              placeholder="Email o nombre de usuario"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input type="password" id="password" name="password" required v-model="form.password"
                              class="form-control form-control-outline"
                              placeholder="Contraseña"></b-form-input>
              </b-form-group>

              <div class="form-row no-gutter justify-content-between">
                <div class="col-auto my-1">
                  <b-form-checkbox
                      class="text-white"
                      id="checkbox-remember-me" checked="true"
                  >
                    <span class="text-white">Recuérdame</span>
                  </b-form-checkbox>
                </div>

                <div class="col-auto my-1">
                  <b-button type="submit" class="btn btn-secondary btn-round ripple ripple-dark m-0 py-2 px-3">Iniciar
                    sesión
                  </b-button>
                </div>
              </div>

            </b-form>
            <!-- CREATE ACCOUNT -->
            <div class="mt-3">
              <router-link to="" class="text-white">¿Olvidaste tu contraseña?</router-link>
              <div class="border-top my-4"></div>
              <h6 class="subtitle-2 text-white">¿Eres nuevo o invitado?</h6>
              <router-link to="/register" class="btn btn-light-outline btn-round btn-block ripple ripple-dark mt-3">Crea
                tu cuenta
              </router-link>
            </div>

            <!-- FOLLOW US -->
            <div class="d-flex justify-content-center mt-5">
              <div class="align-self-center">
                <h6 class="text-white subtitle-2 mb-0">Síguenos en</h6>
              </div>
              <div class="mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                      fill="rgba(255,255,255,1)"/>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                      fill="rgba(255,255,255,1)"/>
                </svg>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<style scoped>
.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  height: 100vh;
}

.container-login {
  margin: auto;
}

.custom-control-label::before {
  background-color: transparent !important;
}

</style>

<script>
import firebase from "firebase";

export default {
  name: 'Login',
  metaInfo: {
    title: 'Iniciar sesión',
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null
    };
  },
  methods: {
    submit() {
      firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(() => this.$router.replace({name: "Home"}), err => this.error = err.message)
    }
  }
}
</script>