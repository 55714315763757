<template>
  <b-navbar class="navbar navbar-admin navbar-expand-lg navbar-dark bg-light sticky-top" toggleable="lg">
    <div class="container-fluid">
      <b-navbar-brand>
        <router-link to="/admin/dashboard" class="navbar-brand">
          <img src="@/assets/img/logos/logo-white.png" class="img-fluid" width="80px" alt="Xplits Logo">
        </router-link>
        <b-button v-b-toggle.sidebar-admin variant="link-light" class="ripple" v-b-tooltip.hover title="Expandir menú">
          <i class="material-icons-outlined">menu_open</i>
        </b-button>
      </b-navbar-brand>

      <b-navbar-toggle target="navbarNav"></b-navbar-toggle>

      <b-collapse id="navbarNav" is-nav>
        <b-navbar-nav class="navbar-nav mr-auto">
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <template v-if="user.loggedIn">
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                {{ user.data.displayName }}
                <i class="material-icons-outlined">account_circle</i>
              </template>
              <b-dropdown-item @click.prevent="signOut" class="text-uppercase">
                Cerrar sesión
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>

          <template v-else>
            <b-nav-item to="/login">
              Iniciar sesión
            </b-nav-item>
            <b-nav-item to="/register">
              Registrarse
            </b-nav-item>
          </template>
        </b-navbar-nav>

      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import {mapGetters} from 'vuex'
import firebase from "firebase";

export default {
  methods: {
    signOut() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "Login"
            });
          });
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
}
</script>