<template>
  <b-card class="card-outline mb-3">
    <template #header>
      <h6 class="font-weight-bold mt-2">Revisa tu pedido</h6>
    </template>
    <b-row>
      <b-col lg="7" md="12">
        <b-card class="card-outline mb-3" v-if="checkout.address != null">

          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="font-weight-bold">Dirección de envío</h6>
              <b-button variant="link" @click="$emit('back-checkout', 0)" class="ripple ripple-dark">Cambiar</b-button>
            </div>
          </template>

          <address-item v-model="checkout.address"
                        :address-item="checkout.address.id"
                        lg="12"
                        md="12"/>
        </b-card>

        <b-card class="card-outline mb-3">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="font-weight-bold">Envío del producto</h6>
              <div class="mr-auto"></div>
              <b-button variant="link" @click="$emit('back-checkout', 1)" class="ripple ripple-dark">Cambiar</b-button>
            </div>
          </template>

          <b-card v-for="sId in suppliersIdsList"
                  :header="getSupplierName(sId)"
                  class="shadow-sm  mt-2"
                  header-class="font-weight-bold"
                  :key="sId">

            <div v-for="prod in products.filter(p => p.supplier === sId)" :key="prod.id">
              <product-horizontal-item :value="prod"/>
            </div>
          </b-card>
        </b-card>

      </b-col>

      <b-col lg="5" md="12">
        <b-card class="card-outline">
          <template #header>
            <h6 class="font-weight-bold mt-2">Resumen de compra</h6>
          </template>
          <b-row>
            <b-col md="7" sm="6">
              <p class="subtitle-1 font-weight-bold">Productos:</p>
            </b-col>
            <b-col>
              <format-price class="text-right" v-model="totalPrice"/>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col md="6">
              <p class="subtitle-1 font-weight-bold">Envío:</p>
            </b-col>
            <b-col md="6">
              <format-price class="text-right" :value="0"/>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col md="6">
              <p class="subtitle-1 mb-0">Total (IVA incluido):</p>
            </b-col>
            <b-col md="6">
              <p class="subtitle-1 mb-0">
                <format-price class="text-right font-weight-bold" :value="totalPrice"/>
              </p>
            </b-col>
          </b-row>
          <template #footer>
            <b-button variant="secondary"
                      @click="confirmCheckout"
                      block
                      pill>Terminar y pagar pedido
            </b-button>

          </template>
        </b-card>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import AddressItem from "@/components/checkout/components/AddressItem";
import ProductHorizontalItem from "@/components/checkout/components/ProductHorizontalItem";
import FormatPrice from "@/components/share/formatPrice";
import firebase from 'firebase/app'
import {mapGetters} from "vuex";

export default {
  name: "ConfirmationStep",
  components: {
    FormatPrice,
    ProductHorizontalItem,
    AddressItem
  },
  props: {
    checkout: Object
  },
  data: () => {
    return {
      suppliers: []
    }
  },
  mounted() {

  },
  methods: {
    getSupplierName(id) {
      let x = this.products.find(p => p.supplier === id)
      return x.supplier_label
    },
    confirmCheckout() {
      this.$emit('confirmed', this.checkout)
    },
    async simulatePay() {

      const db = firebase.firestore()
      //const stripeCustomerQuery = await db.collection('stripe_customers').where('customer_id', '==', stripeCustomerId).get()
      const userUUID = this.user.data.uid
      let userOrderRef = db.collection('users').doc(userUUID).collection('orders').doc()
      let address = this.checkout.address
      let orderObject = {
        userId: userUUID,
        userDisplayName: this.user.data.displayName,
        stripeCustomerId: 'xdxdxd',
        amountTotal: this.totalPrice,
        amountSubtotal: this.totalPrice,
        paymentStatus: 'paid',
        amountDiscount: 0,
        amountTax: 0,
        orderAt: firebase.firestore.Timestamp.now(),
        deliveryStatus: 0,
        deliveryAddress: address
      }

      await userOrderRef.set(orderObject, {merge: true})
      userOrderRef = db.collection('users').doc(userUUID).collection('orders').doc(userOrderRef.id)

      for await (const item of this.checkout.products) {
        const productObject = {
          name: item.title,
          title: item.title,
          images: [item.photoURL],
          description: item.details ?? '',
          amount_total: item.finalUnitPrice,
          amount_subtotal: item.finalUnitPrice,
          supplierId: item.supplier,
          supplierLabel: item.supplier_label,
          quantity: item.count,
          deliveryAddress: address
        }

        let supplierOrderRef = db
            .collection('suppliers').doc(item.supplier)
            .collection('orders').doc(userOrderRef.id)

        await supplierOrderRef.set({userUUID, ...orderObject}, {merge: true})

        await supplierOrderRef.collection('products').doc(item.id).set(productObject)
        await userOrderRef.collection('products').doc(item.id).set(productObject)

      }

      let shoppingCartProducts = await db.collection('shopping_carts').doc(userUUID).collection('products').get()
      shoppingCartProducts.forEach(cartItemSnap => cartItemSnap.ref.delete())
    }
  },
  computed: {
    totalPrice() {
      let x = 0
      this.products.forEach(p => x += p.finalTotalPrice)
      return x
    },
    products() {
      return this.checkout.products ? this.checkout.products : []
    },
    suppliersIdsList() {
      return [...new Set(this.products.map(p => p.supplier))]
    },
    ...mapGetters(['cartProductsCount', 'user', 'isCartOpen']),
  },
}
</script>

<style scoped>

</style>