<template>
  <div>
    <div class="card card-outline">
      <div class="card-header my-2">
        <div class="row">
          <b-col md="6" lg="4" class="mb-3">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter"
                            type="search"
                            id="filterInput"
                            class="form-control-flat"
                            placeholder="Buscar por sujeto, fecha, proridad u otro campo"
                            lazy/>
            </div>
          </b-col>
          <div class="col-md-8 col-lg-8 text-md-right">
            <router-link to="/admin/users/register" class="btn btn-primary">
              <i class="material-icons-outlined">add</i>
              Agregar
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <b-table id="table-users"
                 responsive="md"
                 :items="getUsers"
                 :fields="fields"
                 :filter="filter"
                 :busy="tableBusy"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :per-page="perPage"
                 :current-page="currentPage"
                 borderless
                 hover>

          <template v-slot:cell(numberId)="d">
            <p v-text="d.item.data.numberId"/>
          </template>
          <template v-slot:cell(displayName)="d">
            <p class="text-wrap" v-text="d.item.data.displayName"/>
          </template>
          <template v-slot:cell(lastName)="d">
            <p class="text-wrap" v-text="d.item.data.lastName"/>
          </template>
          <template v-slot:cell(email)="d">
            <p v-text="d.item.data.email"/>
          </template>
          <template v-slot:cell(roleDisplayName)="d">
            <p class="text-wrap" v-text="d.item.data.roleDisplayName"/>
          </template>
          <template v-slot:cell(birthday)="d">
            <p class="text-wrap"
               v-if="d.item.data.birthday"
               v-text="d.item.data.birthday.toDate().toLocaleString('es-MX', datetime.dateOptions)"/>
          </template>
          <template v-slot:cell(status)="d">
            <span v-if="d.item.data.status == 1" class="badge badge-success">Activado</span>
            <span v-else class="badge badge-danger">Desactivado</span>
          </template>

          <template v-slot:cell(options)="data">
            <router-link :to="{ name: 'UserEdit', params: { userUID: data.item.id } }"
                         variant="link"
                         class="ripple ripple-dark"
                         v-b-tooltip.hover
                         title="Editar">
              <i class="material-icons-outlined">edit</i>
            </router-link>
          </template>

        </b-table>

        <b-button @click="currentPage--" variant="light"
                  :disabled="currentPage === 1">
          <i class="material-icons-outlined">arrow_back</i> Anterior
        </b-button>
        <b-button @click="currentPage++" variant="light" :disabled="isCurrentPageLastPage">
          Siguiente <i class="material-icons-outlined">arrow_forward</i>
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>
  import firebase from "firebase/app";

export default {
  data() {
    return {
      filter: "",
      perPage: 10,
      currentPage: 1,
      sortBy: 'displayName',
      sortDesc: false,
      tableBusy: false,
      fields: [
        {key: "numberId", label: 'ID', sortable: true},
        {key: "displayName", label: 'Nombre', sortable: true},
        {key: "lastName", label: 'Apellido', sortable: true},
        {key: "email", label: 'Correo', sortable: true},
        {key: "roleDisplayName", label: 'Rol', sortable: true},
        {key: "birthday", label: 'Cumpleaños', sortable: true},
        {key: "status", label: 'Estatus', sortable: true},
        {key: "options", label: 'Acciones', sortable: false}
      ],
      users: [],
      datetime: {
        phrases: {
          ok: 'Continuar',
          cancel: 'Salir'
        },
        format: 'dd/MM/yyyy hh:mm a',
        dateOptions: {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          // second: '2-digit'
        },
      },
      startAfterDocsList: []
    }
  },
  mounted() {
    // this.getUsers();
  },
  methods: {
    getUsers() {
      let query = firebase.firestore().collection('admin_users')
              .limit(this.perPage)
              .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')

      if (this.filter !== '') {
        query = query.where('filterWords', 'array-contains-any', this.filter.split(' '))
      }

      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.users[this.users.length - 1].doc
        query = query.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      let usersArray = [];
      return query.get().then(users => {

        users.forEach(u => {
          usersArray.push({
            id: u.id,
            data: u.data(),
            doc: u,
          })
        })
        this.users = usersArray;
        return usersArray;
      }).catch((error) => {
        console.log(error)
        this.users = [];
        return [];
      });
    },
  },
  computed: {
    rows() {
      return this.users.length
    },
    isCurrentPageLastPage() {
      return this.users.length < this.perPage;
    }
  },
}
</script>