<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <b-container :fluid="true" class="mt-3">
      <!-- BREADCRUMB -->
      <b-row>
        <b-col md="6">
          <b-breadcrumb :items="items"/>
        </b-col>
      </b-row>

      <!-- TABLE -->
      <b-row class="mb-3">
        <b-col md="12">
          <TableProducts with-create-btn></TableProducts>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Navbar from "@/components/admin/includes/Navbar";
import Sidebar from "@/components/admin/includes/Sidebar";
import TableProducts from "@/components/admin/products/TableProducts";

export default {
  metaInfo: {
    title: 'Catálogo',
  },
  components: {TableProducts, Sidebar, Navbar},
  data() {
    return {
      items: [
        { text: 'Inicio', to: {name: 'Dashboard'} },
        { text: 'Productos' }
      ],
    }
  }
}
</script>