<template>
  <div>
    <b-container>
      <h6 class="subtitle-1 font-weight-bold">Dirección de destino</h6>
      <div class="row align-items-center mb-3">
        <div class="col-6 col-md-6 mb-3">
          <h6 class="subtitle-2 text-muted">
            Nombre:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.fullName"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Teléfono:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.phoneNumber"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            País:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.country"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Estado:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.state"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Ciudad:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.city"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Código postal:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.zipCode"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-12">
          <h6 class="subtitle-2 text-muted">
            Dirección:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.firstAddress"></h6>
        </div>
        <div class="col-md-12" v-if="deliveryAddress.secondAddress">
          <h6 class="subtitle-2 text-muted">
            Segunda Dirección:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.secondAddress"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-12" v-if="deliveryAddress.extraIndications">
          <h6 class="subtitle-2 text-muted">
            Indicaciones Extra:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.extraIndications"></h6>
        </div>
        <div class="col-md-12" v-if="deliveryAddress.extraCodeNumber">
          <h6 class="subtitle-2 text-muted">
            Código de seguridad o un número de teléfono:
          </h6>
          <h6 class="subtitle-1" v-text="deliveryAddress.extraCodeNumber"></h6>
        </div>
      </div>
    </b-container>
    <hr>
    <b-container>
      <h6 class="subtitle-1 font-weight-bold">Dirección de Remitente</h6>
      <div class="row align-items-center mb-3">
        <div class="col-6 col-md-6 mb-3">
          <h6 class="subtitle-2 text-muted">
            Nombre:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.fullName"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Teléfono:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.phoneNumber"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            País:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.country"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Estado:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.state"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Ciudad:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.city"></h6>
        </div>
        <div class="col-6 col-md-3 mb-3">
          <h6 class="subtitle-2 text-muted">
            Código postal:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.zipCode"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-12">
          <h6 class="subtitle-2 text-muted">
            Dirección:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.firstAddress"></h6>
        </div>
        <div class="col-md-12" v-if="supplierAddress.secondAddress">
          <h6 class="subtitle-2 text-muted">
            Segunda Dirección:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.secondAddress"></h6>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-12" v-if="supplierAddress.extraIndications">
          <h6 class="subtitle-2 text-muted">
            Indicaciones Extra:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.extraIndications"></h6>
        </div>
        <div class="col-md-12" v-if="supplierAddress.extraCodeNumber">
          <h6 class="subtitle-2 text-muted">
            Código de seguridad o un número de teléfono:
          </h6>
          <h6 class="subtitle-1" v-text="supplierAddress.extraCodeNumber"></h6>
        </div>
      </div>
    </b-container>

  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "ShippingGuide",
  data() {
    return {
      orderId: null,
      supplierId: null,
      supplierAddress: {},
      deliveryAddress: {}
    }
  },
  mounted() {
    this.orderId = this.$route.params.orderId
    this.supplierId = this.$route.params.supplierId

    this.getOrder()
  },
  methods: {
    async getOrder() {
      let db = firebase.firestore()

      let orderSnap = await db.collection('suppliers').doc(this.supplierId).collection('orders').doc(this.orderId).get()
      let data = orderSnap.data()
      this.deliveryAddress = data.deliveryAddress

      if (data.emitterAddress) {
        this.supplierAddress = data.emitterAddress
      } else {
        let addressSnap = await db.collection('suppliers').doc(this.supplierId).collection('addresses').get()
        this.supplierAddress = addressSnap.docs[0].data()
      }
    }
  }
}
</script>

<style scoped>

</style>