<template>
  <div>
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>
      <div class="row mb-3 my-5">
        <div class="col-md-12 text-center my-5">
          <i class="material-icons text-success mdc-58 mb-2 animate__animated animate__bounce">check</i>
          <h4 class="font-weight-bold">Orden completada</h4>
          <h6>Gracias por tu compra, recibirás un email con más detalles.</h6>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import SearchBar from "@/components/Home/SearchBar";
import Footer from "@/components/Footer";

export default {
  name: 'Checkout',
  metaInfo: {
    title: 'Checkout',
  },
  components: {Footer, SearchBar, Navbar},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Home'}
        },
        {
          text: 'Carrito',
        },
      ]
    }
  },
}
</script>