<template>
  <div>
    <b-form-group :label="label" :label-for="id">
      <div :class="['input-file-dropbox', value ? 'input-file-dropbox-success' : '']">

        <b-form-file
            @input="handleInput"
            :id="id"
            :v-model="content"
            :state="Boolean(value)"
            :accept="accept"
            class="input-file"
        ></b-form-file>

        <p v-if="value" class="overline pb-0">
          <i class="material-icons-outlined mdc-18">check</i>{{ value ? value.name : '' }}
        </p>
        <p v-else class="overline pb-0"><i class="material-icons-outlined mdc-18">add_circle</i>
          Seleccionar
          archivo
        </p>

      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: [String, Number],
    value: [File, Object, null],
    accept: {
      default: '',
      type: String
    },
  },
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    }
  }
}
</script>