<template>
  <div class="about">
    <Navbar></Navbar>
    <SearchBar></SearchBar>
    <div class="container">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- VIDEO -->
      <div class="row mb-5">
        <b-col md="12" v-if="aboutUsImage == null">
          <img src="@/assets/img/banners/about-us/banner-2.jpeg" class="img-fluid" alt="Banner oportunidades">
        </b-col>
        <b-col md="12" v-else>
          <b-embed type="iframe" class="border-radius-25"
                   aspect="16by9"
                   :src="aboutUsImage.data().url"
                   allowfullscreen/>
        </b-col>
      </div>

      <!-- XPLITS -->
      <div class="row bg-grey border-radius-12 p-5 mb-3">
        <div class="col-md-6">
          <img src="@/assets/img/banners/about-us/about-us.jpg" class="img-fluid border-radius-25" alt="Equipo">
        </div>
        <div class="col-md-6 align-self-center">
          <h6 class="font-weight-bold text-primary mb-5">¿Quiénes somos?</h6>
          <h6 class="body-1">
            Somos un sistema que busca un bienestar y fortalecimiento completo del consumidor:
            físico, emocional, económico y de desarrollo personal.
            Por medio de productos nutricionales y de suplementación, le ofrecemos una ecuación de ganar, ofreciendo
            productos de gran calidad a un precio competitivo y razonable pero además generando oportunidades de
            crecimiento económico.
          </h6>
          <h6 class="body-1 text-secondary">
            Xplits te ofrece la oportunidad de pertenecer a una comunidad de lideres ganadores, adquiriendo
            productos para tu bienestar físico, que tangiblemente te entrega eso y más, al ser un detonador de un
            negocio adicional para ti y con quien lo compartas.
          </h6>
        </div>
      </div>

      <div class="row bg-grey border-radius-12 p-5 mb-3">
        <div class="col-md-6 align-self-center">
          <h6 class="font-weight-bold text-secondary mb-3">¿Para quién es Xplits?</h6>
          <h6 class="body-1 mb-3">
            Xplits es una plataforma muy ACCESIBLE para personas preocupadas por su salud y su nutrición, gente que
            comprende el valor del trabajo, del dinero y gusta premiarse por su desempeño ( ir a restaurants,
            vacacionar, ir de compras, tener diversiones y aficiones deportivas, culturales) que tiene la
            posibilidad de invertir en su persona y en un negocio sencillo de lado a sus labores cotidianas.
          </h6>
          <b-button v-b-toggle.collapse-1 variant="light" class="btn-round bg-white">
            <i class="material-icons-outlined mr-1">add</i>
            ¿Por qué Xplits?
          </b-button>
          <b-collapse id="collapse-1">
            <b-card>
              <h6 class="body-1">
                Estamos en medio de un momento donde ocupamos reinventarnos, si queremos resultados diferentes hay que
                hacer algo diferente, ya basta de INTENCIONES y ahora pasemos a las ACCIONES.
              </h6>
            </b-card>
          </b-collapse>
        </div>
        <div class="col-md-6">
          <img src="@/assets/img/banners/about-us/why-xplits.jpg" class="img-fluid border-radius-25"
               alt="Persone viendo celular">
        </div>
      </div>

      <!-- PRODUCTS -->
      <div class="row justify-content-center p-5">
        <div class="col-md-12 text-center mb-3">
          <h5 class="font-weight-bold">Productos y beneficios</h5>
        </div>
        <div class="col-md-8">
          <h6 class="body-1 text-justify">
            Se viene un crecimiento en el sector salud y alimenticio que va impulsar marcas que cubran necesidades que
            el mercado estará demandando, la palabra sistema inmune cobra hoy gran relevancia para los consumidores, el
            miedo a los acontecimientos recientes de infección, harán que el consumidor busque alternativas que a nivel
            emocional y físico lo alivien de la incertidumbre, hoy la vida ya cambio y tenemos que adaptarnos a esos
            cambios.
          </h6>
        </div>
      </div>
      <div class="row justify-content-center mb-3">
        <div class="col-md-4 text-center">
          <img src="@/assets/img/products/whey-1.png" width="250px" class="img-fluid img-whey border-radius-25"
               alt="Proteina">
        </div>
        <div class="col-md-8 align-self-center">
          <div class="card bg-grey p-3">
            <div class="card-body text-dark">
              <img src="@/assets/img/icons/about/ultra-pures-upps-logo.jpg" width="150px"
                   class="img-fluid border-radius-25 mb-2"
                   alt="Proteina">
              <div class="text-justify">
                Marca que nace con la idea de colaborar en las metas de desarrollo físico, prevención y de nutrición, un
                producto de calidad y excelente imagen que otorga suplementos de diversos tipos para optimizar, nutrir,
                proteger y construir un mejor cuerpo.

                Desde vitaminas para la familia, productos que ayuden a nuestro sistema inmune y suplementos para
                soportar
                una vida activa y dinámica.
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HOW IT WORKS -->
      <div class="row mt-5 mb-3">
        <div class="col-md-12 text-center">
          <h5 class="font-weight-bold">¿Como funciona Xplits?</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3 mb-3">
          <div class="card shadow-sm h-100">
            <div class="card-body d-flex align-items-center">
              <div>
                <div class="text-center">
                  <img src="../assets/img/icons/about/icon2.png" class="img-fluid mb-3" alt="">
                </div>
                <img src="../assets/img/icons/about/num1.png" class="img-fluid mb-2" alt="">
                <h6 class="font-weight-bold">El comienzo</h6>
                Es exclusivamente por invitación, convirtiéndose en algo aspiracional y más controlado y
                estudiado el acceso.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card shadow-sm h-100">
            <div class="card-body d-flex align-items-center">
              <div>
                <div class="text-center">
                  <img src="../assets/img/icons/about/icon1.png" class="img-fluid mb-3" alt="">
                </div>
                <img src="../assets/img/icons/about/num2.png" class="img-fluid mb-2" alt="">
                <h6 class="font-weight-bold">Para activar</h6>
                Tu membresía que genera BONOS económicos hay que completar estos pasos:
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="card shadow-sm">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="px-3">
                      <img src="../assets/img/icons/about/icon3.png" class="img-fluid" width="150px" alt="">
                    </div>
                    <div>
                      <h6 class="font-weight-bold">Pago de tu AUTO CONSUMO</h6>
                      Pago de tu AUTO CONSUMO (consumo mínimo de producto) con valor de 2,000 pesos que ya incluye IVA y
                      entrega a domicilio en cualquier parte de la republica mexicana
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card shadow-sm">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="px-3">
                      <img src="../assets/img/icons/about/icon4.png" class="img-fluid" width="150px" alt="">
                    </div>
                    <div>
                      <h6 class="font-weight-bold">GIVE ME FIVE</h6>
                      Tener un núcleo activado con 5 invitados a los cuales tu les vas a dar seguimiento y vas a
                      liderear,
                      ellos a su vez tienen que estar activos con sus auto consumos y generar un núcleo como mínimo.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="px-3">
                  <img src="../assets/img/icons/about/icon5.png" class="img-fluid mb-3" width="150px" alt="">
                </div>
                <div>
                  <img src="../assets/img/icons/about/num3.png" class="img-fluid mb-2" alt="">
                  <h6 class="font-weight-bold">Tu NUCLEO</h6>
                  (Parte principal, fundamental o mas importante de algo) es tu primer FRANQUICIA que te va a
                  hacer generar DIVIDENDOS, tú vas a estar en la posibilidad de que una vez completado tu NUCLEO, puedas
                  abrir uno más (otra franquicia) y así sucesivamente, eso ya depende de cada asociado.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="px-3">
                  <img src="../assets/img/icons/about/icon6.png" class="img-fluid mb-3" width="150px" alt="">
                </div>
                <div>
                  <img src="../assets/img/icons/about/num4.png" class="img-fluid mb-2" alt="">
                  <h6 class="font-weight-bold">Tendrás a la mano un sistema vía WEB</h6>
                  Donde podrás estar revisando tu actividad y la de tu núcleo, tendrá
                  los primeros 22 días del mes para realizar pedidos, el día 25 el sistema realiza la cuantificación de
                  miembros de tu núcleo y derivados para darte certeza de desempeño en el mes y te notificará el BONO
                  DIVIDENDO que estas recibiendo el día ultimo de mes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BANNER -->
      <div class="row mb-5">
        <div class="col-md-12">
          <img src="@/assets/img/banners/about-us/banner-7.jpeg" class="img-fluid border-radius-25"
               alt="Banner oportunidades">
        </div>
      </div>

      <!-- EARN MONEY -->
      <div class="row mb-3">
        <div class="col-md-12 text-center">
          <h5 class="font-weight-bold">¿Cómo ganas dinero?</h5>
        </div>
        <div class="col-md-12 text-center mb-3">
          <h6 class="body-1">
            El sistema Xplits bonifica de 6 maneras:
          </h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/happy.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Venta Directa</h6>
          <h6 class="subtitle-1">Tus núcleos los controlas tu totalmente</h6>
        </div>
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/teamwork.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Red</h6>
          <h6 class="subtitle-1">Tus núcleos comienzan a realizar redes que generan dividendos) ( No se controla
            totalmente, dependemos de las acciones de toda la red</h6>
        </div>
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/phone.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Activación o Lealtad</h6>
        </div>
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/happy-2.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Diferencial</h6>
        </div>
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/success.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Tanda Excedente</h6>
        </div>
        <div class="col-md-4 text-center mb-3">
          <img src="@/assets/img/banners/about-us/entrepreneur.jpg" class="img-fluid img-earn-money mb-3" width="200px"
               alt="">
          <h6 class="font-weight-bold">Bono por Emprendedor </h6>
          <h6 class="subtitle-1">Xplits premia cada seis meses a sus 5 mejores socios con mayor actividad en red,
            nombrando un campeón de temporada</h6>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SearchBar from '@/components/Home/SearchBar'
import firebase from "firebase";

export default {
  name: 'AboutUs',
  metaInfo: {
    title: 'Nosotros',
  },
  components: {
    Navbar,
    Footer,
    SearchBar,
  },
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Home'}
        },
        {
          text: 'Cómo funciona',
        },
      ],
      aboutUsImage: null
    }
  },
  mounted() {
    this.getAboutUsImage()
  },
  methods: {
    async getAboutUsImage() {
      let img = await firebase.firestore()
          .collection('image_banners')
          .doc('aboutUsImage')
          .get()

      if (img.exists) {
        this.aboutUsImage = img;
      }
    }
  }
}
</script>

<style scoped>
.img-whey {
  right: -95px;
  top: 10px;
  position: absolute;
  z-index: 99;
}
</style>