<template>
  <div>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <div class="container-fluid mt-3">
      <!-- BREADCRUMB -->
      <div class="row">
        <div class="col-md-6">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
      </div>

      <!-- TABLE -->
      <div class="row mb-3">
        <div class="col-md-12">
          <TableCategories></TableCategories>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/admin/includes/Sidebar";
import Navbar from "@/components/admin/includes/Navbar";
import TableCategories from "@/components/admin/products/TableCategories";

export default {
  metaInfo: {
    title: 'Categorías',
  },
  components: {Navbar, Sidebar, TableCategories},
  data() {
    return {
      items: [
        {
          text: 'Inicio',
          to: {name: 'Dashboard'}
        },
        {
          text: 'Productos',
        },
        {
          text: 'Categorías',
        },
      ],
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>