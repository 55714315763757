<template>
  <b-card class="card-outline">
    <b-form-group label="Asignar Responsable"
                  description="Asignar responsable de empaquetar los productos de este envío">
      <b-form-select v-model="assignedMemberUid" :options="userList" @input="onInputSelect($event)"/>
    </b-form-group>
    <template #footer>
      <div class="text-right">
        <b-button variant="secondary"
                  @click="$emit('next', assignedMemberProp, assignedMemberUidProp)"
                  :disabled="!check">Asignar</b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
export default {
  name: "packagingStep",
  props: {
    order: Object,
    assignedMember: String,
    assignedMemberUid: String,
    userList: Array
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    onInputSelect(val) {
      let user = this.userList.find(u => u.value === val)
      this.assignedMember = user ? user.text : ''
    }
  },
  computed: {
    check() {
      return this.assignedMemberUidProp
    },
    assignedMemberProp: {
      get() {return this.assignedMember ?? ''},
      set(v) {this.$emit('update:assignedMember', v)}
    },
    assignedMemberUidProp: {
      get() {return this.assignedMemberUid ?? null },
      set(v) {this.$emit('update:assignedMemberUid', v)}
    }
  }
}
</script>

<style scoped>

</style>