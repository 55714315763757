<template>
  <div>
    <!-- RANKING -->
    <div class="card shadow-sm mb-3">
      <div class="card-header mt-2">
        <h6>Premios bono emprendedor</h6>
      </div>
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-5 col-lg-4">
            <h6 class="subtitle-1">
              <strong>Primer lugar NUCLEOS:</strong>
            </h6>
            <p>
              <format-price :value="rankings.prizes.cores.first" class="text-secondary subtitle-1 font-weight-bold"/>
            </p>
          </div>
          <div class="col-md-5 col-lg-4">
            <h6 class="subtitle-1">
              <strong>Primer lugar RED:</strong>
            </h6>
            <p>
              <format-price :value="rankings.prizes.network.first" class="text-secondary subtitle-1 font-weight-bold"/>
            </p>
          </div>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-md-5 col-lg-4">
            <h6 class="subtitle-1">
              <strong>Segundo lugar NUCLEOS:</strong>
            </h6>
            <p>
              <format-price :value="rankings.prizes.cores.second" class="text-secondary subtitle-1 font-weight-bold"/>
            </p>
          </div>
          <div class="col-md-5 col-lg-4">
            <h6 class="subtitle-1">
              <strong>Segundo lugar RED:</strong>
            </h6>
            <p>
              <format-price :value="rankings.prizes.network.second" class="text-secondary subtitle-1 font-weight-bold"/>
            </p>
          </div>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-md-5 col-lg-4">
            <h6 class="subtitle-1">
              <strong>Tercer lugar NUCLEOS:</strong>
            </h6>
            <p>
              <format-price :value="rankings.prizes.cores.third" class="text-secondary subtitle-1 font-weight-bold"/>
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer d-md-flex justify-content-between align-items-center">
        <h6 class="text-secondary font-weight-bold">Acumulado total:</h6>
        <format-price :value="rankings.prizes.total" class="text-secondary subtitle-1 font-weight-bold"/>
      </div>
    </div>

    <!-- TOP 3 CORES AND RED -->
    <div class="card shadow-sm mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <!-- RANKING INVITES -->
            <h6 class="text-center text-uppercase">Top 3 NÚCLEOS</h6>
            <PerformanceRanking :people="rankings ? rankings.cores.top3 : []"></PerformanceRanking>
          </div>
          <div class="col-md-6 mb-3">
            <!-- RANKING MY INVITES -->
            <h6 class="text-center text-uppercase">TOP 2 RED</h6>
            <PerformanceRanking :people="rankings ? rankings.network.top3 : []"></PerformanceRanking>
          </div>
        </div>
      </div>
    </div>

    <!--    TOP 25 CORES AND RED-->
    <div class="card shadow-sm mb-3">
      <div class="card-body">

        <div class="row">
          <div class="col-md-6 mb-3">
            <!-- RANKING INVITES -->
            <h6 class="text-center text-uppercase">TOP 25 RANKING NÚCLEOS</h6>
            <PerformanceRanking :people="rankings ? rankings.cores.top25 : []"></PerformanceRanking>
          </div>
          <div class="col-md-6 mb-3">
            <!-- RANKING MY INVITES -->
            <h6 class="text-center text-uppercase">TOP 25 RANKING RED</h6>
            <PerformanceRanking :people="rankings ? rankings.network.top25 : []"></PerformanceRanking>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerformanceRanking from "@/components/xplits/performance/PerformanceRanking";
import firebase from 'firebase/app'
import 'firebase/functions'
import FormatPrice from "@/components/share/formatPrice"

export default {
  name: 'VoucherEntrepreneursCard',
  components: {PerformanceRanking, FormatPrice},
  mounted() {
    this.fetchRanking()
  },
  data() {
    return {
      rankings: {}
    }
  },
  methods: {
    async fetchRanking() {
      const getRankings = firebase.functions().httpsCallable('getRankings')
      getRankings()
      .then(res => {
        console.log(res.data)
        this.rankings = res.data
      })
    }
  }
}
</script>