<template>
  <div class="step" :data-target="'#'+stepperId">
    <button type="button" class="step-trigger" role="tab" :aria-controls="stepperId" :id="stepperId+'-trigger'">
              <span class="bs-stepper-circle">
                <i class="material-icons-outlined" v-text="buttonIcon"/>
              </span>
      <span class="bs-stepper-label hide-below-md" v-text="label"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "bsStepperStep",
  props: {
    stepperId: String,
    buttonIcon: String,
    label: String
  },
}
</script>

<style scoped>

</style>