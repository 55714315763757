<template>
  <div>
    <b-card class="card-outline">
      <template #header>
        <b-row>
          <b-col md="6" lg="4" class="mb-3">
            <div class="d-flex align-items-center">
              <i class="material-icons-outlined mr-2">search</i>
              <b-form-input v-model="filter"
                            type="search"
                            id="filterInput"
                            class="form-control-flat"
                            placeholder="Buscar por nombre de la compañía"/>
            </div>
          </b-col>
          <b-col md="6" lg="8" class="text-md-right">
            <router-link :to="{name: 'ObjectivesForm'}" class="ripple btn btn-primary">
              <i class="material-icons-outlined">add</i>
              Agregar
            </router-link>
          </b-col>
        </b-row>
      </template>

      <b-table :fields="fields"
               :filter="filter"
               :per-page="perPage"
               :filter-ignored-fields="filterIgnoredFields"
               :current-page.sync="currentPage"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :items="getObjectives"
               ref="table">
        <template #cell(id)="d">
          <router-link :to="{name: 'Goal', params: { goalId: d.value } }" target="_blank">
            {{ d.value }}
          </router-link>
        </template>
        <template #cell(color)="d">
          <b-badge variant="light" class="mx-auto">{{ d.value }}</b-badge>
          <b-aspect aspect="3:2" :style="{backgroundColor: d.value}"/>
        </template>
        <template #cell(url)="d">
          <b-img :src="d.value" height="128" class="mx-auto"/>
        </template>
        <template #cell(options)="d">
          <router-link class="btn btn-link ripple ripple-dark"
                       :to="{ name: 'ObjectivesFormEdit', params: { id: d.item.id} }"
                       title="Editar">
            <i class="material-icons">edit</i>
          </router-link>
          <b-btn variant="link" class="ripple" title="Eliminar" @click="openDeleteModal(d.item)">
            <i class="material-icons">delete</i>
          </b-btn>
        </template>
      </b-table>

      <b-button @click="currentPage--" variant="light"
                :disabled="currentPage === 1">
        <i class="material-icons-outlined">arrow_back</i> Anterior
      </b-button>
      <b-button @click="currentPage++" variant="light" :disabled="isCurrentPageLastPage">
        Siguiente <i class="material-icons-outlined">arrow_forward</i>
      </b-button>

    </b-card>

    <b-modal :title="'Eliminar Objetivo: '+deleteModal.objective.title"
             v-model="deleteModal.show"
             @ok="deleteObjective"
             ok-variant="danger ripple ripple-dark"
             ok-title="Eliminar"
             cancel-variant="link ripple ripple-dark"
             cancel-title="Cancelar">
      <p>¿Desea eliminar el objetivo <b v-text="deleteModal.objective.title"></b> permanentemente?</p>

      <b-container :style="{backgroundColor: deleteModal.objective.color, padding: '8px'}">
        <b-img :src="deleteModal.objective.url" height="128" class="mx-auto d-block"/>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "TableObjectives",
  components: {},
  data() {
    return {
      filter: '',
      filterIgnoredFields: ['id', 'doc'],
      perPage: 5,
      currentPage: 1,
      sortBy: '',
      startAfterDocsList: [],
      sortDesc: false,
      dateOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      },
      objectives: [],
      deleteModal: {
        objective: {title: ''},
        id: null,
        show: false,
      },
      fields: [
        { key: 'id', label: 'Id', sortable: false },
        { key: 'color', label: 'Color', sortable: false },
        { key: 'url', label: 'Imagen', sortable: false, },
        { key: 'title', label: 'Título', sortable: true, },
        { key: 'created_at', label: 'Fecha de creación', sortable: true},
        { key: 'options', label: 'Acciones', sortable: false },
      ]
    }
  },
  mounted() {
    // this.getObjectives()
  },
  methods: {
    getObjectives() {
      const db = firebase.firestore();
      let objectives = db.collection('goal_categories')
          .limit(this.perPage)

      if (this.sortBy !== null && this.sortBy.length > 1) {
        objectives = objectives.orderBy(this.sortBy, this.sortDesc ? 'desc':'asc');
      }
      if (this.currentPage > 1) {
        if (this.startAfterDocsList[this.currentPage] == null)
          this.startAfterDocsList[this.currentPage] = this.objectives[this.objectives.length - 1].doc
        objectives = objectives.startAfter(this.startAfterDocsList[this.currentPage])
      } else this.startAfterDocsList = []

      this.objectives = []
      let objArray = []

      return objectives.get().then(objectivesSnap => {
        objectivesSnap.forEach(o => {
          let d = o.data()
          d.id = o.id
          d.doc = o
          d.created_at = d.created_at != null ? d.created_at.toDate().toLocaleString('es-MX', this.dateOptions) : null
          objArray.push(d)
        })
        this.objectives = objArray

        return objArray
      })
    },
    openDeleteModal(objective) {
      this.deleteModal.show = true
      this.deleteModal.id = objective.id
      this.deleteModal.objective = objective
    },
    async deleteObjective() {
      if (this.deleteModal.id) {
        const db = firebase.firestore();
        let query = db.collection('goal_categories').doc(this.deleteModal.id)
        let store = firebase.storage().ref().child(this.deleteModal.objective.image_path)

        await store.delete()
        await query.delete()

        this.$refs.table.refresh()
      }
    }
  },
  computed: {
    totalRows: function () {
      return this.objectives.length;
    },
    isCurrentPageLastPage() {
      return this.objectives.length < this.perPage
    },
  }
}
</script>

<style scoped>

</style>