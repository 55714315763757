<template>
  <footer>
    <button class="btn-shopping-cart" @click="toggleClass()">
      <i class="material-icons">local_mall</i>
    </button>
    <b-container>
      <b-row class="mb-3">
        <b-col md="4" class="mb-2">
          <img src="@/assets/img/logos/logo.png" class="img-fluid" width="150px" height="150px" alt="Xplits Logo">
        </b-col>
        <b-col md="4" class="mb-2">
          <h6 class="subtitle-2">
            <a href="tel:8183637845" class="text-dark">
              <i class="material-icons mdc-18">phone</i>
              T. (81) 8363 7845
            </a>
          </h6>
          <h6 class="subtitle-2">
            <a href="mailto:soporte@xplits.com" class="text-dark">
              <i class="material-icons mdc-18">email</i>
              soporte@xplits.com
            </a>
          </h6>
          <h6 class="subtitle-2 text-dark">
            Montes Cárpatos 306B <br>
            Residencial San Agustín <br> San Pedro Garza García, N.L. México <br>
            CP 62260
          </h6>
        </b-col>
        <!--        <b-col md="4" class="mb-2">
                  <h6 class="font-weight-bold text-secondary">Suscríbete -</h6>
                  <b-form-group
                      label="Suscríbete para recibir ofertas, notificaciones de nuevos artículos y más."
                  >
                    <b-input-group class="mt-3">
                      <b-form-input></b-form-input>
                      <b-input-group-append>
                        <b-button variant="btn btn-flat btn-primary ripple m-0">
                          <i class="material-icons mdc-18">send</i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                  </b-form-group>
                </b-col>-->
      </b-row>
      <b-row class="row">
        <b-col md="12" class="text-center">
          <hr>
          <h6 class="caption mt-4">Todos los derechos reservados XPLITS © 2020.</h6>
          <h6 class="caption mt-1 font-italic">Versión: {{ version }}</h6>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  computed: {
    version: function () {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    toggleClass() {
      this.$store.dispatch('toggleClassCart')
    }
  }
}
</script>