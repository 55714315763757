import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import AboutUs from '../views/AboutUs'
import Contact from '../views/Contact'
import Login from '../views/auth/Login'
import Register from '../views/auth/Register'
import Store from '../views/store/Store'
import Category from '../views/store/Category'
import Goal from "@/views/store/Goal";
import Product from '../views/store/Product'
import Profile from '../views/profile/Profile'
import Checkout from '../views/checkout/Checkout'
import OrderSuccess from '../views/checkout/OrderSuccess'
import Panel from '../views/xplits/Panel'
import firebase from 'firebase/app'
import 'firebase/auth'
import adminRouter from './admin'
import RegisterStripe from "../views/auth/RegisterStripe";
import StripeExpressReauth from '../views/auth/StripeExpressReauth'
import StripeExpressReturn from '../views/auth/StripeExpressReturn'
import SubcategoryView from "@/views/store/SubcategoryView";
import PayoutReceipt from "@/views/xplits/PayoutReceipt";

Vue.use(VueRouter)

const routes = [
    ...adminRouter,
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {guestOnly: true}
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {guestOnly: true}
    },
    {
        path: '/account/billing',
        name: 'AccountBillingRegister',
        component: RegisterStripe,
        meta: {requireAuth: true}
    },
    {
        path: '/stripe/express_reauth',
        name: 'StripeExpressReauth',
        component: StripeExpressReauth,
        meta: {requireAuth: true}
    },
    {
        path: '/stripe/express_return',
        name: 'StripeExpressReturn',
        component: StripeExpressReturn,
        meta: {requireAuth: true}
    },
    {
        path: '/register/:requestUID',
        name: 'RegisterWithRequest',
        component: Register,
        meta: {guestOnly: true}
    },
    {
        path: '/store',
        name: 'Store',
        component: Store
    },
    {
        path: '/store/categories/:categoryId',
        name: 'Category',
        component: Category
    },
    {
        path: '/store/categories/:categoryId/:subcategoryId',
        name: 'Subcategory',
        component: SubcategoryView
    },
    {
        path: '/store/product/:productId',
        name: 'Product',
        component: Product
    },
    {
        path: '/store/goals/:goalId',
        name: 'Goal',
        component: Goal
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {requireAuth: true}
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    {
        path: '/order-success',
        name: 'OrderSuccess',
        component: OrderSuccess
    },
    //==================================================================================================================
    //==============================                ACCOUNT                =============================================
    //==================================================================================================================
    {
        path: '/xplits/panel',
        name: 'Panel',
        component: Panel,
        meta: {requireAuth: true}
    },
    {
        path: '/xplits/panel/payouts/:uuid/receipt',
        component: PayoutReceipt
    },
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            if (from.fullPath) next({path: from.fullPath})
            else next({name: 'Home'})
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach(async (to, from, next) => {
    // const loggedIn = store.getters.user.loggedIn
    const requireAuth = to.matched.some(record => record.meta.requireAuth)
    const guestOnly = to.matched.some(record => record.meta.guestOnly)
    const firebaseUser = firebase.auth().currentUser

    if (requireAuth && !firebaseUser) next('login')
    else if (guestOnly && firebaseUser) next('home')
    else next()
})

export default router
