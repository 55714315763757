import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/app"
import 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            adminData: null
        },
        isCartOpen: false,
        cartProductsCount: 0
    },
    getters: {
        user: state => state.user,
        isCartOpen: state => state.isCartOpen,
        cartProductsCount: state => state.cartProductsCount
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_ADMIN_USER(state, data) {
            state.user.adminData = data;
        },
        SET_IS_CART_OPEN(state, isCartOpen) {
            state.isCartOpen = isCartOpen
        },
        SET_CART_PRODUCTS_COUNT(state, cartProductsCount) {
            state.cartProductsCount = cartProductsCount
        }
    },
    actions: {
        async fetchCartProductsCount({commit, getters}) {
            if (!getters.user.data) {
                return
            }

            const db = firebase.firestore()
            const count = await db.collection('shopping_carts').doc(getters.user.data.uid).get()
            const data = count.data()

            if (data) {
                console.debug(
                    "[store/index.js:fetchCartProductsCount]",
                    "Products count updated", data.totalCount
                )

                commit('SET_CART_PRODUCTS_COUNT', count.data().totalCount)
            }
        },
        async addProductToCart({commit, getters}, {
            product,
            countToAdd = 1,
            discountCode = null
        }) {
            const newCount = parseInt(getters.cartProductsCount) + countToAdd
            commit('SET_CART_PRODUCTS_COUNT', newCount)

            const productDocRef = await firebase.firestore()
                .collection('shopping_carts')
                .doc(getters.user.data.uid)
                .collection('products')
                .doc(product.id)

            const productDocSnap = await productDocRef.get()

            if (productDocSnap.exists) {
                const productData = productDocSnap.data()
                const productCount = productData.count
                const discountCodeUp = discountCode ?? productDocSnap.data().discountCode ?? null;
                const productStock = productData.stock
                const totalProductCount = productCount + countToAdd
                if (totalProductCount <= productStock) {
                    return productDocRef.update({
                        count: totalProductCount,
                        discountCode: discountCodeUp,
                    })
                } else {
                    this.$bvToast.toast('No hay stock suficiente', {
                        title: 'Sin Stock',
                        autoHideDelay: 6500,
                        variant: 'primary',
                    });
                }
            }

            console.log("Adding ", countToAdd)
            return productDocRef.set({
                id: product.id,
                count: countToAdd,
                discountCode: discountCode,
                ...product
            })
        },
        async updateCartProductCount({getters}, {product, newCount}) {
            console.log('updating to newCount product: ', newCount, product.id)
            return firebase.firestore()
                .collection('shopping_carts')
                .doc(getters.user.data.uid)
                .collection('products')
                .doc(product.id)
                .update({count: newCount})
        },
        async fetchUser({commit}, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                const db = firebase.firestore()
                let userInfo = await db.collection('users').doc(user.uid).get()
                let openpayCustomer = await db.collection('openpay_customers').doc(user.uid).get()
                let openpayBillingReady = false
                let openpayCustomerId = null

                if (openpayCustomer.exists) {
                    openpayBillingReady = openpayCustomer.data().billing_ready
                    openpayCustomerId = openpayCustomer.data().customer_id
                }

                userInfo = userInfo.data()

                commit("SET_USER", {
                    uid: user.uid,
                    openpayBillingReady,
                    openpayCustomerId,
                    ...userInfo
                })

                let adminUserInfo = await firebase.firestore()
                    .collection('admin_users')
                    .doc(user.uid)
                    .get()

                if (adminUserInfo.exists) {
                    commit('SET_ADMIN_USER', {
                        uid: user.uid,
                        ...adminUserInfo.data()
                    })
                }
            } else {
                commit("SET_USER", null);
            }
        },
        toggleClassCart({commit, state}) {
            commit("SET_IS_CART_OPEN", !state.isCartOpen)
        }
    },
    modules: {}
});
